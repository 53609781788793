const shadow = {
  1: '0px 2px 5px 0px rgba(84, 91, 79, 0.15)',
  2: '0px 4px 12px 0px rgba(84, 91, 79, 0.15)',
  3: '0px 10px 30px 0px rgba(84, 91, 79, 0.15)',
  4: '0px 32px 40px 0px rgba(84, 91, 79, 0.2)',
} as const;

type Shadow = keyof typeof shadow;

export { shadow };
export type { Shadow };
