import { Button } from '@givelegacy/react';
import { useContext } from 'react';

import { layoutContext } from '../../layout/context';
import { HIDE_NEXT, Step } from '../step';

function LocationInvalidStep() {
  const { exitUrl } = useContext(layoutContext);

  return (
    <Step
      nextButtonContent={HIDE_NEXT}
      secondaryAction={
        <Button href={exitUrl} variant="secondary">
          Exit
        </Button>
      }
      title="We're sorry. Our services aren't yet available in your area."
    />
  );
}

export { LocationInvalidStep };
