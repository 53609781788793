import {
  Alcohol,
  Bike,
  Cannabis,
  Fitness,
  HotTub,
  Pill,
  RedMeat,
  Smoking,
  Supplements,
  Walk,
} from '@givelegacy/icons';
import { CCheckboxGroup, Text, useFC } from '@givelegacy/react';
import { useFormContext } from 'react-hook-form';

import { Step } from '../step';
import { type Schema, type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'lifestyle';
const lifestyleData: SchemaOptions<typeof schemaProperty> = [
  { label: 'Smoking tobacco', value: 'tobacco' },
  { label: 'Using cannabis', value: 'cannabis' },
  { label: 'Drinking alcohol', value: 'alcohol' },
  { label: 'Biking', value: 'biking' },
  { label: 'Walking or running', value: 'walkingOrRunning' },
  { label: 'Taking supplements, vitamins, or herbs', value: 'supplements' },
  { label: 'Taking anabolic steroids', value: 'anabolicSteroids' },
  { label: 'Lifting weights', value: 'liftingWeights' },
  { label: 'Cold plunging', value: 'coldPlunging' },
  { label: 'Using saunas or hot tubs', value: 'saunasOrHotTubs' },
  { label: 'Intermittent fasting', value: 'intermittentFasting' },
  { label: 'Meditating', value: 'meditating' },
  { label: 'Monitoring blood glucose', value: 'monitoringBloodGlucose' },
  { label: 'Eating red meat', value: 'eatingRedMeat' },
];

function LifestyleStep() {
  return (
    <Step title="Let's talk about your lifestyle. This is a no-judgment zone, but certain choices can influence your sperm health, for better or for worse.">
      <Text sx={{ mb: 2 }}>
        Are any of these part of your regular routine? Think about things you do at least 2-3 times
        a week.
      </Text>
      <Text>Select all that apply.</Text>
      <CCheckboxGroup data={lifestyleData} defaultValue={[]} name={schemaProperty} />
    </Step>
  );
}

function LifeStyleInfoStep() {
  const { getValues } = useFormContext<Schema>();

  const lifeStyleValues = getValues('lifestyle');

  const Section = useFC(Text, { sx: { borderBottom: 1, borderColor: 'evergreen300', py: 8 } });
  const SectionTitle = useFC(Text, { alt: true, as: 'div', sx: { mt: 2 } });

  return (
    <Step title="Your lifestyle may be impacting your sperm. Here's a quick overview, but the best way to understand your sperm quality is to do a semen analysis.">
      <Section>
        Sperm health isn't set in stone. You make new sperm every 72 days, so changing your habits
        can improve — or decrease — your sperm quality and fertility over time.
      </Section>
      {lifeStyleValues.includes('tobacco') ? (
        <Section>
          <Smoking />
          <SectionTitle>Smoking tobacco</SectionTitle>
          Cigarette smoking and tobacco use are very detrimental to sperm quality. Smokers are twice
          as likely to experience infertility, compared to non-smokers.
        </Section>
      ) : null}
      {lifeStyleValues.includes('cannabis') ? (
        <Section>
          <Cannabis />
          <SectionTitle>Using cannabis</SectionTitle>
          Studies show that those who use cannabis — AKA, smoke weed or take edibles — have, on
          average, 28% fewer sperm and 28% lower sperm quality.
        </Section>
      ) : null}
      {lifeStyleValues.includes('alcohol') ? (
        <Section>
          <Alcohol />
          <SectionTitle>Drinking alcohol</SectionTitle>
          Occasional or moderate drinking — fewer than 10 drinks per week — has not been shown to
          affect sperm health. But if you're drinking more than that, you may see lower sperm
          quality.
        </Section>
      ) : null}
      {lifeStyleValues.includes('biking') ? (
        <Section>
          <Bike />
          <SectionTitle>Biking</SectionTitle>
          Exercise is generally good for your fertility. But biking can put pressure on your testes
          and heat up the scrotum, affecting sperm health, especially if you're biking for 5 or more
          hours each week. (And yes, stationary bikes count!)
        </Section>
      ) : null}
      {lifeStyleValues.includes('walkingOrRunning') ? (
        <Section>
          <Walk />
          <SectionTitle>Walking or running</SectionTitle>
          Exercise has been associated with improved sperm motility (how sperm are moving) and
          morphology (how sperm are shaped). Keep it up!
        </Section>
      ) : null}
      {lifeStyleValues.includes('liftingWeights') ? (
        <Section>
          <Fitness />
          <SectionTitle>Lifting weights</SectionTitle>
          Research has shown that lifting heavy objects is correlated with improved sperm health.
          That means your gym routine may be benefiting your fertility.
        </Section>
      ) : null}
      {lifeStyleValues.includes('supplements') ? (
        <Section>
          <Pill />
          <SectionTitle>Taking supplements, vitamins, or herbs</SectionTitle>
          There's mixed evidence as to how effective supplements, vitamins, and herbs really are for
          improving sperm health. But a few show promise: Antioxidants like coenzyme Q10, selenium,
          and lycopene Vitamins C, D, and E L-carnitine Folate D-aspartic acid (D-AA) Zinc.
        </Section>
      ) : null}
      {lifeStyleValues.includes('anabolicSteroids') ? (
        <Section>
          <Supplements />
          <SectionTitle>Taking anabolic steroids</SectionTitle>
          Anabolic steroids have been shown to interfere with the hormones involved in creating
          sperm. Those who take steroids long-term are at risk for azoospermia, a condition in which
          no sperm is found in the semen.
        </Section>
      ) : null}
      {lifeStyleValues.includes('saunasOrHotTubs') ? (
        <Section>
          <HotTub />
          <SectionTitle>Using saunas or hot tubs</SectionTitle>
          The heat of a sauna or hot tub can temporarily reduce sperm production. Studies have found
          that using a sauna twice per week resulted in decreased sperm concentration and motility,
          as well as altered sperm DNA.
        </Section>
      ) : null}
      {lifeStyleValues.includes('eatingRedMeat') ? (
        <Section>
          <RedMeat />
          <SectionTitle>Eating red meat</SectionTitle>
          Contrary to common belief, eating red meat has been associated with lower sperm count and
          quality, vs. a diet high in fruits and veggies, complex carbs, and seafood.
        </Section>
      ) : null}
      <Text italic sx={{ py: 8 }}>
        Our semen analysis reports include recommendations for bettering your sperm health, based
        your lifestyle and individual results.
      </Text>
    </Step>
  );
}

export { LifestyleStep, LifeStyleInfoStep, lifestyleData };
