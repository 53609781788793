import { Button, Link, Text } from '@givelegacy/react';
import { useContext } from 'react';

import { layoutContext } from '../../layout/context';
import { Step } from '../step';

function SpermDonotrOrSurrogateInfoStep() {
  const { setHelpModal } = useContext(layoutContext);

  const title =
    "Heads up: Because you're planning to use a sperm donor or surrogate, there may be additional screenings necessary for you or your donor.";

  return (
    <Step
      secondaryAction={
        <Button onClick={openHelp} variant="secondary">
          Contact a client advisor
        </Button>
      }
      secondaryActionPosition="above"
      title={title}
    >
      <>
        <Text sx={{ mb: 2 }}>
          If you're considering sperm freezing, the FDA requires you or your donor to complete
          screenings prior to producing a sample. There are no pre-screenings required for an
          analysis.
        </Text>
        <Text>
          Want to learn more? Reach out to us at{' '}
          <Link
            href="mailto:clientservices@givelegacy.com"
            sx={{ color: 'evergreen900', textDecoration: 'underline' }}
            target="_blank"
            variant="body2"
          >
            clientservices@givelegacy.com
          </Link>{' '}
          or click below to find other ways to contact us. Click “Next” to continue taking the quiz
          in the meantime.
        </Text>
      </>
    </Step>
  );

  function openHelp() {
    setHelpModal(true);
  }
}

export { SpermDonotrOrSurrogateInfoStep };
