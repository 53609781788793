import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgArrowRightCircle(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M12 4.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM3.5 12a8.5 8.5 0 1 1 17 0 8.5 8.5 0 0 1-17 0ZM12 8.293l.354.353 3 3 .353.354-.353.354-3 3-.354.353-.707-.707.353-.354 2.147-2.146H8.5v-1h5.293l-2.147-2.146L11.293 9 12 8.293Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgArrowRightCircle;
