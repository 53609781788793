import { match } from 'ts-pattern';

import { type ComparisonTableProduct, type Product, productDict } from './steps/result';
import { type Schema } from './types';

interface RecommendationFactors {
  desiredCountSemenAnalysis: 1 | 2 | 3;
  desiredYearsOfFreezing: 0 | 1 | 5 | 25;
  goodLifestyle?: boolean;
  highAge: boolean;
  highNeed: boolean;
  needsAdvancedSemenAnalysis: boolean;
}

const GOOD_LIFESTYLE_SCORE_LIMIT = 3;
const HIGH_AGE_LIMIT = 34;

type ProductId = 143 | 146 | 186 | 190 | 144 | 145 | 184 | 156;
const possibleRecommendations = {
  standardSemenAnalysis: 143,
  advancedSemenAnalysis: 146,
  standardSemenAnalysisPlusOneYearStorage: 186,
  advancedSemenAnalysisPlusOneYearStorage: 190,
  forTomorrow: 144,
  forever: 145,
  standardSemenAnalysisPlusFiveYearStorage: 156,
  standardSemenAnalysisPlusTenYearStorage: 184,
} satisfies Record<string, ProductId>;

function getRecommendationFromAnswers(answers: Schema) {
  const recommendationFactors = getRecommendationFactorsFromAnswers(answers);
  const recommendation = getRecommendationFromRecommendationFactors(recommendationFactors);

  return { recommendationFactors, recommendation };
}

function getRecommendationFactorsFromAnswers(answers: Schema): RecommendationFactors {
  const highAge = answers.age > HIGH_AGE_LIMIT;
  const medicalTreatmentOverrideRecommendationFactors =
    getMedicalTreatmentOverrideMedicalTreatmentFactors(answers, highAge);

  if (medicalTreatmentOverrideRecommendationFactors) {
    return medicalTreatmentOverrideRecommendationFactors;
  }

  const lifeStyleScore = getLifestyleScoreFromLifestyleFactors(answers.lifestyle);
  const goodLifestyle = lifeStyleScore <= GOOD_LIFESTYLE_SCORE_LIMIT;
  return {
    desiredCountSemenAnalysis: evaluateDesiredCountSemenAnalysis(answers),
    desiredYearsOfFreezing: evaluateDesiredYearsOfFreezing(answers),
    needsAdvancedSemenAnalysis: evaluateNeedsAdvancedSemenAnalysis(answers, goodLifestyle, highAge),
    goodLifestyle,
    highNeed: evaluateHighNeed(answers),
    highAge,
  };
}

function evaluateDesiredCountSemenAnalysis(answers: Schema) {
  if (answers.wantKids === 'no') {
    return 1;
  } else if (answers.familySize === '1-2') {
    return 2;
  } else if (answers.familySize === '3+' || answers.familySize === 'unsure') {
    return 3;
  }

  return 1;
}

function evaluateDesiredYearsOfFreezing(answers: Schema) {
  if (answers.wantKids === 'no') {
    return 1;
  }

  return match(answers.whenKids)
    .returnType<RecommendationFactors['desiredYearsOfFreezing']>()
    .with(undefined, 'now', () => 0)
    .with('unsure', () => 1)
    .with('lessThan5', () => 5)
    .with('moreThan5', () => 25)
    .exhaustive();
}

function evaluateNeedsAdvancedSemenAnalysis(
  answers: Schema,
  goodLifestyle: boolean,
  highAge: boolean,
) {
  if (
    !goodLifestyle ||
    highAge ||
    answers.medical === 'fertilityTreatment' ||
    answers.howLongTryingForKids === 'over1Year' ||
    answers.prevPregnancy === '1' ||
    answers.prevPregnancy === '2+'
  ) {
    return true;
  }

  return false;
}

function evaluateHighNeed(answers: Schema) {
  if (answers.medical !== 'none') {
    return true;
  }

  return false;
}

function getMedicalTreatmentOverrideMedicalTreatmentFactors(
  answers: Schema,
  highAge: boolean,
): RecommendationFactors | null {
  switch (answers.medical) {
    case 'genderAffirming': {
      if (answers.genderAffirming === 'yes') {
        return {
          desiredCountSemenAnalysis: 1,
          desiredYearsOfFreezing: 0,
          needsAdvancedSemenAnalysis: false,
          highNeed: true,
          highAge,
        };
      }
      break;
    }
    case 'trt': {
      if (answers.trtStarted === 'yes') {
        return {
          desiredCountSemenAnalysis: 1,
          desiredYearsOfFreezing: 0,
          needsAdvancedSemenAnalysis: false,
          highNeed: true,
          highAge,
        };
      }
      break;
    }
    case 'cancerTreatment': {
      if (answers.cancerTreatment === 'yes') {
        return {
          desiredCountSemenAnalysis: 1,
          desiredYearsOfFreezing: 0,
          needsAdvancedSemenAnalysis: true,
          highNeed: true,
          highAge,
        };
      }
      break;
    }
    case 'vasectomy': {
      if (answers.vasectomy === 'yes') {
        return {
          desiredCountSemenAnalysis: 1,
          desiredYearsOfFreezing: 0,
          needsAdvancedSemenAnalysis: false,
          highNeed: true,
          highAge,
        };
      }
      break;
    }
    case 'vasectomyReversal': {
      return {
        desiredCountSemenAnalysis: 1,
        desiredYearsOfFreezing: 0,
        needsAdvancedSemenAnalysis: true,
        highNeed: true,
        highAge,
      };
    }
    case 'spermDonorOrSurrogate': {
      return {
        desiredCountSemenAnalysis: 1,
        desiredYearsOfFreezing: 0,
        needsAdvancedSemenAnalysis: false,
        highNeed: true,
        highAge,
      };
    }
    default: {
      break;
    }
  }

  return null;
}

function getLifestyleScoreFromLifestyleFactors(lifestyleFactors: Schema['lifestyle']): number {
  let lifestyleScore = 0;

  for (const lifestyleFactor of lifestyleFactors) {
    lifestyleScore += match(lifestyleFactor)
      .returnType<number>()
      .with('tobacco', () => 3)
      .with('cannabis', () => 2)
      .with('alcohol', () => 1)
      .with('biking', () => 2)
      .with('walkingOrRunning', () => -2)
      .with('supplements', () => -3)
      .with('anabolicSteroids', () => 4)
      .with('liftingWeights', () => -1)
      .with('coldPlunging', () => 0)
      .with('saunasOrHotTubs', () => 2)
      .with('intermittentFasting', () => 0)
      .with('meditating', () => -1)
      .with('monitoringBloodGlucose', () => 0)
      .with('eatingRedMeat', () => 2)
      .exhaustive();
  }

  return lifestyleScore;
}

function getRecommendationFromRecommendationFactors(recommendationFactors: RecommendationFactors) {
  let aggregateRecommendationScore = 0;

  aggregateRecommendationScore += match(recommendationFactors.desiredCountSemenAnalysis)
    .returnType<number>()
    .with(1, () => 2)
    .with(2, () => 3)
    .with(3, () => 4)
    .exhaustive();

  aggregateRecommendationScore += match(recommendationFactors.desiredYearsOfFreezing)
    .returnType<number>()
    .with(0, () => 0)
    .with(1, () => 3)
    .with(5, () => 6)
    .with(25, () => 10)
    .exhaustive();

  const needsFreezing = recommendationFactors.desiredYearsOfFreezing > 0;

  if (!needsFreezing) {
    if (recommendationFactors.needsAdvancedSemenAnalysis) {
      return possibleRecommendations.advancedSemenAnalysis;
    }

    return possibleRecommendations.standardSemenAnalysis;
  }

  if (!recommendationFactors.highNeed) {
    if (recommendationFactors.needsAdvancedSemenAnalysis) {
      return possibleRecommendations.advancedSemenAnalysisPlusOneYearStorage;
    }

    return possibleRecommendations.standardSemenAnalysisPlusOneYearStorage;
  }

  if (aggregateRecommendationScore <= 7) {
    if (recommendationFactors.needsAdvancedSemenAnalysis) {
      return possibleRecommendations.advancedSemenAnalysisPlusOneYearStorage;
    }

    return possibleRecommendations.standardSemenAnalysisPlusOneYearStorage;
  } else if (aggregateRecommendationScore <= 9) {
    return possibleRecommendations.forTomorrow;
  } else {
    return possibleRecommendations.forever;
  }
}

function getComparisonTableProducts(recommendedProduct: Product): ComparisonTableProduct[] {
  const comparisonTableProducts = [];

  comparisonTableProducts.push(
    resolveComparisonTableProductFromProduct(
      recommendedProduct,
      "Best for you based on what you've shared with us",
    ),
  );

  let alternativeProduct1: Product | undefined;
  let alternativeProduct1SubTitle = '';

  let alternativeProduct2: Product | undefined;
  let alternativeProduct2SubTitle = '';

  switch (recommendedProduct.id) {
    case possibleRecommendations.forever:
      alternativeProduct1 =
        productDict[possibleRecommendations.standardSemenAnalysisPlusTenYearStorage];
      alternativeProduct1SubTitle = "If you'd like more flexibility";

      alternativeProduct2 = productDict[possibleRecommendations.forTomorrow];
      alternativeProduct2SubTitle = 'For near-term family plans';
      break;
    case possibleRecommendations.forTomorrow:
      alternativeProduct1 =
        productDict[possibleRecommendations.standardSemenAnalysisPlusFiveYearStorage];
      alternativeProduct1SubTitle = "If you'd like more flexibility";

      alternativeProduct2 = productDict[possibleRecommendations.forever];
      alternativeProduct2SubTitle = 'To maximize the chance of having your dream family';
      break;
    case possibleRecommendations.advancedSemenAnalysisPlusOneYearStorage:
      alternativeProduct1 = productDict[possibleRecommendations.forTomorrow];
      alternativeProduct1SubTitle =
        'Longer storage, more samples and better value—for more peace of mind';

      alternativeProduct2 = productDict[possibleRecommendations.forever];
      alternativeProduct2SubTitle = 'Most samples and storage—maximum value and peace of mind';
      break;
    case possibleRecommendations.standardSemenAnalysisPlusOneYearStorage:
      alternativeProduct1 =
        productDict[possibleRecommendations.standardSemenAnalysisPlusFiveYearStorage];
      alternativeProduct1SubTitle = 'Longer storage—and better value—for more peace of mind';

      alternativeProduct2 = productDict[possibleRecommendations.forTomorrow];
      alternativeProduct2SubTitle =
        'Longer storage, more samples and better value—for more peace of mind';
      break;
    case possibleRecommendations.standardSemenAnalysis:
      alternativeProduct1 = productDict[possibleRecommendations.advancedSemenAnalysis];
      alternativeProduct1SubTitle = "If you'd like a deeper understanding of your sperm health";
      break;
    case possibleRecommendations.advancedSemenAnalysis:
      alternativeProduct1 = productDict[possibleRecommendations.standardSemenAnalysis];
      alternativeProduct1SubTitle = 'For a baseline understanding of your sperm';
      break;
    default:
      break;
  }

  if (alternativeProduct1) {
    comparisonTableProducts.push(
      resolveComparisonTableProductFromProduct(alternativeProduct1, alternativeProduct1SubTitle),
    );
  }

  if (alternativeProduct2) {
    comparisonTableProducts.push(
      resolveComparisonTableProductFromProduct(alternativeProduct2, alternativeProduct2SubTitle),
    );
  }

  return comparisonTableProducts;
}

function resolveComparisonTableProductFromProduct(
  product: Product,
  subTitle: string,
): ComparisonTableProduct {
  const advancedAnalysisCount = product.id !== 186 ? (product.advancedAnalysis ? 1 : 0) : undefined;

  return {
    ...product,
    addToCartUrl: `https://shop.givelegacy.com/cart.php?action=add&product_id=${product.id}`,
    advancedAnalysisCount,
    subTitle,
  };
}

export {
  possibleRecommendations,
  getRecommendationFromAnswers,
  getRecommendationFactorsFromAnswers,
  getRecommendationFromRecommendationFactors,
  getLifestyleScoreFromLifestyleFactors,
  getComparisonTableProducts,
};
export type { ProductId, RecommendationFactors };
