import { CRadioGroup } from '@givelegacy/react';

import { Step } from '../step';
import { type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'wantKids';
const wantKidsData: SchemaOptions<typeof schemaProperty> = [
  { label: 'Yes', value: 'yes' },
  { label: `I'm not sure`, value: 'unsure' },
  { label: 'No', value: 'no' },
];

function WantKidsStep() {
  return (
    <Step title="Do you want children in the future?">
      <CRadioGroup data={wantKidsData} name={schemaProperty} required />
    </Step>
  );
}

export { WantKidsStep };
