import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgFitness(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M6 6.5h-.5v2h-3v3h-2v1h2v3h3v2h4v-5h5v5h4v-2h3v-3h2v-1h-2v-3h-3v-2h-4v5h-5v-5H6Zm12.5 3v5h2v-5h-2Zm-1-1v-1h-2v9h2v-8Zm-9-1h-2v9h2v-9Zm-3 7v-5h-2v5h2Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgFitness;
