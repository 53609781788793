import { type Exact, type TupleToUnion } from 'type-fest';

import { type ResponsiveArray, usePropsMerge, useResponsiveArray } from '../../hooks';
import { type As, Box, type BoxProps } from '../box';
import { textCss } from './text.css';

const textDisplayVariants = ['display1', 'display2'] as const;
const textHeadingVariants = [
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'heading5',
  'heading6',
] as const;
const textBodyVariants = ['body1', 'body2', 'body3', 'body4'] as const;
const textCapsVariants = ['caption1', 'caption2', 'caption3'] as const;
const textVariants = [
  ...textDisplayVariants,
  ...textHeadingVariants,
  ...textBodyVariants,
  ...textCapsVariants,
] as const;

type TextDisplayType = TupleToUnion<typeof textDisplayVariants>;
type TextHeadingType = TupleToUnion<typeof textHeadingVariants>;
type TextBodyType = TupleToUnion<typeof textBodyVariants>;
type TextCapsType = TupleToUnion<typeof textCapsVariants>;
type Variant = 'inherit' | TextBodyType | TextCapsType | TextDisplayType | TextHeadingType;

interface TextProps<E extends As = As> extends BoxProps<E> {
  readonly alt?: boolean;
  readonly italic?: boolean;
  readonly underline?: boolean;
  readonly variant?: ResponsiveArray<Variant>;
}

function Text<E extends As = As>(props: TextProps<E>) {
  const { alt, as = 'p' as E, children, italic, underline, variant = 'inherit', ...rest } = props;
  rest satisfies Exact<BoxProps<E>, typeof rest>;

  const css = textCss({ alt, variant: useResponsiveArray(variant) });

  const mergedProps = usePropsMerge<TextProps<E>>(
    {
      className: css,
      sx: {
        color: 'evergreen800',
        fontStyle: italic ? 'italic' : undefined,
        textDecoration: underline ? 'underline' : undefined,
      },
    },
    rest,
  );

  return (
    <Box as={as} {...mergedProps}>
      {children}
    </Box>
  );
}

export { Text, textVariants };
export type { TextBodyType, TextCapsType, TextDisplayType, TextHeadingType, TextProps };
