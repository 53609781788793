import { type FC, useCallback } from 'react';

/** Convenience wrapper for using Functional Components within another Component */
function useFC<P>(Component: FC<P>, staticProps: Partial<P>) {
  return useCallback(
    (props: P) => <Component {...staticProps} {...props} />,
    [Component, staticProps],
  );
}

export { useFC };
