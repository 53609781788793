import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgSmoking(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M17 3.5h-.5v1h1a2 2 0 1 1 0 4H17v1a4 4 0 0 1 4 4v2h1v-2a5 5 0 0 0-2.831-4.507A3 3 0 0 0 17.5 3.5H17Zm-6.5 6a3 3 0 0 1 3-3h1v1h-1a2 2 0 1 0 0 4h2.833a3.167 3.167 0 0 1 3.167 3.167v.833h-1v-.833c0-1.197-.97-2.167-2.167-2.167H13.5a3 3 0 0 1-3-3Zm-9 7H22v4H1.5v-4Zm1 1v2h16v-2h-16Zm17 0v2H21v-2h-1.5Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgSmoking;
