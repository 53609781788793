import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgBike(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M16 3.5C15.1716 3.5 14.5 4.17157 14.5 5C14.5 5.82843 15.1716 6.5 16 6.5C16.8284 6.5 17.5 5.82843 17.5 5C17.5 4.17157 16.8284 3.5 16 3.5ZM13.5 5C13.5 3.61929 14.6193 2.5 16 2.5C17.3807 2.5 18.5 3.61929 18.5 5C18.5 6.38071 17.3807 7.5 16 7.5C14.6193 7.5 13.5 6.38071 13.5 5ZM2.5 17.5C2.5 15.8431 3.84315 14.5 5.5 14.5C7.15685 14.5 8.5 15.8431 8.5 17.5C8.5 19.1569 7.15685 20.5 5.5 20.5C3.84315 20.5 2.5 19.1569 2.5 17.5ZM5.5 13.5C3.29086 13.5 1.5 15.2909 1.5 17.5C1.5 19.7091 3.29086 21.5 5.5 21.5C7.70914 21.5 9.5 19.7091 9.5 17.5C9.5 15.2909 7.70914 13.5 5.5 13.5ZM15.5 17.5C15.5 15.8431 16.8431 14.5 18.5 14.5C20.1569 14.5 21.5 15.8431 21.5 17.5C21.5 19.1569 20.1569 20.5 18.5 20.5C16.8431 20.5 15.5 19.1569 15.5 17.5ZM18.5 13.5C16.2909 13.5 14.5 15.2909 14.5 17.5C14.5 19.7091 16.2909 21.5 18.5 21.5C20.7091 21.5 22.5 19.7091 22.5 17.5C22.5 15.2909 20.7091 13.5 18.5 13.5ZM13.416 7.72265C13.3398 7.60831 13.2199 7.53038 13.0844 7.50718C12.949 7.48398 12.8099 7.51755 12.7 7.6L8.7 10.6C8.58404 10.687 8.51153 10.82 8.50126 10.9646C8.49098 11.1091 8.54395 11.2511 8.64645 11.3536L11.5 14.2071V17.5C11.5 17.7761 11.7239 18 12 18C12.2761 18 12.5 17.7761 12.5 17.5V14C12.5 13.8674 12.4473 13.7402 12.3536 13.6464L9.7612 11.0541L12.8772 8.71713L14.584 11.2774C14.6767 11.4164 14.8328 11.5 15 11.5H17C17.2761 11.5 17.5 11.2761 17.5 11C17.5 10.7239 17.2761 10.5 17 10.5H15.2676L13.416 7.72265Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgBike;
