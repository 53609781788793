import { Text } from '@givelegacy/react';

import { Step } from '../step';

function Under18InfoStep() {
  return (
    <Step nextButtonContent="I understand" title="Looks like you're under 18.">
      <Text>
        You are able to use Legacy's services, but you'll need a parent or guardian to sign a
        consent form.
      </Text>
    </Step>
  );
}

export { Under18InfoStep };
