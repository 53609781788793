import { ArrowLeft } from '@givelegacy/icons';
import { Box, Button, Grid, Text } from '@givelegacy/react';
import { type ReactNode, useContext } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';

import { layoutContext } from '../../layout/context';
import { wizardContext } from '../context';
import { firstStep } from '../index';

const HIDE_NEXT = 'HIDE_NEXT';

interface StepProps {
  readonly children?: ReactNode;
  readonly footerInfo?: ReactNode;
  readonly infoStep?: boolean;
  readonly nextButtonContent?: ReactNode | typeof HIDE_NEXT;
  readonly secondaryAction?: ReactNode;
  readonly secondaryActionPosition?: 'above' | 'below';
  readonly title: ReactNode;
}

function Step(props: StepProps) {
  const {
    children,
    footerInfo,
    infoStep,
    nextButtonContent,
    secondaryAction,
    title,
    secondaryActionPosition = 'below',
  } = props;

  const { headerRef } = useContext(layoutContext);
  const { activeStep, onBack, onNext } = useContext(wizardContext);

  const { formState } = useFormContext();
  const { isValid } = formState;

  const isResult = activeStep === 'result';

  return (
    <>
      {headerRef.current
        ? createPortal(
            <Grid sx={{ gridArea: 'left-side', justifyContent: 'start' }}>
              {activeStep !== firstStep ? (
                <Button icon onClick={onBack} size="xs" variant="tertiary">
                  <ArrowLeft />
                </Button>
              ) : null}
            </Grid>,
            headerRef.current,
          )
        : null}
      <Grid sx={{ gridAutoFlow: 'row', gridTemplateRows: '1fr auto', height: 'full' }}>
        <Grid
          sx={{
            overflow: 'auto',
            pb: [footerInfo ? 20 : undefined, undefined, 16],
            pt: [undefined, undefined, 16],
          }}
        >
          <Grid
            sx={{
              alignContent: 'start',
              justifySelf: 'center',
              maxWidth: 150,
              p: 4,
              rowGap: 4,
              width: 'full',
            }}
            vertical
          >
            <Text
              alt
              sx={{ mb: 1 }}
              variant={isResult ? ['heading6', 'heading2'] : ['heading6', 'heading5']}
            >
              {title}
            </Text>
            {children}
          </Grid>
        </Grid>

        <Box>
          {footerInfo ? (
            <Box sx={{ maxWidth: 150, mb: 4, px: [4, 0], width: 'full', ml: 'auto', mr: 'auto' }}>
              {footerInfo}
            </Box>
          ) : null}
          <Grid sx={{ borderTop: 1, borderColor: 'evergreen300', p: 4 }}>
            <Grid
              sx={{
                justifySelf: 'center',
                maxWidth: 150,
                rowGap: 2,
                width: 'full',
                px: 0,
                // @ts-expect-error
                '@media screen and (min-width: 616px)': { px: 4 },
              }}
              vertical
            >
              {secondaryActionPosition === 'above' && secondaryAction}
              {nextButtonContent !== HIDE_NEXT ? (
                <Button
                  attrs={{ 'data-test': 'next-button' }}
                  autoFocus={infoStep}
                  disabled={!isValid}
                  fullWidth
                  onClick={onNext}
                  type="submit"
                >
                  {nextButtonContent ?? 'Next'}
                </Button>
              ) : null}
              {secondaryActionPosition === 'below' && secondaryAction}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}

export { HIDE_NEXT, Step };
export type { StepProps };
