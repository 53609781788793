import { isArray } from 'remeda';

import { useViewport } from './use_viewport';

type ResponsiveArray<T, U = T | undefined, Mobile = U, Tablet = U, Desktop = U> =
  | U
  | [Mobile]
  | [Mobile, Tablet]
  | [Mobile, Tablet, Desktop];

function useResponsiveArray<T>(responsiveArray: ResponsiveArray<T>) {
  const { isMobile, isTablet } = useViewport();

  if (!isArray(responsiveArray)) return responsiveArray;

  // Mobile-first design means larger viewports will inherit from smaller by default
  if (isMobile) return responsiveArray[0];
  if (isTablet) return responsiveArray[1] ?? responsiveArray[0];
  return responsiveArray[2] ?? responsiveArray[1] ?? responsiveArray[0];
}

export { useResponsiveArray };
export type { ResponsiveArray };
