import { AnalyticsBrowser } from '@segment/analytics-next';

const { PUBLIC_SEGMENT_KEY: writeKey } = import.meta.env;
const isMock = writeKey === 'mock';

const mock = {
  identify: (...args: unknown[]) => console.info('[Segment identify]', args),
  page: (...args: unknown[]) => console.info('[Segment page]', args),
  track: (...args: unknown[]) => console.info('[Segment track]', args),
  user: () => Promise.resolve({ anonymousId: () => 'MOCK_ANONYMOUS_ID' }),
} as AnalyticsBrowser;

const analytics = !isMock ?
    AnalyticsBrowser.load(
        {
            writeKey,
            cdnURL: 'https://analytics.givelegacy.com'
        },
        {
            integrations: {
                "Segment.io": {
                    apiHost: "analytics-api.givelegacy.com/v1"
                }
            }
        },
    ) :
    mock;

async function getAnonymousId() {
  const user = await analytics.user();
  return user.anonymousId();
}

export { analytics, getAnonymousId };
