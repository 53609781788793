import { connect, machine } from '@zag-js/radio-group';
import { normalizeProps, useMachine } from '@zag-js/react';
import { type ReactNode, useEffect, useMemo } from 'react';
import { type Exact } from 'type-fest';

import { useControllableId, usePropsMerge } from '../../hooks';
import { Grid, type GridProps } from '../box';
import { Radio } from './radio';
import { RadioButton } from './radio_button';
import {
  type RadioGroupContext,
  radioGroupContext,
  type ZagRadioGroupContext,
} from './radio_group_context';

interface RadioItem {
  label: string;
  value: string;
}

interface RadioGroupProps extends GridProps, ZagRadioGroupContext {
  readonly autoFocus?: boolean;
  readonly children?: ReactNode;
  readonly data?: RadioItem[];
  readonly disabled?: boolean;
  readonly error?: string;
  readonly radioComponent?: 'Radio' | 'RadioButton';
  readonly required?: boolean;
  readonly theme?: RadioGroupContext['theme'];
  readonly value?: string;
}

function RadioGroup(props: RadioGroupProps) {
  const {
    as,
    autoFocus,
    attrs,
    children,
    className,
    data,
    disabled,
    error,
    id,
    inline = false,
    radioComponent = 'radio',
    required,
    rootRef,
    sx,
    theme = 'light',
    vertical = true,
    ...rest
  } = props;
  rest satisfies Exact<ZagRadioGroupContext, typeof rest>;

  const cId = useControllableId(id);
  const [state, send] = useMachine(machine({ id: cId }), { context: rest });
  const api = connect(state, send, normalizeProps);

  const context = useMemo(
    () => ({ ...api, config: rest, disabled, error, required, theme }),
    [api, disabled, error, required, theme, rest],
  );

  const mergedProps = usePropsMerge<GridProps>(
    { attrs: api.rootProps, sx: { rowGap: 4 } },
    { attrs, className, inline, rootRef, sx, vertical },
  );

  const RadioComponent = radioComponent === 'Radio' ? Radio : RadioButton;

  useEffect(() => {
    if (autoFocus) api.focus();
  }, [api, autoFocus]);

  return (
    <radioGroupContext.Provider value={context}>
      <Grid as={as} {...mergedProps}>
        {children}
        {data?.map((item: RadioItem) => (
          <RadioComponent key={item.value} value={item.value}>
            {item.label}
          </RadioComponent>
        ))}
      </Grid>
    </radioGroupContext.Provider>
  );
}

export { RadioGroup };
export type { RadioGroupProps };
