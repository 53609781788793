/**
 * Generated by orval v6.19.1 🍺
 * Do not edit manually.
 * Profiles.Api
 * OpenAPI spec version: 1.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  CreateQuizRequest,
  GetQuizzesParams,
  ProblemDetails,
  QuizResponse,
  UpdateQuizRequest,
  ValidationFailureResponse,
} from '../profilesApi.schemas';
import { useCustomInstance } from '../../../src/axios_client';

export const useGetQuizzesIdHook = () => {
  const getQuizzesId = useCustomInstance<QuizResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getQuizzesId({ url: `/quizzes/${id}`, method: 'get', signal });
  };
};

export const getGetQuizzesIdQueryKey = (id: string) => {
  return [`/quizzes/${id}`] as const;
};

export const useGetQuizzesIdInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>>,
  TError = ProblemDetails,
>(
  id: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizzesIdQueryKey(id);

  const getQuizzesId = useGetQuizzesIdHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>> = ({
    signal,
  }) => getQuizzesId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizzesIdInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>
>;
export type GetQuizzesIdInfiniteQueryError = ProblemDetails;

export const useGetQuizzesIdInfinite = <
  TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>>,
  TError = ProblemDetails,
>(
  id: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>,
        TError,
        TData
      >
    >;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetQuizzesIdInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetQuizzesIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>,
  TError = ProblemDetails,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizzesIdQueryKey(id);

  const getQuizzesId = useGetQuizzesIdHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>> = ({
    signal,
  }) => getQuizzesId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizzesIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>
>;
export type GetQuizzesIdQueryError = ProblemDetails;

export const useGetQuizzesId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>,
  TError = ProblemDetails,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQuizzesIdHook>>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetQuizzesIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePutQuizzesIdHook = () => {
  const putQuizzesId = useCustomInstance<QuizResponse>();

  return (id: string, updateQuizRequest: UpdateQuizRequest) => {
    return putQuizzesId({
      url: `/quizzes/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateQuizRequest,
    });
  };
};

export const usePutQuizzesIdMutationOptions = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutQuizzesIdHook>>>,
    TError,
    { id: string; data: UpdateQuizRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutQuizzesIdHook>>>,
  TError,
  { id: string; data: UpdateQuizRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putQuizzesId = usePutQuizzesIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutQuizzesIdHook>>>,
    { id: string; data: UpdateQuizRequest }
  > = (props) => {
    const { id, data } = props ?? {};

    return putQuizzesId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutQuizzesIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutQuizzesIdHook>>>
>;
export type PutQuizzesIdMutationBody = UpdateQuizRequest;
export type PutQuizzesIdMutationError = ProblemDetails;

export const usePutQuizzesId = <TError = ProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutQuizzesIdHook>>>,
    TError,
    { id: string; data: UpdateQuizRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePutQuizzesIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useGetQuizzesHook = () => {
  const getQuizzes = useCustomInstance<QuizResponse>();

  return (params?: GetQuizzesParams, signal?: AbortSignal) => {
    return getQuizzes({ url: `/quizzes`, method: 'get', params, signal });
  };
};

export const getGetQuizzesQueryKey = (params?: GetQuizzesParams) => {
  return [`/quizzes`, ...(params ? [params] : [])] as const;
};

export const useGetQuizzesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>>,
  TError = unknown,
>(
  params?: GetQuizzesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizzesQueryKey(params);

  const getQuizzes = useGetQuizzesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>> = ({
    signal,
  }) => getQuizzes(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizzesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>
>;
export type GetQuizzesInfiniteQueryError = unknown;

export const useGetQuizzesInfinite = <
  TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>>,
  TError = unknown,
>(
  params?: GetQuizzesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>,
        TError,
        TData
      >
    >;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetQuizzesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetQuizzesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>,
  TError = unknown,
>(
  params?: GetQuizzesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuizzesQueryKey(params);

  const getQuizzes = useGetQuizzesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>> = ({
    signal,
  }) => getQuizzes(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetQuizzesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>
>;
export type GetQuizzesQueryError = unknown;

export const useGetQuizzes = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>,
  TError = unknown,
>(
  params?: GetQuizzesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQuizzesHook>>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetQuizzesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostQuizzesHook = () => {
  const postQuizzes = useCustomInstance<QuizResponse>();

  return (createQuizRequest: CreateQuizRequest) => {
    return postQuizzes({
      url: `/quizzes`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createQuizRequest,
    });
  };
};

export const usePostQuizzesMutationOptions = <
  TError = ValidationFailureResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostQuizzesHook>>>,
    TError,
    { data: CreateQuizRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostQuizzesHook>>>,
  TError,
  { data: CreateQuizRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postQuizzes = usePostQuizzesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostQuizzesHook>>>,
    { data: CreateQuizRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postQuizzes(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostQuizzesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostQuizzesHook>>>
>;
export type PostQuizzesMutationBody = CreateQuizRequest;
export type PostQuizzesMutationError = ValidationFailureResponse;

export const usePostQuizzes = <TError = ValidationFailureResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostQuizzesHook>>>,
    TError,
    { data: CreateQuizRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostQuizzesMutationOptions(options);

  return useMutation(mutationOptions);
};
