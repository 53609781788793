import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgChevronUp(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="m12 7.293.354.353 8 8 .353.354-.707.707-.354-.354L12 8.707l-7.646 7.646-.354.354L3.293 16l.353-.354 8-8L12 7.293Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgChevronUp;
