import { fontSizes, lineHeights } from '@givelegacy/style';
import { type ChangeEventHandler, type HTMLInputTypeAttribute, type ReactNode } from 'react';
import { type EmptyObject, type Exact, type Except } from 'type-fest';

import { useNanoid, usePropsMerge } from '../../hooks';
import { Box, type BoxProps, Grid, type GridProps } from '../box';
import { Text, type TextProps } from '../text';

const labelAsPlaceholderStyles = {
  fontSize: fontSizes.body3,
  lineHeight: lineHeights.body3,
  gridArea: 'input',
  pointerEvents: 'unset',
} as const;

const placeholder = ':placeholder-shown + span';
const inputRawCss = {
  '&::placeholder': { color: 'transparent' },
  '&[type="number"]': { appearance: 'textfield' },
  '&[type="number"]::-webkit-inner-spin-button, &[type="number"]::-webkit-outer-spin-button': {
    appearance: 'none',
    margin: 0,
  },
  [`&:not(:focus)${placeholder}`]: labelAsPlaceholderStyles,
  [`&[data-label-static-on-focus="true"]${placeholder}`]: labelAsPlaceholderStyles,
} as const;

interface InputProps extends Except<BoxProps<'label'>, 'as' | 'children'> {
  readonly autoComplete?: string;
  readonly autoFocus?: boolean;
  readonly defaultValue?: string;
  readonly disabled?: boolean;
  readonly error?: string;
  readonly inputProps?: BoxProps<'input'>;
  readonly label: ReactNode;
  readonly onChange?: ChangeEventHandler<HTMLInputElement>;
  readonly required?: boolean;
  readonly rightAddon?: ReactNode;
  readonly theme?: 'dark' | 'light';
  readonly type?: HTMLInputTypeAttribute;
  readonly value?: string;
}

interface Private {
  readonly labelStaticOnFocus?: boolean;
}

function Input(props: InputProps & Private) {
  const {
    attrs,
    autoComplete = 'off',
    autoFocus,
    className,
    defaultValue,
    disabled,
    error,
    inputProps = {},
    label,
    labelStaticOnFocus,
    onChange,
    required,
    rightAddon,
    rootRef,
    sx,
    theme: _TODO = 'light',
    type = 'text',
    value,
    ...rest
  } = props;
  rest satisfies Exact<EmptyObject, typeof rest>;

  const inputId = useNanoid();
  const mergedProps = usePropsMerge<GridProps<'label'>>(
    {
      attrs: { htmlFor: inputProps?.attrs?.id ?? inputId, 'data-foo': '' },
      inline: true,
      sx: {
        alignContent: 'center',
        border: 1,
        borderColor: 'evergreen300',
        borderRadius: 2,
        cursor: 'text',
        gridTemplateAreas: '"label rightAddon" "input rightAddon"',
        gridTemplateColumns: '1fr auto',
        height: 14,
        px: 2,
        '&:focus-within': {
          border: 2,
          borderColor: 'mint',
        },
      },
    },
    { attrs, className, rootRef, sx },
  );
  const mergedInputProps = usePropsMerge<TextProps<'input'>>(
    {
      attrs: {
        autoComplete,
        autoFocus,
        defaultValue,
        disabled,
        id: inputId,
        onChange,
        placeholder: 'used by the CSS',
        required,
        type,
        value,
        'data-label-static-on-focus': labelStaticOnFocus ? 'true' : undefined,
        // https://1password.community/discussion/comment/677269/#Comment_677269
        'data-1p-ignore': autoComplete === 'off' ? '' : undefined,
      },
      sx: { raw: inputRawCss, color: 'evergreen800', gridArea: 'input', height: 6 },
      variant: 'body2',
    },
    inputProps,
  );

  return (
    <Grid sx={{ gridAutoFlow: 'row' }}>
      <Grid as="label" {...mergedProps}>
        <Text as="input" {...mergedInputProps} />
        <Text
          as="span"
          sx={{ color: 'evergreen600', gridArea: 'label', pointerEvents: 'none' }}
          variant="body4"
        >
          {label}
        </Text>
        {rightAddon ? (
          <Box sx={{ alignSelf: 'center', gridArea: 'rightAddon' }}>{rightAddon}</Box>
        ) : null}
      </Grid>
      {error ? <Text>{error}</Text> : null}
    </Grid>
  );
}

export { Input };
export type { InputProps };
