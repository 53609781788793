import { Auth0Provider } from '@givelegacy/auth0';
import { LegacyApiProvider } from '@givelegacy/legacy-api';
import { MockProvider } from '@givelegacy/mock';
import { analytics } from '@givelegacy/segment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { Fragment, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './layout/app';

const { DEV, PROD, PUBLIC_POSTHOG_KEY, PUBLIC_POSTHOG_HOST } = import.meta.env;

const container = document.querySelector('#root') as HTMLElement;
const root = createRoot(container);

const queryClient = new QueryClient();

const UseEnabledMocks = DEV ? MockProvider : Fragment;

function initPosthog() {
  if (PROD && typeof window !== 'undefined') {
    posthog.init(PUBLIC_POSTHOG_KEY, {
      api_host: PUBLIC_POSTHOG_HOST,
      disable_session_recording: true,
      capture_pageview: false,
      segment: analytics?.instance,
      session_recording: {
        maskAllInputs: true,
        maskTextSelector: '*', // Masks all text elements (not including inputs)
      },
      loaded: (posthog) => {
        posthog.startSessionRecording();
      },
    });
  }
}
initPosthog();

root.render(
  <StrictMode>
    <UseEnabledMocks>
      <Auth0Provider>
        <QueryClientProvider client={queryClient}>
          <LegacyApiProvider>
            <PostHogProvider client={posthog}>
              <App />
            </PostHogProvider>
          </LegacyApiProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </UseEnabledMocks>
  </StrictMode>,
);
