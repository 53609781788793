import { Quiz, type QuizResponse } from '@givelegacy/legacy-api';
import { CForm } from '@givelegacy/react';
import { analytics, useAnonymousId } from '@givelegacy/segment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { dropLast, last } from 'remeda';

import { wizardContext } from './context';
import { flow, stepDict, type StepName } from './flow';
import { getRecommendationFromAnswers } from './recommendation';
import { type Schema, type SchemaProperty } from './types';

const firstStep = 'intro' as const satisfies StepName;

function Wizard() {
  const [activeStep, setActiveStep] = useState<StepName>(firstStep);
  const [stepHistory, setStepHistory] = useState<StepName[]>([activeStep]);

  const formRef = useRef<HTMLFormElement>(null);

  const rhfForm = useForm<Schema>({ mode: 'all' });
  const { formState, getValues, reset, resetField, trigger } = rhfForm;

  const postQuiz = Quiz.usePostQuizzes();
  const putQuiz = Quiz.usePutQuizzesId();
  const [quizIdPromise, setQuizIdPromise] = useState<Promise<QuizResponse>>();
  const { anonymousId } = useAnonymousId();

  const apiHandler = useCallback(createOrUpdateQuiz, [
    getValues,
    postQuiz,
    putQuiz,
    quizIdPromise,
    anonymousId,
  ]);
  const onBack = useCallback(handleBack, [formState.dirtyFields, resetField, stepHistory]);
  const onNext = useCallback(handleNext, [activeStep, apiHandler, getValues, stepHistory]);
  const onReset = useCallback(resetWizard, [reset]);
  const context = useMemo(
    () => ({ activeStep, onBack, onNext, onReset }),
    [activeStep, onBack, onNext, onReset],
  );

  const CurrentStep = stepDict[activeStep];
  useEffect(() => {
    trigger();
  }, [activeStep, trigger]);

  useEffect(() => {
    analytics.page(`quiz.givelegacy.com/${activeStep}`);
    analytics.track('Quiz Step Viewed', { step: `${toKebabCase(activeStep)}` });
  }, [activeStep]);

  return (
    <wizardContext.Provider value={context}>
      <CForm rhfForm={rhfForm} rootRef={formRef} sx={{ overflow: 'hidden' }}>
        <CurrentStep />
      </CForm>
    </wizardContext.Provider>
  );

  function toKebabCase(str: string) {
    return str.replaceAll(/[A-Z]/g, (m) => '-' + m.toLowerCase());
  }

  function handleBack() {
    const fieldsToReset = Object.keys(formState.dirtyFields) as SchemaProperty[];
    for (const field of fieldsToReset) {
      const fieldElem = formRef.current?.querySelector(`[name="${field}"]`);
      if (fieldElem) resetField(field);
    }

    const nextStepHistory = dropLast(stepHistory, 1);
    const nextActiveStep = last(nextStepHistory);
    setActiveStep(nextActiveStep || firstStep);
    setStepHistory(nextStepHistory);
  }

  function handleNext() {
    analytics.track('Quiz Step Completed', { step: `${toKebabCase(activeStep)}` });
    if (activeStep === 'result') return;

    const nextFlow = flow[activeStep];
    const nextActiveStep = typeof nextFlow === 'string' ? nextFlow : nextFlow(getValues());
    setActiveStep(nextActiveStep);
    setStepHistory([...stepHistory, nextActiveStep]);

    apiHandler(nextActiveStep);
  }

  async function createOrUpdateQuiz(nextActiveStep: StepName) {
    const formData = getValues();
    const isResult = nextActiveStep === 'result';

    const sharedData = {
      recommendations: [] as string[],
      responses: formData,
      status: isResult ? 'Complete' : 'In Progress',
    };

    if (isResult) {
      const id = getRecommendationFromAnswers(formData).recommendation.toString();
      sharedData.recommendations = [id];
    }

    if (quizIdPromise) {
      const id = (await quizIdPromise).id ?? '';
      putQuiz.mutate({ id, data: sharedData });
      return;
    }

    const data = {
      segment_user_id: anonymousId,
      ...sharedData,
    };
    const quizResponse = postQuiz.mutateAsync({ data });
    setQuizIdPromise(quizResponse);
  }

  function resetWizard() {
    setActiveStep(firstStep);
    setStepHistory([firstStep]);
    reset();
  }
}

export { Wizard, firstStep };
