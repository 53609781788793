import { createContext, type MutableRefObject } from 'react';
import { noop } from 'remeda';

interface LayoutContext {
  exitUrl: string;
  headerRef: MutableRefObject<HTMLDivElement | null>;
  helpModal: boolean;
  setHelpModal: (helpModal: boolean) => void;
}

const refMock: MutableRefObject<null> = { current: null };

const layoutContext = createContext<LayoutContext>({
  exitUrl: 'DEFAULT',
  headerRef: refMock,
  helpModal: false,
  setHelpModal: noop,
});

export { layoutContext };
export type { LayoutContext };
