import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgChevronDownSmall(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="m8 9.293.353.353L12 13.293l3.646-3.647.354-.353.707.707-.354.353-4 4-.353.354-.354-.354-4-4L7.293 10 8 9.293Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgChevronDownSmall;
