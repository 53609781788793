import { type ReactNode } from 'react';
import { type Exact, type Except } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { Text, type TextProps } from '../text';

interface LinkProps extends Except<TextProps<'a'>, 'as'> {
  readonly children?: ReactNode;
  readonly href: string;
  readonly target?: '_blank' | '_self';
  readonly theme?: 'dark' | 'light';
}

// TODO: match how Button uses textProps
function Link(props: LinkProps) {
  const { children, href, target, theme = 'light', ...rest } = props;
  rest satisfies Exact<TextProps<'a'>, typeof rest>;

  const mergedProps = usePropsMerge<TextProps<'a'>>(
    {
      attrs: { href, target },
      sx: {
        color: theme === 'light' ? 'evergreen600' : 'white',
        '&:hover': { textDecoration: 'underline' },
      },
    },
    rest,
  );

  return (
    <Text as="a" {...mergedProps}>
      {children}
    </Text>
  );
}

export { Link };
export type { LinkProps };
