import {
  ArrowRightCircle,
  CheckCircle,
  CheckCircleLarge,
  ChevronDown,
  ChevronUp,
  XCircle,
} from '@givelegacy/icons';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Button,
  Grid,
  Image,
  Link,
  Text,
  useFC,
  useResponsiveArray,
} from '@givelegacy/react';
import { analytics } from '@givelegacy/segment';
import { type ReactNode, useContext, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import advancedSemenAnalysisImage from '../../../assets/images/advanced_semen_analysis.webp';
import forTomorrowImage from '../../../assets/images/for_tomorrow.webp';
import foreverImage from '../../../assets/images/forever.webp';
import freezingImage from '../../../assets/images/freezing.webp';
import learnHowItWorksImage from '../../../assets/images/learn-how-it-works.webp';
import aetnaLogo from '../../../assets/images/logo-aetna.webp';
import affirmLogo from '../../../assets/images/logo-affirm.webp';
import carrotLogo from '../../../assets/images/logo-carrot.webp';
import cignaLogo from '../../../assets/images/logo-cigna.webp';
import emblemHealthLogo from '../../../assets/images/logo-emblemhealth.webp';
import greenBeretFoundationLogo from '../../../assets/images/logo-greenberetfoundation.webp';
import mavenLogo from '../../../assets/images/logo-maven.webp';
import militaryFamilyBuildingCoalition from '../../../assets/images/logo-militaryfamilybuildingcoalition.webp';
import progynyLogo from '../../../assets/images/logo-progyny.webp';
import splitItLogo from '../../../assets/images/logo-splitit.webp';
import storkClubLogo from '../../../assets/images/logo-storkclub.webp';
import triWestLogo from '../../../assets/images/logo-triwest.webp';
import unitedHealthcareLogo from '../../../assets/images/logo-unitedhealthcare.webp';
import veteransAdvantageLogo from '../../../assets/images/logo-veteransadvantage.webp';
import veteransAffairsLogo from '../../../assets/images/logo-veteransaffairs.webp';
import winFertilityLogo from '../../../assets/images/logo-winfertility.webp';
import sampleCupImage from '../../../assets/images/sample_cup.webp';
import shopAllProductsImage from '../../../assets/images/shop-all-products.webp';
import standardSemenAnalysisImage from '../../../assets/images/standard_semen_analysis.webp';
import standardSemenAnalysisFreezingImage from '../../../assets/images/standard_semen_analysis_freezing.webp';
import stiKitImage from '../../../assets/images/sti_kit.webp';
import { layoutContext } from '../../layout/context';
import {
  getComparisonTableProducts,
  getRecommendationFromAnswers,
  type ProductId,
  type RecommendationFactors,
} from '../recommendation';
import { HIDE_NEXT, Step } from '../step';
import { type Schema } from '../types';

interface Product {
  advancedAnalysis: boolean;
  description: string;
  freezing: number;
  freezingCostPerSample: number;
  frozenSampleCount: number;
  id: number;
  image: string;
  price: number;
  sku: string;
  standardSampleCount: number;
  stiKit: boolean;
  title: string;
  url: string;
}

interface ComparisonTableProduct {
  addToCartUrl: string;
  advancedAnalysisCount?: number;
  freezing: number;
  freezingCostPerSample: number;
  frozenSampleCount: number;
  image: string;
  price: number;
  stiKit: boolean;
  subTitle: string;
  title: string;
}

const productDict = {
  146: {
    advancedAnalysis: true,
    description: `Get a deeper insight into your fertility. This testing bundle evaluates sperm count, motility, and morphology plus DNA fragmentation, a measure of sperm's genetic health, to optimize fertility, pregnancy health, and IVF success. All from the convenience of home.`,
    freezing: 0,
    freezingCostPerSample: 0,
    frozenSampleCount: 0,
    id: 146,
    image: advancedSemenAnalysisImage,
    price: 570,
    standardSampleCount: 1,
    stiKit: false,
    title: 'Advanced Semen Analysis',
    sku: '7e39a5fe-5549-428a-ba15-b832472279d1',
    url: 'https://shop.givelegacy.com/advanced-semen-analysis/',
  },
  190: {
    advancedAnalysis: true,
    description: `"Get a deeper insight into your fertility. This testing option evaluates sperm count, motility, and morphology plus DNA fragmentation, a measure of sperm's genetic health, to optimize fertility, pregnancy health, and IVF success. All from the convenience of home.\n\nPlus a flexible, renewable sperm freezing plan to keep your family-building options open."`,
    freezing: 1,
    freezingCostPerSample: 145,
    frozenSampleCount: 1,
    id: 190,
    image: advancedSemenAnalysisImage,
    price: 715,
    standardSampleCount: 1,
    stiKit: false,
    title: 'Advanced Semen Analysis + 1 Year Storage',
    sku: '3c1c9224-6f0e-4355-b7fa-379b863cd092',
    url: 'https://shop.givelegacy.com/advanced-semen-analysis-1-year-storage/',
  },
  145: {
    advancedAnalysis: true,
    description: `Safeguard your fertility for years to come. Advanced semen analysis and STI testing give the maximum assurance that your samples are healthy, and 25 years of frozen sperm storage offers ultimate flexibility and value — all done without leaving home.`,
    freezing: 25,
    freezingCostPerSample: 36,
    frozenSampleCount: 3,
    id: 145,
    image: foreverImage,
    price: 3_995,
    standardSampleCount: 3,
    stiKit: true,
    title: 'Forever Bundle',
    sku: '2913c2bd-3b3c-4eec-a72a-719a15447b89',
    url: 'https://shop.givelegacy.com/forever',
  },
  144: {
    advancedAnalysis: false,
    description: `Protect your fertility today and give yourself more options for family-building tomorrow. This bundle includes sperm freezing, semen analysis and STI testing to ensure you're preserving a healthy sample, and 5 years of frozen sperm storage — all done without leaving home.`,
    freezing: 5,
    freezingCostPerSample: 65,
    frozenSampleCount: 2,
    id: 144,
    image: forTomorrowImage,
    price: 1_195,
    standardSampleCount: 2,
    stiKit: true,
    title: 'For Tomorrow Bundle',
    sku: '472bbfbf-037a-41a5-a05a-31bd99e5827b',
    url: 'https://shop.givelegacy.com/for-tomorrow/',
  },
  143: {
    advancedAnalysis: false,
    description: `Understanding sperm health starts here. This testing option analyzes your sperm count, motility, and morphology to give you a clear picture of your overall fertility.\n\nAll from the convenience of home.`,
    freezing: 0,
    freezingCostPerSample: 0,
    frozenSampleCount: 0,
    id: 143,
    image: standardSemenAnalysisImage,
    price: 295,
    standardSampleCount: 1,
    stiKit: false,
    title: 'Standard Semen Analysis',
    sku: '7aa649a6-8d21-4909-8073-cd5592002f54',
    url: 'https://shop.givelegacy.com/standard-semen-analysis/',
  },
  186: {
    advancedAnalysis: false,
    description: `Understanding sperm health starts here. This testing option analyzes your sperm count, motility, and morphology to give you a clear picture of your overall fertility. All from the convenience of home.\nPlus a flexible, renewable sperm freezing plan to keep your family-building options open.`,
    freezing: 1,
    freezingCostPerSample: 145,
    frozenSampleCount: 1,
    id: 186,
    image: standardSemenAnalysisFreezingImage,
    price: 440,
    standardSampleCount: 1,
    stiKit: false,
    title: 'Standard Semen Analysis + 1 Year Storage',
    sku: '16b3a3c8-b125-4a1e-8847-7dc5bc193e22',
    url: 'https://shop.givelegacy.com/standard-semen-analysis-1-year-storage/',
  },
  156: {
    advancedAnalysis: false,
    description: '',
    freezing: 5,
    freezingCostPerSample: 119,
    frozenSampleCount: 1,
    id: 156,
    image: standardSemenAnalysisFreezingImage,
    price: 890,
    standardSampleCount: 1,
    stiKit: false,
    title: 'Standard Semen Analysis + 5 Years Storage',
    sku: '33825bf9-c2cc-41b0-94f3-023cad2f2eaf',
    url: 'https://shop.givelegacy.com/standard-semen-analysis-1-year-storage/',
  },
  184: {
    advancedAnalysis: false,
    description: '',
    freezing: 10,
    freezingCostPerSample: 95,
    frozenSampleCount: 1,
    id: 184,
    image: standardSemenAnalysisFreezingImage,
    price: 1_250,
    standardSampleCount: 1,
    stiKit: false,
    title: 'Standard Semen Analysis + 10 Years Storage',
    sku: 'c3a4e69e-b0c3-4570-b085-f7aa775c2551',
    url: 'https://shop.givelegacy.com/standard-semen-analysis-1-year-storage/',
  },
} as const satisfies Record<ProductId, Product>;

function ResultStep() {
  const { setHelpModal } = useContext(layoutContext);

  const { getValues } = useFormContext<Schema>();
  const values = getValues();

  const { recommendation, recommendationFactors } = useMemo(
    () => getRecommendationFromAnswers(values),
    [values],
  );
  const product = productDict[recommendation];

  const comparisonTableProducts = useMemo(() => getComparisonTableProducts(product), [product]);

  useEffect(() => {
    analytics.track('Registration Completed', {});
    analytics.identify({
      age: values.age,
      firstName: values.name,
      email: values.email,
    });

    // TikTok Pixel Tracking
    if (typeof window.ttq === 'object' && typeof window.ttq.track === 'function') {
      window.ttq.track('CompleteRegistration', {
        value: '<0>',
        currency: '<USD>',
      });
    } else {
      window.console.log('TikTok Pixel not loaded');
    }
  }, []);

  useEffect(() => {
    if (recommendation) submitSegmentProductViewedEvent();
  }, [recommendation]);

  return (
    <Step
      nextButtonContent={HIDE_NEXT}
      secondaryAction={
        <>
          <Grid sx={{ columnGap: 4, justifyContent: 'space-between' }}>
            <Text alt sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {product.title}
            </Text>
            <Text>{formatPrice(product.price)}</Text>
          </Grid>
          <Grid sx={{ columnGap: 4, gridTemplateColumns: '1fr 1fr' }}>
            <Button fullWidth onClick={openHelp} variant="secondary">
              Contact us
            </Button>
            <Button
              fullWidth
              href={`https://shop.givelegacy.com/cart.php?action=add&product_id=${recommendation}`}
              target="_blank"
              variant="primary"
            >
              Add to cart
            </Button>
          </Grid>
        </>
      }
      title="Here's what's recommended for you."
    >
      <BundleRecommendation product={product} />
      <BundleIncludes product={product} recommendationFactors={recommendationFactors} />
      <ProductComparisonTable products={comparisonTableProducts} />
      <SiteLinks hideOtherOptions />
      <WaysToPay />
    </Step>
  );

  async function submitSegmentProductViewedEvent() {
    await analytics.track('Product Viewed', {
      product_id: `${recommendation}`,
      name: `${product.title}`,
      price: `${product.price}`,
      category: product.freezing ? 'freezing' : 'testing',
    });
  }

  function openHelp() {
    setHelpModal(true);
  }
}

function BundleRecommendation(props: { readonly product: Product }) {
  const { product } = props;
  const { description, image, price, title } = product;

  return (
    <Box sx={{ my: 8 }}>
      <Grid sx={{ alignItems: 'center', columnGap: 8, gridTemplateColumns: 'auto 1fr' }}>
        <Image alt={title} src={image} sx={{ height: 34, width: 34 }} />
        <Grid sx={{ gridAutoFlow: 'row' }}>
          <Text alt variant={['body1', 'heading4']}>
            {title}
          </Text>
          <Text variant={['body2', 'body1']}>{formatPrice(price)}</Text>
        </Grid>
      </Grid>
      <Text sx={{ color: 'evergreen600', mt: [4, 8] }} variant={['body3', 'body2']}>
        {description}
      </Text>
    </Box>
  );
}

function BundleIncludes(props: {
  readonly product: Product;
  readonly recommendationFactors: RecommendationFactors;
}) {
  const { product, recommendationFactors } = props;
  const { advancedAnalysis, freezing, standardSampleCount, stiKit, title } = product;
  const { goodLifestyle, highAge } = recommendationFactors;

  const { getValues } = useFormContext<Schema>();
  const {
    cancerTreatment,
    familySize,
    genderAffirming,
    howLongTryingForKids,
    medical,
    prevPregnancy,
    trtStarted,
    vasectomy,
    wantKids,
    whenKids,
  } = getValues();

  const Wrapper = useFC(Box, {
    sx: { borderRadius: 2, border: 1, borderColor: 'evergreen300', p: 4 },
  });
  const TopGrid = useFC(Grid, { sx: { columnGap: 4, justifyContent: 'start' } });
  const MainImage = useFC(Image, { sx: { height: 16 } });
  const SubText = useFC(Text, { sx: { color: 'evergreen600' }, variant: 'body3' });
  const ListItem = useFC(Text, { as: 'li', variant: 'body3' });

  const isAdvanceSemenAnalysis = title === 'Advanced Semen Analysis';
  const isForever = title === 'Forever';

  const isSemenSample = standardSampleCount && freezing && !isAdvanceSemenAnalysis;
  const isStandardSemenAnalysis = standardSampleCount && !freezing && !isAdvanceSemenAnalysis;
  return (
    <>
      <Text sx={{ color: 'evergreen500', pb: 3 }} variant="caption3">
        This bundle includes
      </Text>

      {/* Freezing section */}
      {freezing ? (
        <Wrapper>
          <TopGrid>
            <MainImage alt="freezing" src={freezingImage} />
            <Box>
              <Text alt>
                {freezing} year{freezing > 1 ? 's' : ''} of sperm freezing
                {freezing === 1 ? ' (renewable)' : ''}
              </Text>
              {whenKids === 'moreThan5' ? (
                <SubText>Because you want children in more than 5 years</SubText>
              ) : null}
              {whenKids === 'lessThan5' ? (
                <SubText>
                  Because you want children in the next 5 years{'\n\n'}
                  Don't worry — this plan is renewable, in case you need more time.
                </SubText>
              ) : null}
              {wantKids === 'unsure' ? (
                <SubText>
                  Because you aren't sure when you'll have children{'\n\n'}
                  Don't worry — this plan is renewable, in case you need more time.
                </SubText>
              ) : null}
            </Box>
          </TopGrid>
          <IncludesAccordion triggerText="What is sperm freezing?">
            <Text sx={{ mb: 2 }} variant="body3">
              Sperm freezing preserves sperm for use in the future. The process uses liquid nitrogen
              to bring sperm to sub-zero temperatures.
            </Text>
            <Text sx={{ mb: 2 }} variant="body3">
              Once frozen, sperm doesn't age, and stays healthy and high-quality during storage.
              Years or even decades later, the sperm can be thawed at a fertility clinic and used to
              attempt a pregnancy.
            </Text>
            <Text variant="body3">
              Where you store your sperm matters. We split all sperm samples between two
              industry-leading cryostorage facilities for maximum security.
            </Text>
          </IncludesAccordion>
        </Wrapper>
      ) : null}

      {/* Semen sample section */}
      {isSemenSample ? (
        <Wrapper>
          <TopGrid>
            <MainImage alt="sample cup" src={sampleCupImage} />
            <Box>
              <Text alt>
                {standardSampleCount} Semen sample{standardSampleCount > 1 ? 's' : ''}
              </Text>
              {familySize === '1-2' ? <SubText>Because you want 1-2 children</SubText> : null}
              {familySize === '3+' ? <SubText>Because you want 3 or more children</SubText> : null}
              {familySize === 'unsure' || wantKids === 'unsure' ? (
                <SubText>To preserve your option to have children</SubText>
              ) : null}
              {wantKids === 'no' ? (
                <SubText>To keep your family-building options open</SubText>
              ) : null}
              {wantKids === 'yes' && medical === 'none' ? (
                <SubText>Analyzed to understand your sperm health</SubText>
              ) : null}
            </Box>
          </TopGrid>
          <IncludesAccordion triggerText="What is a sample?">
            <Text variant="body3">
              A semen sample refers to the result of one ejaculation. Our kit allows you to collect
              your sample from home in a sterile cup.{'\n\n'}
              Every sperm sample frozen with Legacy is analyzed twice: once to ensure it's healthy,
              and again after it's frozen, to make sure it can be used in the future.
            </Text>
          </IncludesAccordion>
        </Wrapper>
      ) : null}

      {/* Standard semen analysis section */}
      {isStandardSemenAnalysis ? (
        <Wrapper>
          <TopGrid>
            <MainImage alt="sample cup" src={standardSemenAnalysisImage} />
            <Box>
              <Text alt>
                {standardSampleCount} Semen analys{standardSampleCount > 1 ? 'es' : 'is'}
              </Text>
              {medical === 'none' && whenKids === 'now' ? (
                <SubText>
                  To check your sperm health because you're trying to have a baby now
                </SubText>
              ) : null}
              {trtStarted === 'yes' ? (
                <SubText>To check your sperm health after starting testosterone therapy</SubText>
              ) : null}
              {genderAffirming === 'yes' ? (
                <SubText>
                  To check your fertility health after starting gender-affirming care
                </SubText>
              ) : null}
              {medical === 'spermDonorOrSurrogate' ? (
                <SubText>
                  To check sperm health before using a donor or gestational surrogate
                </SubText>
              ) : null}
              {vasectomy === 'yes' ? (
                <SubText>To check the results of your vasectomy</SubText>
              ) : null}
            </Box>
          </TopGrid>
          <IncludesAccordion triggerText="What is a semen analysis?">
            <Text variant="body3">
              A semen analysis looks at how many sperm you're making and how healthy those sperm are
              - a useful way to understand if you are making enough healthy sperm to get someone
              pregnant, also known as your fertility.{'\n\n'}
              Legacy's standard semen analysis measures: sperm count and concentration — how many
              sperm motility — how many of your sperm are swimming morphology — how many of your
              sperm are the right size and shape"""
            </Text>
          </IncludesAccordion>
        </Wrapper>
      ) : null}

      {/* Advanced analysis section */}
      {advancedAnalysis ? (
        <Wrapper>
          <TopGrid>
            <MainImage alt="advanced analysis" src={advancedSemenAnalysisImage} />
            <Box>
              <Text alt>1 Advanced semen analysis</Text>
              {medical === 'none' && highAge ? (
                <SubText>
                  Because you're over 35, and sperm genetic health declines with age
                </SubText>
              ) : null}
              {medical === 'fertilityTreatment' ? (
                <SubText>To maximize your chances of success with fertility treatment</SubText>
              ) : null}
              {medical === 'vasectomyReversal' ? (
                <SubText>Because you're planning or have had a vasectomy reversal</SubText>
              ) : null}
              {medical === 'spermDonorOrSurrogate' ? (
                <SubText>
                  To check sperm health before using a donor or gestational surrogate
                </SubText>
              ) : null}
              {!highAge && !goodLifestyle ? (
                <SubText>Because your lifestyle habits may be affecting your sperm health</SubText>
              ) : null}
              {highAge && !goodLifestyle ? (
                <SubText>
                  Because you're over 35, and your lifestyle habits may be affecting your sperm
                  health
                </SubText>
              ) : null}
              {cancerTreatment ? (
                <SubText>To check your sperm health after starting cancer treatment</SubText>
              ) : null}
              {prevPregnancy && prevPregnancy !== '0' ? (
                <SubText>Because you have a history of pregnancy loss</SubText>
              ) : null}
              {howLongTryingForKids === 'over1Year' ? (
                <SubText>To maximize your chances of getting pregnant</SubText>
              ) : null}
              {isForever && highAge && goodLifestyle ? (
                <SubText>To maximize your chances of getting pregnant</SubText>
              ) : null}
            </Box>
          </TopGrid>
          <IncludesAccordion triggerText="What is an Advanced semen analysis?">
            <Text sx={{ mb: 2 }} variant="body3">
              Sperm carries DNA that becomes half of a future baby's genetic code. Breaks in that
              DNA, known as sperm DNA fragmentation, can affect your ability to create a healthy
              pregnancy.
            </Text>
            <Text variant="body3">Legacy's advanced semen analysis measures:</Text>
            <Box as="ul">
              <ListItem>sperm count and concentration — how many sperm</ListItem>
              <ListItem>motility — how many of your sperm are swimming</ListItem>
              <ListItem>morphology — how many of your sperm are the right size and shape</ListItem>
              <ListItem>DNA fragmentation — how many of your sperm contain damaged DNA</ListItem>
            </Box>
          </IncludesAccordion>
        </Wrapper>
      ) : null}

      {/* STI kit section */}
      {stiKit ? (
        <Wrapper>
          <TopGrid>
            <MainImage alt="STI kit" src={stiKitImage} />
            <Box>
              <Text alt>STI Kit</Text>
              <SubText>
                Because STI screening now is required to use frozen sperm in the future
              </SubText>
            </Box>
          </TopGrid>
          <IncludesAccordion triggerText="What is an STI kit?">
            <Text variant="body3">Legacy's mail-in STI kit tests for:</Text>
            <Box as="ul">
              <ListItem>HIV 1/2</ListItem>
              <ListItem>Chlamydia</ListItem>
              <ListItem>Gonorrhea</ListItem>
              <ListItem>Syphilis</ListItem>
              <ListItem>Hepatitis B</ListItem>
              <ListItem>Hepatitis C</ListItem>
            </Box>
          </IncludesAccordion>
        </Wrapper>
      ) : null}
    </>
  );
}

function IncludesAccordion(props: { readonly children: ReactNode; readonly triggerText: string }) {
  const { children, triggerText } = props;

  return (
    <Accordion collapsible size="sm" sx={{ bg: 'evergreen100', borderRadius: 2, mt: 4 }}>
      <AccordionItem first sx={{ border: 0 }} value={triggerText}>
        <AccordionTrigger
          ClosedIcon={ChevronDown}
          OpenedIcon={ChevronUp}
          alt={false}
          sx={{ color: 'evergreen600', columnGap: 1, justifyContent: 'center' }}
          value={triggerText}
        >
          {triggerText}
        </AccordionTrigger>
        <AccordionContent value={triggerText}>{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

function SiteLinks(props: { readonly hideOtherOptions: Boolean }) {
  return (
    <Grid sx={{ my: 4 }} vertical>
      {!props.hideOtherOptions && (
        <Grid
          as="a"
          attrs={{ href: 'https://givelegacy.com/order', target: '_blank' }}
          sx={{
            alignItems: 'center',
            borderTop: 1,
            borderColor: 'evergreen300',
            columnGap: 4,
            gridTemplateColumns: 'auto 1fr auto',
            py: 4,
          }}
        >
          <Image alt="" src={shopAllProductsImage} sx={{ maxHeight: 16, maxWidth: 16 }} />
          <Box>
            <Text alt variant="body1">
              See other options
            </Text>
          </Box>
          <ArrowRightCircle />
        </Grid>
      )}
      <Grid
        as="a"
        attrs={{ href: 'https://givelegacy.com/how-it-works', target: '_blank' }}
        sx={{
          alignItems: 'center',
          borderBottom: 1,
          borderTop: 1,
          borderColor: 'evergreen300',
          columnGap: 4,
          gridTemplateColumns: 'auto 1fr auto',
          py: 4,
        }}
      >
        <Image alt="" src={learnHowItWorksImage} sx={{ maxHeight: 16, maxWidth: 16 }} />
        <Box>
          <Text alt variant="body1">
            Learn how it works
          </Text>
        </Box>
        <ArrowRightCircle />
      </Grid>
    </Grid>
  );
}

function WaysToPay() {
  const Masonry = useFC(Box, {
    sx: { display: 'flex', flexWrap: 'wrap', gap: 4, mb: 4, rowGap: 8 },
  });

  return (
    <>
      <Text sx={{ my: 8 }} variant="heading5">
        Ways to pay (and save)
      </Text>
      <Accordion collapsible>
        <AccordionItem first value="insurance-coverage">
          <AccordionTrigger value="insurance-coverage">Insurance coverage</AccordionTrigger>
          <AccordionContent value="insurance-coverage">
            <Text sx={{ mb: 4 }} variant="body3">
              Leading insurance plans cover a standard semen analysis and one year of frozen sperm
              storage (required).
            </Text>
            <Box sx={{ mb: 8 }}>
              <Link
                alt
                href="https://www.givelegacy.com/insurance/"
                sx={{ color: 'evergreen900' }}
                target="_blank"
                variant="body3"
              >
                Check insurance eligibility
                <ArrowRightCircle />
              </Link>
            </Box>
            <Masonry>
              <Image
                alt="United Healthcare logo"
                src={unitedHealthcareLogo}
                sx={{ maxHeight: 10 }}
              />
              <Image alt="Cigna logo" src={cignaLogo} sx={{ maxHeight: 10 }} />
              <Image alt="Emblem Health logo" src={emblemHealthLogo} sx={{ maxHeight: 10 }} />
              <Image alt="Aetna logo" src={aetnaLogo} sx={{ maxHeight: 10 }} />
              <Image alt="Tri West logo" src={triWestLogo} sx={{ maxHeight: 10 }} />
            </Masonry>
            <Box sx={{ bg: 'evergreen100', borderRadius: 3, p: 4, mb: 4 }}>
              <Text alt sx={{ mb: 4 }} variant="body3">
                <CheckCircle sx={{ mr: 4 }} />
                FSA/HSA eligible
              </Text>
              <Text variant="body3">
                After purchase, submit a copy of your receipt to your FSA to be reimbursed for this
                product.
              </Text>
            </Box>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="employer-benefits">
          <AccordionTrigger value="employer-benefits">Employer benefits</AccordionTrigger>
          <AccordionContent value="employer-benefits">
            <Text sx={{ mb: 4 }} variant="body3">
              Legacy is in-network with leading fertility benefits programs that may be offered by
              your employer. Covered clients get up to 100% off.
            </Text>
            <Box sx={{ mb: 8 }}>
              <Link
                alt
                href="https://www.givelegacy.com/employers/"
                sx={{ color: 'evergreen900' }}
                target="_blank"
                variant="body3"
              >
                Check employer eligibility
                <ArrowRightCircle />
              </Link>
            </Box>
            <Masonry>
              <Image alt="Carrot logo" src={carrotLogo} sx={{ maxHeight: 10 }} />
              <Image alt="Progyny logo" src={progynyLogo} sx={{ maxHeight: 10 }} />
              <Image alt="Maven logo" src={mavenLogo} sx={{ maxHeight: 10 }} />
              <Image alt="Stork Club logo" src={storkClubLogo} sx={{ maxHeight: 10 }} />
              <Image alt="Win Fertility logo" src={winFertilityLogo} sx={{ maxHeight: 10 }} />
            </Masonry>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="military-programs">
          <AccordionTrigger value="military-programs">Military programs</AccordionTrigger>
          <AccordionContent value="military-programs">
            <Text sx={{ mb: 4 }} variant="body3">
              We offer discounted or free access to our services for active-duty and veteran members
              of the US military.
            </Text>
            <Box sx={{ mb: 8 }}>
              <Link
                alt
                href="https://www.givelegacy.com/partners/military/"
                sx={{ color: 'evergreen900' }}
                target="_blank"
                variant="body3"
              >
                Explore military programs
                <ArrowRightCircle />
              </Link>
            </Box>
            <Masonry>
              <Image alt="TriWest logo" src={triWestLogo} sx={{ maxHeight: 10 }} />
              <Image
                alt="Military Family Building Coalition logo"
                src={militaryFamilyBuildingCoalition}
                sx={{ maxHeight: 8 }}
              />
              <Image alt="Veterans Affairs logo" src={veteransAffairsLogo} sx={{ maxHeight: 8 }} />
              <Image
                alt="Veterans Advantage logo"
                src={veteransAdvantageLogo}
                sx={{ maxHeight: 8 }}
              />
              <Image
                alt="Green Beret Foundation logo"
                src={greenBeretFoundationLogo}
                sx={{ maxHeight: 8 }}
              />
            </Masonry>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="financing-options">
          <AccordionTrigger value="financing-options">Financing options</AccordionTrigger>
          <AccordionContent value="financing-options">
            <Box>
              <Text sx={{ mb: 4 }} variant="body3">
                Break the cost of fertility care into monthly payments, interest-free, with Affirm
                financing. Your rate will depend on your credit, terms of payment, and purchase
                price. Select Affirm at checkout to explore your options. Or, try a Splitit payment
                plan.
              </Text>
              <Image alt="SplitIt logo" src={splitItLogo} sx={{ maxHeight: 10, mb: 2 }} />
              <Text sx={{ mb: 4 }} variant="body3">
                Pay in monthly 0% interest installments. No hard credit check required.*
              </Text>
              <Image alt="Affirm logo" src={affirmLogo} sx={{ maxHeight: 10, mb: 2 }} />
              <Text variant="body3">
                Buy now, pay later with Affirm 3-36 month payment plans available.
              </Text>
            </Box>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
}

function ProductComparisonTable(props: { readonly products: ComparisonTableProduct[] }) {
  const SubText = useFC(Text, { sx: { color: 'evergreen600' }, variant: 'body4' });

  const { products } = props;
  const testingOnly = products[0].freezing <= 0;
  const tableOverflow: 'scroll' | 'visible' =
    useResponsiveArray(['scroll', 'visible', 'visible']) ?? 'visible';

  return (
    <>
      <Text sx={{ mt: 8, mb: 4 }} variant="heading4">
        Compare to other options for you
      </Text>
      <Box
        sx={{
          maxWidth: 'full',
          overflow: tableOverflow,
          // @ts-expect-error
          '@media screen and (max-width: 768px)': {
            backgroundColor: '#FFF',
            maxWidth: 'none',
            overflow: tableOverflow,
            width: '100vw',
            ml: 'calc((-100vw + 100%) / 2)',
            mr: 'calc((-100vw + 100%) / 2)',
          },
        }}
      >
        <Grid vertical>
          <Grid
            sx={{ gridTemplateColumns: products.length > 2 ? '1fr 1fr 2fr' : '1fr 1fr 1fr 1fr' }}
          >
            <Box
              sx={{
                bg: 'white',
                borderBottom: 1,
                borderRight: 1,
                borderColor: 'evergreen300',
                position: 'sticky',
                left: 0,
              }}
            />
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'evergreen300',
                bg: testingOnly ? 'gold300' : 'mint',
                px: 4,
                py: 1,
              }}
            >
              <Text alt variant="body4">
                Best fit for you
              </Text>
            </Box>
            <Box
              sx={{
                borderBottom: 1,
                borderLeft: 1,
                borderColor: 'evergreen300',
                bg: 'evergreen100',
                px: 4,
                py: 1,
              }}
            >
              <Text alt variant="body4">
                {products.length > 2 ? 'Other options' : 'Other option'}
              </Text>
            </Box>
          </Grid>
          <Grid sx={{ gridTemplateColumns: '142px 142px 142px 142px' }}>
            <Grid
              sx={{
                bg: 'white',
                borderBottom: 1,
                borderRight: 1,
                borderColor: 'evergreen300',
                position: 'sticky',
                left: 0,
                py: 4,
                px: 2,
                alignItems: 'end',
              }}
            >
              {testingOnly && (
                <Text alt variant="body3">
                  Analysis measures:
                </Text>
              )}
            </Grid>
            {products.map(({ image, title, subTitle }, idx) => (
              <Box
                key={idx}
                sx={{
                  borderBottom: 1,
                  borderLeft: idx === 0 ? 0 : 1,
                  borderColor: 'evergreen300',
                  p: 4,
                  bg: idx === 0 ? (testingOnly ? 'gold100' : 'paleGreen') : undefined,
                }}
              >
                <Image alt={title} src={image} sx={{ height: 28, width: 28, pb: 2 }} />
                <Text alt sx={{ pb: 2 }} variant="body3">
                  {title}
                </Text>
                <SubText>{subTitle}</SubText>
              </Box>
            ))}
          </Grid>
          {testingOnly ? (
            <>
              <ProductComparisonTableRow
                firstColumnBackgroundColor="gold100"
                products={products}
                renderGridContents={(product: ComparisonTableProduct) => (
                  <Box>
                    <CheckCircleLarge sx={{ mr: 1, color: 'success500', display: 'inline' }} />
                    <Text sx={{ display: 'inline' }} variant="body3">
                      Included
                    </Text>
                  </Box>
                )}
                rowSubTitle="How much semen you've produced"
                rowTitle="Semen volume"
              />
              <ProductComparisonTableRow
                firstColumnBackgroundColor="gold100"
                products={products}
                renderGridContents={(product: ComparisonTableProduct) => (
                  <Box>
                    <CheckCircleLarge sx={{ mr: 1, color: 'success500', display: 'inline' }} />
                    <Text sx={{ display: 'inline' }} variant="body3">
                      Included
                    </Text>
                  </Box>
                )}
                rowSubTitle="How many sperm your semen contains"
                rowTitle="Sperm concentration"
              />
              <ProductComparisonTableRow
                firstColumnBackgroundColor="gold100"
                products={products}
                renderGridContents={(product: ComparisonTableProduct) => (
                  <Box>
                    <CheckCircleLarge sx={{ mr: 1, color: 'success500', display: 'inline' }} />
                    <Text sx={{ display: 'inline' }} variant="body3">
                      Included
                    </Text>
                  </Box>
                )}
                rowSubTitle="How many of your sperm are moving"
                rowTitle="Motility"
              />
              <ProductComparisonTableRow
                firstColumnBackgroundColor="gold100"
                products={products}
                renderGridContents={(product: ComparisonTableProduct) => (
                  <Box>
                    <CheckCircleLarge sx={{ mr: 1, color: 'success500', display: 'inline' }} />
                    <Text sx={{ display: 'inline' }} variant="body3">
                      Included
                    </Text>
                  </Box>
                )}
                rowSubTitle="How many of your sperm are the proper size and shape"
                rowTitle="Morphology"
              />
              <ProductComparisonTableRow
                firstColumnBackgroundColor="gold100"
                products={products}
                renderGridContents={(product: ComparisonTableProduct) => (
                  <>
                    {product.advancedAnalysisCount ? (
                      <Box>
                        <CheckCircleLarge sx={{ mr: 1, color: 'success500', display: 'inline' }} />
                        <Text sx={{ display: 'inline' }} variant="body3">
                          Included
                        </Text>
                      </Box>
                    ) : (
                      <Box>
                        <XCircle sx={{ mr: 1, color: 'error500', display: 'inline' }} />
                        <Text sx={{ display: 'inline' }} variant="body3">
                          Not Included
                        </Text>
                      </Box>
                    )}
                  </>
                )}
                rowSubTitle="How many of your sperm contain damaged DNA"
                rowTitle="DNA fragmentation index"
              />
            </>
          ) : (
            <>
              <ProductComparisonTableRow
                firstColumnBackgroundColor="paleGreen"
                products={products}
                renderGridContents={(product: ComparisonTableProduct) => (
                  <>
                    <Text sx={{ mb: 1 }}>
                      {product.frozenSampleCount}{' '}
                      {product.frozenSampleCount === 1 ? <>child</> : <>children</>}
                    </Text>
                    <SubText>
                      {product.frozenSampleCount}{' '}
                      {product.frozenSampleCount === 1 ? <>sample</> : <>samples</>} frozen
                    </SubText>
                  </>
                )}
                rowSubTitle="We recommend freezing one sample per child you hope to have in the future."
                rowTitle="Family size"
              />
              <ProductComparisonTableRow
                firstColumnBackgroundColor="paleGreen"
                products={products}
                renderGridContents={(product: ComparisonTableProduct) => (
                  <>
                    <Text sx={{ mb: 1 }} variant="body3">
                      {product.freezing} years included
                    </Text>
                    <SubText>Equivalent to</SubText>
                    <SubText alt>{formatPrice(product.freezingCostPerSample)}/year/sample</SubText>
                  </>
                )}
                rowSubTitle="Renew your plan or retrieve your sample at any time."
                rowTitle="Sperm storage plan"
              />
              <ProductComparisonTableRow
                firstColumnBackgroundColor="paleGreen"
                products={products}
                renderGridContents={(product: ComparisonTableProduct) => (
                  <>
                    {product.stiKit ? (
                      <Box>
                        <CheckCircleLarge sx={{ mr: 1, color: 'success500', display: 'inline' }} />
                        <Text sx={{ display: 'inline' }} variant="body3">
                          Included
                        </Text>
                      </Box>
                    ) : (
                      <Box>
                        <XCircle sx={{ mr: 1, color: 'error500', display: 'inline' }} />
                        <Text sx={{ display: 'inline' }} variant="body3">
                          Not Included
                        </Text>
                      </Box>
                    )}
                  </>
                )}
                rowSubTitle="Highly recommended as it's required in most states when using frozen sperm."
                rowTitle="STI testing"
              />
              <ProductComparisonTableRow
                firstColumnBackgroundColor="paleGreen"
                products={products}
                renderGridContents={(product: ComparisonTableProduct) => (
                  <Box>
                    <CheckCircleLarge sx={{ mr: 1, color: 'success500', display: 'inline' }} />
                    <Text sx={{ display: 'inline' }} variant="body3">
                      Included
                    </Text>
                    <Text sx={{ ml: 6, color: 'evergreen600' }} variant="body4">
                      For all samples
                    </Text>
                  </Box>
                )}
                rowSubTitle="Tests the main 5 metrics of sperm health."
                rowTitle="Standard analysis"
              />
              {products[0].advancedAnalysisCount !== undefined && (
                <ProductComparisonTableRow
                  firstColumnBackgroundColor="paleGreen"
                  products={products}
                  renderGridContents={(product: ComparisonTableProduct) => (
                    <>
                      {product.advancedAnalysisCount ? (
                        <Box>
                          <CheckCircleLarge
                            sx={{ mr: 1, color: 'success500', display: 'inline' }}
                          />
                          <Text sx={{ display: 'inline' }} variant="body3">
                            Included
                          </Text>
                          <Text sx={{ ml: 6, color: 'evergreen600' }} variant="body4">
                            For {product.advancedAnalysisCount} sample
                            {product.advancedAnalysisCount > 1 && 's'}
                          </Text>
                        </Box>
                      ) : (
                        <Box>
                          <XCircle sx={{ mr: 1, color: 'error500', display: 'inline' }} />
                          <Text sx={{ display: 'inline' }} variant="body3">
                            Not Included
                          </Text>
                        </Box>
                      )}
                    </>
                  )}
                  rowSubTitle="Includes a test of your sperm's genetic health to freeze your best sample."
                  rowTitle="Advanced analysis"
                />
              )}
            </>
          )}

          <Grid sx={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
            <Box
              sx={{
                borderRight: 1,
                borderColor: 'evergreen300',
                py: 4,
                px: 2,
                position: 'sticky',
                left: 0,
                bg: 'white',
              }}
            />
            {products.map((product: ComparisonTableProduct, idx) => (
              <Box
                key={idx}
                sx={{
                  borderLeft: idx === 0 ? 0 : 1,
                  borderColor: 'evergreen300',
                  p: 4,
                  bg: idx === 0 ? (testingOnly ? 'gold300' : 'mint') : undefined,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'full',
                  justifyContent: 'center',
                }}
              >
                <Text alt sx={{ textAlign: 'center', mb: 2 }}>
                  {formatPrice(product.price)}
                </Text>
                <Button
                  disabled={!product.addToCartUrl}
                  href={product.addToCartUrl}
                  size="sm"
                  target="_blank"
                  variant="primary"
                >
                  Add to cart
                </Button>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

function ProductComparisonTableRow({
  renderGridContents,
  firstColumnBackgroundColor,
  products,
  rowTitle,
  rowSubTitle,
}: {
  readonly firstColumnBackgroundColor: 'paleGreen' | 'mint' | 'gold100' | 'gold300';
  readonly products: ComparisonTableProduct[];
  readonly renderGridContents: Function;
  readonly rowSubTitle: string;
  readonly rowTitle: string;
}) {
  return (
    <Grid sx={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderRight: 1,
          borderColor: 'evergreen300',
          py: 4,
          px: 2,
          position: 'sticky',
          left: 0,
          bg: 'white',
        }}
      >
        <Text alt sx={{ mb: 1 }} variant="body3">
          {rowTitle}
        </Text>
        <Text sx={{ color: 'evergreen600' }} variant="body4">
          {rowSubTitle}
        </Text>
      </Box>
      {products.map((product: ComparisonTableProduct, idx) => (
        <Box
          key={idx}
          sx={{
            borderBottom: 1,
            borderLeft: idx === 0 ? 0 : 1,
            borderColor: 'evergreen300',
            py: 4,
            pl: 4,
            pr: 2,
            bg: idx === 0 ? firstColumnBackgroundColor : undefined,
            display: 'flex',
            flexDirection: 'column',
            height: 'full',
            justifyContent: 'center',
          }}
        >
          {renderGridContents(product)}
        </Box>
      ))}
    </Grid>
  );
}

function formatPrice(price: number): string {
  return '$' + price.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export { ResultStep, productDict, type Product, type ComparisonTableProduct };
