import Axios, { AxiosHeaders, type AxiosRequestConfig } from 'axios';

const { PUBLIC_LEGACY_API_HOST: baseURL } = import.meta.env || {};

const headers = new AxiosHeaders({
  'content-type': 'application/json',
});
const legacyAxios = Axios.create({ baseURL, headers });

function useCustomInstance<T>() {
  return async (config: AxiosRequestConfig) => {
    const response = await legacyAxios<T>(config);

    return response.data;
  };
}

export { legacyAxios, useCustomInstance };
