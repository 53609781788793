import { Button, Text } from '@givelegacy/react';
import { useContext } from 'react';

import { layoutContext } from '../../layout/context';
import { Step } from '../step';

function UrgentStep() {
  const { setHelpModal } = useContext(layoutContext);

  return (
    <Step
      nextButtonContent="Get started"
      secondaryAction={
        <Button onClick={openHelp} variant="secondary">
          Contact a client advisor
        </Button>
      }
      title="Before we get started."
    >
      <Text>
        The process of testing and freezing your sperm from home takes about a week. If you need
        these services more urgently, please reach out to a client advisor and we'll do our best to
        help.
      </Text>
    </Step>
  );

  function openHelp() {
    setHelpModal(true);
  }
}

export { UrgentStep };
