import { CInput, InfoOverlay, InfoOverlayContent, InfoOverlayTrigger } from '@givelegacy/react';
import { useFormContext } from 'react-hook-form';

import { Step } from '../step';
import { type Schema, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'age';

function AgeStep() {
  const { getValues } = useFormContext<Schema>();
  const value = getValues('name');

  return (
    <Step
      footerInfo={
        <InfoOverlay>
          <InfoOverlayTrigger>What's age got to do with it?</InfoOverlayTrigger>
          <InfoOverlayContent>
            You have a biological clock, too. Sperm health declines with age, so knowing how old you
            are will help us find what's right for you.
          </InfoOverlayContent>
        </InfoOverlay>
      }
      title={`Nice to meet you, ${value}! How old are you?`}
    >
      <CInput
        autoFocus
        inputProps={{ attrs: { max: '100', min: '1', step: '1' } }}
        label="Age"
        name={schemaProperty}
        required
        type="number"
      />
    </Step>
  );
}

export { AgeStep };
