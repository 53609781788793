import { type MutableRefObject, type Ref, useEffect, useRef } from 'react';

function useMutableRef<T>(ref?: Ref<T>): MutableRefObject<T | null> {
  const newRef = useRef<T | null>(null);

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === 'function') ref(newRef.current);
    else newRef.current = ref.current;
  }, [ref]);

  return newRef;
}

export { useMutableRef };
