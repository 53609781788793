import { getAnonymousId } from '../instance';
import { useState, useEffect } from 'react';
import { ID } from '@segment/analytics-next';
import { nanoid } from 'nanoid';

function useAnonymousId() {
  const [anonymousId, setAnonymousId] = useState<ID>();
  const [isLoading, setIsLoading] = useState(true);
  async function fetchAnonymousId() {
    try { 
      const userId = await getAnonymousId();
      setAnonymousId(userId);
    }
    catch {
      setAnonymousId(nanoid());
    }
    finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchAnonymousId();
  }, [anonymousId]);

  return { anonymousId, fetchAnonymousId, isLoading };
}
export { useAnonymousId }