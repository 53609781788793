import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgHelpCircle(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M4.5 12a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0ZM12 3.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Zm-.434 9.452c-.166.327-.249.743-.249 1.247v.196h1.131c0-.476.06-.84.18-1.092.128-.262.344-.523.648-.785.662-.579 1.113-1.074 1.352-1.484.248-.42.372-.92.372-1.499 0-.486-.12-.92-.359-1.303-.239-.382-.588-.681-1.048-.896-.45-.224-.988-.336-1.614-.336-.597 0-1.121.13-1.572.392-.45.262-.8.602-1.048 1.023A2.65 2.65 0 0 0 9 9.745c0 .355.06.654.18.897l1.02-.21a1.483 1.483 0 0 1-.11-.575c0-.588.184-1.046.551-1.372.368-.327.819-.49 1.352-.49.561 0 1.016.149 1.366.448.358.298.538.695.538 1.19 0 .43-.102.803-.304 1.12-.202.309-.565.682-1.09 1.12-.46.393-.772.753-.938 1.08Zm1.006 2.62h-1.351V17h1.351v-1.429Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgHelpCircle;
