import { Box, Grid, Text } from '@givelegacy/react';
import { analytics } from '@givelegacy/segment';
import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { noop } from 'remeda';
import { match } from 'ts-pattern';

import { wizardContext } from '../context';
import { Step } from '../step';
import { type Schema, type SchemaProperty } from '../types';
import { familySizeData } from './family_size';
import { lifestyleData } from './lifestyle';
import { whenKidsData } from './when_kids';

function SummaryStep() {
  const { onReset } = useContext(wizardContext);

  const { getValues } = useFormContext<Schema>();
  const {
    age,
    cancerTreatment,
    familySize,
    genderAffirming,
    medical,
    name,
    trtStarted,
    vasectomy,
    whenKids,
    prevPregnancy,
    lifestyle,
    howLongTryingForKids,
    email,
  } = getValues();

  useEffect(() => {
    analytics.track('Email Entered', {});
    analytics.identify({
      age,
      firstName: name,
      email,
    });
  }, []);

  return (
    <Step nextButtonContent="See my recommendation" title="Okay, here's what you've shared.">
      <Box sx={{ border: 1, borderColor: 'evergreen400', borderRadius: 3 }}>
        <Text
          alt
          sx={{
            bg: 'evergreen200',
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            p: 3,
          }}
        >
          {name}
        </Text>

        <Grid sx={{ px: 3, py: 4, rowGap: 4 }} vertical>
          <Box>
            <Text variant="body4">Age</Text>
            <Text variant="body2">{age}</Text>
          </Box>
          {whenKids ? (
            <Box>
              <Text variant="body4">Timeline</Text>
              <Text variant="body2">{getLabelFromValue(whenKidsData, whenKids)}</Text>
            </Box>
          ) : null}
          {familySize ? (
            <Box>
              <Text variant="body4">Ideal family size</Text>
              <Text variant="body2">{getLabelFromValue(familySizeData, familySize)}</Text>
            </Box>
          ) : null}
          {medical !== 'none' ? (
            <Box>
              <Text variant="body4">Medical treatment</Text>
              <Text variant="body2">{getMedicalText()}</Text>
            </Box>
          ) : null}
        </Grid>
      </Box>
      <Text sx={{ color: 'evergreen500', p: 5 }} variant="body3">
        Doesn't look right?
        <Text as="span" attrs={{ onClick: onReset }} sx={{ cursor: 'pointer', ml: 2 }} underline>
          Start over
        </Text>
      </Text>
    </Step>
  );

  function getMedicalText() {
    const prefix = (started?: string) => (started === 'yes' ? 'Undergoing' : 'Considering');

    return match(medical)
      .with('cancerTreatment', () => `${prefix(cancerTreatment)} cancer treatment`)
      .with(
        'fertilityTreatment',
        () => 'Considering or undergoing fertility treatment, such as IUI or IVF',
      )
      .with('genderAffirming', () => `${prefix(genderAffirming)} gender-affirming treatment`)
      .with(
        'spermDonorOrSurrogate',
        () => 'Considering using a sperm donor or gestational surrogate',
      )
      .with('trt', () => `${prefix(trtStarted)} testosterone replacement therapy`)
      .with('vasectomy', () =>
        vasectomy === 'yes' ? 'Have had a vasectomy' : 'Considering vasectomy',
      )
      .with('vasectomyReversal', () => 'Considering or have had a vasectomy reversal')
      .with('none', noop)
      .exhaustive();
  }

  function getLabelFromValue(
    data: Array<{ label: string; value: Schema[SchemaProperty] }>,
    value: Schema[SchemaProperty],
  ) {
    return data.find((d) => d.value === value)?.label || value;
  }

  function getLifeStyleText() {
    return lifestyle.length > 0
      ? lifestyle.map((x) => getLabelFromValue(lifestyleData, x)).join(', ')
      : 'None selected';
  }

  function getFertilityJourneyText() {
    return (
      match(howLongTryingForKids)
        .with('unstarted', () => `You haven't started trying to conceive yet`)
        .with('under6Months', () => `You've been trying to conceive for less than 6 months`)
        .with('6-12Months', () => `You've been trying to conceive for 6–12 months`)
        .with('over1Year', () => `You've been trying to conceive for 1 year or more`)
        .with(undefined, noop)
        .exhaustive() +
      (prevPregnancy === '0' || undefined
        ? `\nNo history of pregnancy loss`
        : `\nHistory of pregnancy loss`)
    );
  }
}

export { SummaryStep };
