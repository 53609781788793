import { CRadioGroup, Text } from '@givelegacy/react';
import { useFormContext } from 'react-hook-form';

import { Step } from '../step';
import { type Schema, type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'trtStarted';
const trtStartedData: SchemaOptions<typeof schemaProperty> = [
  { label: 'Yes', value: 'yes' },
  { label: 'Not yet', value: 'no' },
];

function TrtStartedStep() {
  return (
    <Step title="Testosterone therapy, got it.">
      <Text>
        Taking testosterone may harm your fertility. This might be a surprise to you, or even seem
        counterintuitive — we get it. Have you already started treatment?
      </Text>
      <CRadioGroup data={trtStartedData} name={schemaProperty} required />
    </Step>
  );
}

function TrtStartedFollowUpStep() {
  return (
    <Step title="We hope you're feeling great.">
      <Text>
        Because you've been on testosterone therapy, we recommend you start with a semen analysis.
        This will help you understand whether your sperm health has been affected, and if freezing
        for the future makes sense for you. If it does, we can freeze your sample immediately. (You
        can choose a storage plan later.)
      </Text>
    </Step>
  );
}

function TrtNotStartedFollowUpStep() {
  const { getValues } = useFormContext<Schema>();
  const name = getValues('name');

  return (
    <Step title={`You're here at just the right time, ${name}.`}>
      <Text>
        Approximately 3 in 4 men taking testosterone therapy will have zero sperm count within 6
        months. Research shows that fertility recovery after testosterone therapy is variable. That
        means we really don't know when, or if, your sperm will return if you stop taking T.
        Freezing your sperm before starting testosterone therapy means you're preserving the option
        to have children in the future.
      </Text>
    </Step>
  );
}

export { TrtStartedStep, TrtStartedFollowUpStep, TrtNotStartedFollowUpStep };
