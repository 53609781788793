const space = {
  0: '0rem', // 0px
  0.5: '0.125rem', // 2px
  1: '0.25rem', // 4px
  1.25: '0.3125rem', // 5px
  1.5: '0.375rem', // 6px
  2: '0.5rem', // 8px
  2.5: '0.625rem', // 10px
  3: '0.75rem', // 12px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '1.75rem', // 28px
  8: '2rem', // 32px
  10: '2.5rem', // 40px
  12: '3rem', // 48px
  14: '3.25rem', // 52px
  15: '3.5rem', // 56px
  16: '4rem', // 64px
  20: '5rem', // 80px
  24: '6rem', // 96px
  28: '7rem', // 112px
  32: '8rem', // 128px
  34: '8.5rem', // 136px
  36: '9rem', // 144px
  48: '12rem', // 192px
  64: '16rem', // 256px
  100: '25rem', // 400px
  150: '37.5rem', // 600px
  160: '40rem', // 640px
  full: '100%',
  half: '50%',
  auto: 'auto',
} as const;

type Space = keyof typeof space;

export { space };
export type { Space };
