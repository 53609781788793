import { connect, machine } from '@zag-js/dialog';
import { normalizeProps, useMachine } from '@zag-js/react';
import { type ReactNode, useMemo } from 'react';
import { type Exact } from 'type-fest';

import { useControllableId } from '../../hooks';
import { type ModalContext, modalContext, type ZagModalContext } from './modal_context';

interface ModalProps extends ZagModalContext {
  readonly children: ReactNode;
  readonly size?: ModalContext['size'];
  readonly theme?: ModalContext['theme'];
}

function Modal(props: ModalProps) {
  const { children, id, size = 'md', theme = 'light', ...rest } = props;
  rest satisfies Exact<ZagModalContext, typeof rest>;

  const cId = useControllableId(id);

  const config: ZagModalContext = useMemo(() => rest, [rest]);
  const [state, send] = useMachine(machine({ id: cId }), { context: config });
  const api = connect(state, send, normalizeProps);

  const context = useMemo(() => ({ ...api, config, size, theme }), [api, config, size, theme]);

  return <modalContext.Provider value={context}>{children}</modalContext.Provider>;
}

export { Modal };

export type { ModalProps };
