import { connect, type Context, machine } from '@zag-js/accordion';
import { normalizeProps, useMachine } from '@zag-js/react';
import { type ReactNode, useMemo } from 'react';
import { type Exact, type SetOptional } from 'type-fest';

import { useControllableId, usePropsMerge } from '../../hooks';
import { type BoxProps, Grid } from '../box';
import { infoOverlayContext } from './info_overlay_context';

type Config = SetOptional<Context, 'id'>;
interface InfoOverlayProps extends BoxProps, Config {
  readonly children?: ReactNode;
}

function InfoOverlay(props: InfoOverlayProps) {
  const { as, attrs, children, className, rootRef, id, sx, ...rest } = props;
  rest satisfies Exact<Config, typeof rest>;

  const cId = useControllableId(id);

  const config: Context = useMemo(() => ({ collapsible: true, id: cId, ...rest }), [cId, rest]);
  const [state, send] = useMachine(machine(config), { context: config });
  const api = connect(state, send, normalizeProps);

  const context = useMemo(() => ({ ...api, config }), [api, config]);

  const mergedProps = usePropsMerge<BoxProps>(
    {
      sx: {
        boxShadow: 2,
        gridAutoFlow: 'row',
        gridTemplateAreas: '"content" "trigger"',
        position: 'relative',
      },
    },
    { attrs, className, rootRef, sx },
  );

  return (
    <infoOverlayContext.Provider value={context}>
      <Grid as={as} {...mergedProps}>
        {children}
      </Grid>
    </infoOverlayContext.Provider>
  );
}

export { InfoOverlay };
export type { InfoOverlayProps };
