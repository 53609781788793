import { type ReactNode, useContext } from 'react';
import { type EmptyObject, type Exact, type Except } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { Box } from '../box';
import { ButtonBase, type ButtonBaseProps } from '../button';
import { radioGroupContext } from './radio_group_context';

interface RadioButtonProps extends Except<ButtonBaseProps<'label'>, 'as'> {
  readonly children?: ReactNode;
  // readonly disabled?: boolean;
  readonly required?: boolean;
  readonly value: string;
}

function RadioButton(props: RadioButtonProps) {
  const ctx = useContext(radioGroupContext);
  const {
    attrs,
    autoFocus,
    borderColor,
    children,
    className,
    disabled: _disabled,
    fullWidth,
    icon: _icon,
    onClick,
    required = ctx.required,
    rootRef,
    size,
    sx,
    theme,
    value,
    variant = 'secondary',
    ...rest
  } = props;
  rest satisfies Exact<EmptyObject, typeof rest>;

  const { isChecked } = ctx.getItemState({ value });

  const mergedProps = usePropsMerge<ButtonBaseProps<'label'>>(
    {
      attrs: ctx.getItemProps({ value }),
      borderColor: isChecked ? 'evergreen800' : 'evergreen300',
    },
    { attrs, borderColor, className, fullWidth, onClick, rootRef, size, sx, theme, variant },
  );

  return (
    <ButtonBase as="label" {...mergedProps}>
      <Box attrs={ctx.getItemTextProps({ value })}>{children}</Box>
      <input
        autoFocus={autoFocus}
        required={required}
        {...ctx.getItemHiddenInputProps({ value })}
      />
    </ButtonBase>
  );
}

export { RadioButton };
export type { RadioButtonProps };
