import { deepmergeCustom } from 'deepmerge-ts';
import { flatten, isDefined, isFunction, isString } from 'remeda';

/**
 * @summary Deep merge function with custom handlers for certain props.
 * @param {Record} arg.className - append entries to the space-delimited string
 * @param {Record} arg.onSomeEvent - wrap in a function that calls each handler synchronously
 * @param {Record} arg.sx - merge into an array and let @emotion/css handle composition
 */
const merge = deepmergeCustom({
  mergeOthers: (values, utils, meta) => {
    const { key } = meta || {};
    const defined = values.filter(isDefined);

    if (key === 'className') return values.join(' ');

    if (key === 'sx') return flatten(defined);

    const isEventHandler = isString(key) && key.startsWith('on') && defined.every(isFunction);
    if (isEventHandler) return mergeEventHandlers(defined);

    return utils.defaultMergeFunctions.mergeOthers(defined);
  },
  mergeRecords(values, utils, meta) {
    const { key } = meta || {};

    if (key === 'sx') return values;

    return utils.actions.defaultMerge;
  },
});

function mergeEventHandlers(eventHandlers: readonly Function[]) {
  return (...args: unknown[]) => {
    for (const handler of eventHandlers) handler(...args);
  };
}

export { merge };
