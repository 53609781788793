import { type Api, type Context } from '@zag-js/radio-group';
import { createContext } from 'react';
import { type SetOptional } from 'type-fest';

type ZagRadioGroupContext = SetOptional<Context, 'id'>;

interface RadioGroupContext extends Api {
  config: ZagRadioGroupContext;
  disabled?: boolean;
  error?: string;
  required?: boolean;
  theme?: 'dark' | 'light';
}

// @ts-expect-error: don't need a default state
const radioGroupContext = createContext<RadioGroupContext>();

export { radioGroupContext };
export type { RadioGroupContext, ZagRadioGroupContext };
