import { breakpoints } from '@givelegacy/style';
import { useMemo } from 'react';
import { useMediaQuery } from 'usehooks-ts';

const { desktop, tablet } = breakpoints;

function useViewport() {
  const isMobile = useMediaQuery(`(max-width: ${tablet - 1}px)`);
  const isTablet = useMediaQuery(`(min-width: ${tablet}px) and (max-width: ${desktop - 1}px)`);
  const isDesktop = useMediaQuery(`(min-width: ${desktop}px)`);

  return useMemo(() => ({ isMobile, isTablet, isDesktop }), [isMobile, isTablet, isDesktop]);
}

export { useViewport };
