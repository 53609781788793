import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgX(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M17.354 7.354 17.707 7 17 6.293l-.354.353L12 11.293 7.354 6.646 7 6.293 6.293 7l.353.354L11.293 12l-4.647 4.646-.353.354.707.707.354-.353L12 12.707l4.646 4.647.354.353.707-.707-.354-.354L12.707 12l4.646-4.646Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgX;
