import { Check, Minus } from '@givelegacy/icons';
import { type Sx } from '@givelegacy/style';
import { type Api } from '@zag-js/checkbox';
import { match } from 'ts-pattern';
import { type EmptyObject, type Exact, type Except } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { type BoxProps, Grid } from '../box';

interface CheckboxTickProps extends Except<BoxProps, 'children'> {
  readonly api: Api;
  readonly error?: boolean;
  readonly theme?: 'dark' | 'light';
}

function CheckboxTick(props: CheckboxTickProps) {
  const { api, as, attrs, className, error, rootRef, sx, theme = 'light', ...rest } = props;
  rest satisfies Exact<EmptyObject, typeof rest>;

  const variantProps = getVariantProps();

  const mergedProps = usePropsMerge<BoxProps>(
    {
      attrs: api.controlProps,
      sx: {
        raw: { height: '1em', width: '1em' },
        alignContent: 'center',
        bg: variantProps.bg,
        border: 1,
        borderColor: variantProps.borderColor,
        color: variantProps.color,
        gridAutoFlow: 'row',
        justifyContent: 'center',
      },
    },
    { attrs, className, rootRef, sx },
  );

  return (
    <Grid as={as} {...mergedProps}>
      {api.isChecked ? <Check size="inherit" /> : null}
      {api.isIndeterminate ? <Minus size="inherit" /> : null}
    </Grid>
  );

  function getVariantProps() {
    const matchKey = {
      disabled: api.isDisabled,
      error: !!error,
      filled: api.isChecked || api.isIndeterminate,
    } as const;

    if (theme === 'dark') {
      return match(matchKey)
        .returnType<Sx>()
        .with({ disabled: true, error: false }, () => ({
          borderColor: 'white',
          bg: 'evergreen700',
          color: 'white',
          '&:hover': { bg: 'white' },
        }))
        .with({ error: false, filled: true }, () => ({
          borderColor: 'evergreen800',
          bg: 'white',
          color: 'evergreen800',
        }))
        .with({ error: false }, () => ({
          borderColor: 'white',
          bg: 'evergreen800',
          color: 'white',
        }))
        .with({ error: true, disabled: true }, () => ({
          borderColor: 'white',
          bg: 'evergreen700',
          color: 'evergreen700',
          '&:hover': { bg: 'white' },
        }))
        .with({ error: true }, () => ({
          borderColor: 'error500',
          bg: 'evergreen800',
          color: 'error500',
          '&:hover': { bg: 'white' },
        }))
        .exhaustive();
    }

    return match(matchKey)
      .returnType<Sx>()
      .with({ disabled: true, error: false }, () => ({
        borderColor: 'evergreen400',
        bg: 'white',
        color: 'evergreen400',
      }))
      .with({ error: false, filled: true }, () => ({
        borderColor: 'evergreen800',
        bg: 'evergreen800',
        color: 'white',
      }))
      .with({ error: false }, () => ({
        borderColor: 'evergreen400',
        bg: 'white',
        color: 'evergreen800',
      }))
      .with({ disabled: true, error: true }, () => ({
        borderColor: 'evergreen300',
        bg: 'white',
        color: 'error500',
      }))
      .with({ error: true }, () => ({
        borderColor: 'error500',
        bg: 'white',
        color: 'error500',
      }))
      .exhaustive();
  }
}

export { CheckboxTick };
export type { CheckboxTickProps };
