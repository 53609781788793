import {
  CRadioGroup,
  InfoOverlay,
  InfoOverlayContent,
  InfoOverlayTrigger,
  Text,
} from '@givelegacy/react';

import { Step } from '../step';
import { type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'whenKids';
const whenKidsData: SchemaOptions<typeof schemaProperty> = [
  { label: 'Now', value: 'now' },
  { label: 'In the next 5 years', value: 'lessThan5' },
  { label: 'In more than 5 years', value: 'moreThan5' },
  { label: "I'm not sure", value: 'unsure' },
];

function WhenKidsStep() {
  return (
    <Step
      footerInfo={
        <InfoOverlay>
          <InfoOverlayTrigger>Why do we ask?</InfoOverlayTrigger>
          <InfoOverlayContent>
            If getting pregnant has been taking longer than you'd hoped, you might benefit from more
            in-depth testing to help optimize your chances.
          </InfoOverlayContent>
        </InfoOverlay>
      }
      title="When do you want children?"
    >
      <Text>Don't worry — we won't hold you to it.</Text>
      <CRadioGroup data={whenKidsData} name={schemaProperty} required />
    </Step>
  );
}

export { WhenKidsStep, whenKidsData };
