import { Text } from '@givelegacy/react';

import { Step } from '../step';

function IntroStep() {
  return (
    <Step nextButtonContent="Let's do this" title="Hi!">
      <Text>
        We're going to ask you a couple of questions about your health and family plans that will
        help us find what's right for you.
      </Text>
      <Text>
        This will take about 2 minutes, and all your answers are confidential. Sound good?
      </Text>
    </Step>
  );
}

export { IntroStep };
