import { type Sx } from '@givelegacy/style';
import { type ReactNode, useContext } from 'react';
import { match } from 'ts-pattern';
import { type Exact, type Except } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { Box, Grid } from '../box';
import { Text, type TextProps } from '../text';
import { radioGroupContext } from './radio_group_context';

interface RadioProps extends Except<TextProps<'label'>, 'as'> {
  readonly autoFocus?: boolean;
  readonly children?: ReactNode;
  readonly disabled?: boolean;
  readonly error?: string;
  readonly required?: boolean;
  readonly value: string;
}

function Radio(props: RadioProps) {
  const ctx = useContext(radioGroupContext);
  const { autoFocus, children, disabled, error, required, value, ...rest } = props;
  rest satisfies Exact<TextProps<'label'>, typeof rest>;

  const { isChecked, isDisabled } = ctx.getItemState({ value });
  const variantProps = getVariantProps();

  const mergedProps = usePropsMerge<TextProps<'label'>>(
    {
      attrs: ctx.getItemProps({ value }),
      sx: {
        alignItems: 'center',
        color: variantProps.color,
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'start',
      },
    },
    rest,
  );

  return (
    <Text as="label" {...mergedProps}>
      <Grid
        attrs={ctx.getItemControlProps({ value })}
        sx={{
          alignItems: 'center',
          bg: variantProps.bg,
          border: 1,
          borderColor: variantProps.borderColor,
          borderRadius: 'full',
          height: 5,
          justifyContent: 'center',
          mr: 1,
          width: 5,
        }}
      >
        {isChecked ? <Box sx={{ bg: variantProps.borderColor, height: 1.5, width: 1.5 }} /> : null}
      </Grid>
      <Box attrs={ctx.getItemTextProps({ value })}>{children}</Box>
      <input
        autoFocus={autoFocus}
        required={required}
        {...ctx.getItemHiddenInputProps({ value })}
      />
    </Text>
  );

  function getVariantProps() {
    const variantKey = `${ctx.theme || 'light'}-${error ? 'error' : 'primary'}${
      isDisabled || disabled ? '-disabled' : ''
    }` as const;

    return match(variantKey)
      .returnType<Sx>()
      .with('light-primary', () => ({
        borderColor: 'evergreen800',
        bg: 'white',
        color: 'evergreen800',
      }))
      .with('light-primary-disabled', () => ({
        borderColor: 'evergreen300',
        bg: 'white',
        color: 'evergreen400',
      }))
      .with('light-error', () => ({
        borderColor: 'error500',
        bg: 'white',
        color: 'error500',
      }))
      .with('light-error-disabled', () => ({
        borderColor: 'evergreen300',
        bg: 'white',
        color: 'error500',
      }))
      .with('dark-primary', () => ({
        borderColor: 'white',
        bg: 'evergreen800',
        color: 'evergreen800',
      }))
      .with('dark-primary-disabled', () => ({
        borderColor: 'white',
        bg: 'evergreen700',
        color: 'evergreen800',
      }))
      .with('dark-error', () => ({
        borderColor: 'error300',
        bg: 'evergreen800',
        color: 'error500',
      }))
      .with('dark-error-disabled', () => ({
        borderColor: 'white',
        bg: 'evergreen700',
        color: 'evergreen700',
      }))
      .exhaustive();
  }
}

export { Radio };
export type { RadioProps };
