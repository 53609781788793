import { type ReactNode, useContext } from 'react';
import AnimateHeight from 'react-animate-height';
import { type Exact } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { type BoxProps } from '../box';
import { Text, type TextProps } from '../text';
import { type AccordionContext, accordionContext } from './accordion_context';

interface AccordionContentProps extends BoxProps {
  readonly children: ReactNode;
  readonly size?: AccordionContext['size'];
  readonly theme?: AccordionContext['theme'];
  readonly value: string;
}

function AccordionContent(props: AccordionContentProps) {
  const ctx = useContext(accordionContext);
  const { as, children, size = ctx.size, theme = ctx.theme, value, ...rest } = props;
  rest satisfies Exact<BoxProps, typeof rest>;

  const { hidden, ...apiProps } = ctx.getItemContentProps({ value });

  const padding = (size === 'lg' && 8) || (size === 'md' && 4) || 2;

  const mergedProps = usePropsMerge<TextProps>(
    {
      attrs: apiProps,
      sx: {
        color: theme === 'light' ? 'evergreen600' : 'evergreen200',
        mt: size === 'md' ? undefined : 2,
        pb: padding,
        px: padding,
      },
      variant: size === 'md' ? 'body3' : 'body2',
    },
    rest,
  );

  return (
    <AnimateHeight duration={500} height={hidden ? 0 : 'auto'}>
      <Text as={as} {...mergedProps}>
        {children}
      </Text>
    </AnimateHeight>
  );
}

export { AccordionContent };
export type { AccordionContentProps };
