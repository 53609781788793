/* eslint-disable import/no-extraneous-dependencies */
import { type RequestHandler, setupWorker } from 'msw';
import { type ReactNode } from 'react';

const {
  PUBLIC_AUTH0_QUIZ_HOST: auth0Url,
  PUBLIC_BC_GQL_URL: bcUrl,
  PUBLIC_LEGACY_API_HOST: legacyUrl,
} = import.meta.env;

// import.meta.env.PROD used directly to prevent dynamic imports being bundled for PROD
if (!import.meta.env.PROD) {
  const promises = [];
  const mockHandlers: RequestHandler[] = [];

  const addHandlers = ({ handlers }: { handlers: RequestHandler[] }) =>
    mockHandlers.push(...handlers);

  if (auth0Url === 'mock') promises.push(import('@givelegacy/auth0/mock').then(addHandlers));
  if (bcUrl === 'mock') promises.push(import('@givelegacy/bigcommerce/mock').then(addHandlers));
  if (legacyUrl === 'mock') promises.push(import('@givelegacy/legacy-api/mock').then(addHandlers));

  if (promises.length > 0) {
    await Promise.all(promises);

    const mockWorker = setupWorker(...mockHandlers);
    await mockWorker.start();
  }
}

function MockProvider(props: { children: ReactNode }) {
  const { children } = props;

  return children;
}

export { MockProvider };
