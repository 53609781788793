import { space } from '@givelegacy/style';
import { type ReactNode, useContext } from 'react';
import AnimateHeight from 'react-animate-height';
import { type Exact } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { Box } from '../box';
import { Text, type TextProps } from '../text';
import { infoOverlayContext, ITEM_VALUE } from './info_overlay_context';

interface InfoOverlayContentProps extends TextProps {
  readonly children: ReactNode;
}

function InfoOverlayContent(props: InfoOverlayContentProps) {
  const ctx = useContext(infoOverlayContext);
  const { as, children, ...rest } = props;
  rest satisfies Exact<TextProps, typeof rest>;

  const { hidden, ...attrs } = ctx.getItemContentProps({ value: ITEM_VALUE });

  const mergedProps = usePropsMerge<TextProps>(
    {
      attrs,
      sx: {
        raw: { marginBottom: `-${space[1]}`, zIndex: -1 },
        bg: 'subzeroGreen',
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        gridArea: 'content',
        pb: 1,
      },
    },
    rest,
  );

  return (
    <Text as={as} {...mergedProps}>
      <AnimateHeight duration={500} height={hidden ? 0 : 'auto'}>
        <Box sx={{ p: 4 }}>{children}</Box>
      </AnimateHeight>
    </Text>
  );
}

export { InfoOverlayContent };
export type { InfoOverlayContentProps };
