import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgArrowLeft(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M12.354 5.354 12.707 5 12 4.293l-.354.353-7 7-.353.354.353.354 7 7 .354.353.707-.707-.353-.354L6.207 12.5H19.5v-1H6.207l6.147-6.146Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgArrowLeft;
