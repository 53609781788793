import { useController, type UseControllerProps } from 'react-hook-form';
import { type Exact, type Except } from 'type-fest';

import { Select, type SelectProps } from '../components';
import { usePropsMerge } from '../hooks';

interface CSelectProps
  extends Except<SelectProps, 'name'>,
    Readonly<Except<UseControllerProps, 'control'>> {}

function CSelect(props: CSelectProps) {
  const { children, defaultValue = '', name, required, rules, shouldUnregister, ...rest } = props;
  rest satisfies Exact<SelectProps, typeof rest>;

  const { field } = useController({
    defaultValue,
    name,
    rules: { ...rules, required },
    shouldUnregister,
  });
  const { onChange, value: _, ...attrs } = field;

  const mergedProps = usePropsMerge<SelectProps>({ inputProps: { attrs } }, rest);

  return (
    <Select {...mergedProps} onValueChange={handleChange}>
      {children}
    </Select>
  );

  function handleChange(details: Parameters<NonNullable<SelectProps['onValueChange']>>[0]) {
    const nextValue = rest.multiple ? details.value : details.value[0];
    onChange(nextValue);
  }
}

export { CSelect };
export type { CSelectProps };
