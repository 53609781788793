import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgCheck(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="m19.707 7-.354.354-10 10-.353.353-.354-.353-4-4L4.293 13 5 12.293l.354.354L9 16.293l9.646-9.647.354-.353.707.707Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgCheck;
