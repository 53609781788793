import { type IconInstanceProps } from '@givelegacy/icons';
import { type FC, type SyntheticEvent, useState } from 'react';
import { type Exact } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { Box, type BoxProps } from '../box';

interface ImageProps extends BoxProps<'img'> {
  readonly SkeletonIcon?: FC<IconInstanceProps>;
  readonly alt: string;
  readonly fallbackSrc?: string;
  readonly loading?: boolean;
  readonly skeletonSrc?: string;
  readonly src: string;
}

function Image(props: ImageProps) {
  const {
    alt,
    as: _,
    fallbackSrc,
    loading,
    SkeletonIcon,
    skeletonSrc,
    src: baseSrc = '',
    ...rest
  } = props;
  rest satisfies Exact<BoxProps<'img'>, typeof rest>;

  const [error, setError] = useState(false);

  const showFallback = fallbackSrc && error;
  const showSkeleton = !showFallback && (error || loading);
  const showSkeletonIcon = showSkeleton && SkeletonIcon;
  const showSkeletonSrc = Boolean(!showSkeletonIcon && showSkeleton && skeletonSrc);
  const src = (showSkeletonSrc && skeletonSrc) || (showFallback && fallbackSrc) || baseSrc;

  const mergedProps = usePropsMerge<BoxProps>(
    {
      attrs: { alt, onError: handleError, src },
      sx: { display: 'block', maxHeight: 'full', maxWidth: 'full' },
    },
    rest,
  );

  return (
    <Box as={showSkeleton ? 'div' : 'img'} {...mergedProps}>
      {showSkeleton && SkeletonIcon ? (
        <SkeletonIcon size="expand" sx={{ opacity: '0.25' }} />
      ) : null}
    </Box>
  );

  function handleError({ currentTarget }: SyntheticEvent<HTMLImageElement>) {
    currentTarget.onerror = null; // eslint-disable-line no-param-reassign
    setError(true);
  }
}

export { Image };
export type { ImageProps };
