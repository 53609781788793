import { useController, type UseControllerProps } from 'react-hook-form';
import { type Exact } from 'type-fest';

import { CheckboxGroup, type CheckboxGroupProps } from '../components';
import { usePropsMerge } from '../hooks';

interface CCheckboxGroupProps extends CheckboxGroupProps {
  readonly name: string;
  readonly rules?: UseControllerProps['rules'];
}

function CCheckboxGroup(props: CCheckboxGroupProps) {
  const { defaultValue, name, rules, ...rest } = props;
  rest satisfies Exact<CheckboxGroupProps, typeof rest>;

  const { field } = useController({ defaultValue, name, rules });
  const { onChange, value, ...attrs } = field;

  const mergedProps = usePropsMerge<CheckboxGroupProps>(
    { attrs, defaultValue: value, onChange: handleChange },
    rest,
  );

  return <CheckboxGroup {...mergedProps} />;

  function handleChange(nextValue?: string[]) {
    onChange(nextValue || []);
  }
}

export { CCheckboxGroup };
export type { CCheckboxGroupProps };
