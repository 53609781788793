import { Grid, Modal, ModalContent } from '@givelegacy/react';
import { SentryProvider } from '@givelegacy/sentry';
import { useMemo, useRef, useState } from 'react';

import { Wizard } from '../wizard';
import { layoutContext } from './context';
import { Header } from './header';
import { Help } from './help';

function App() {
  const headerRef = useRef(null);
  const [helpModal, setHelpModal] = useState(false);

  const exitUrl = useMemo(() => {
    const { referrer } = window.document;
    return /givelegacy\.com/iu.test(referrer) ? referrer : 'https://givelegacy.com';
  }, []);

  const context = useMemo(
    () => ({ exitUrl, headerRef, helpModal, setHelpModal }),
    [exitUrl, headerRef, helpModal, setHelpModal],
  );

  return (
    <layoutContext.Provider value={context}>
      <Grid
        sx={{
          alignContent: 'start',
          gridAutoFlow: 'row',
          gridTemplateRows: 'auto 1fr',
          height: '100dvh',
        }}
      >
        <Header rootRef={headerRef} />
        <SentryProvider>
          <Wizard />
        </SentryProvider>
      </Grid>
      <Modal
        closeOnEscapeKeyDown={false}
        closeOnInteractOutside={false}
        onOpenChange={onHelpModalCHange}
        open={helpModal}
      >
        <ModalContent>
          <Help />
        </ModalContent>
      </Modal>
    </layoutContext.Provider>
  );

  function onHelpModalCHange({ open }: { open: boolean }) {
    setHelpModal(open);
  }
}

export { App };
