import { type CSSObject } from '@emotion/react';
import { type Pseudos, type StandardProperties } from 'csstype';
import facepaint, { type DynamicStyle } from 'facepaint';
import { isArray, isNumber, isObject, isString } from 'remeda';
import { type Except } from 'type-fest';

import {
  type Animation,
  animation,
  type BorderWidth,
  borderWidth,
  breakpoints,
  type Duration,
  duration,
  type Palette,
  palette,
  type Radius,
  radius,
  type Shadow,
  shadow,
  type Space,
  space,
} from './constants';

type ValOrValArr<V> = V | Array<V | undefined>;

type ItemsPlacement = 'center' | 'end' | 'start' | 'stretch';
type ContentPlacement = ItemsPlacement | 'space-around' | 'space-between' | 'space-evenly';

interface BasicProperties {
  alignContent: ValOrValArr<ContentPlacement>;
  alignItems: ValOrValArr<ItemsPlacement>;
  alignSelf: ValOrValArr<ItemsPlacement>;
  aspectRatio: ValOrValArr<StandardProperties['aspectRatio']>;
  cursor: ValOrValArr<'default' | 'pointer' | 'text'>;
  display: ValOrValArr<
    'block' | 'flex' | 'grid' | 'inline' | 'inline-grid' | 'inline-block' | 'none'
  >;
  flexDirection: ValOrValArr<'column' | 'row' | 'column-reverse' | 'row-reverse'>;
  flexWrap: ValOrValArr<'nowrap' | 'wrap' | 'wrap-reverse'>;
  float: ValOrValArr<'left' | 'right'>;
  fontStyle: ValOrValArr<'italic' | 'normal'>;
  gridArea: ValOrValArr<StandardProperties['gridArea']>;
  gridAutoFlow: ValOrValArr<'column' | 'column dense' | 'row' | 'row dense'>;
  gridColumn: ValOrValArr<StandardProperties['gridColumn']>;
  gridRow: ValOrValArr<StandardProperties['gridRow']>;
  gridTemplateAreas: ValOrValArr<StandardProperties['gridTemplateAreas']>;
  gridTemplateColumns: ValOrValArr<StandardProperties['gridTemplateColumns']>;
  gridTemplateRows: ValOrValArr<StandardProperties['gridTemplateRows']>;
  justifyContent: ValOrValArr<ContentPlacement>;
  justifyItems: ValOrValArr<ItemsPlacement>;
  justifySelf: ValOrValArr<ItemsPlacement>;
  listStyle: ValOrValArr<'inside'>;
  opacity: ValOrValArr<StandardProperties['opacity']>;
  overflow: ValOrValArr<'auto' | 'hidden' | 'scroll' | 'visible'>;
  pointerEvents: ValOrValArr<'auto' | 'none'>;
  position: ValOrValArr<'fixed' | 'absolute' | 'relative' | 'sticky'>;
  textAlign: ValOrValArr<'center' | 'end' | 'justify' | 'start'>;
  textDecoration: ValOrValArr<'underline' | 'overline' | 'line-through'>;
  textOverflow: ValOrValArr<'clip' | 'ellipsis' | 'unset'>;
  transform: ValOrValArr<StandardProperties['transform']>;
  transitionProperty: ValOrValArr<StandardProperties['transitionProperty']>;
  verticalAlign: ValOrValArr<'baseline' | 'bottom' | 'middle' | 'top'>;
  visibility: ValOrValArr<'collapse' | 'hidden' | 'visible'>;
  whiteSpace: ValOrValArr<'break-spaces' | 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap'>;
  wordBreak: ValOrValArr<'normal' | 'break-all' | 'break-word' | 'keep-all'>;
}

interface NamedProperties {
  animationDuration: ValOrValArr<Duration>;
  animationName: ValOrValArr<Animation>;
  bg: ValOrValArr<Palette>;
  border: ValOrValArr<BorderWidth>;
  borderBottom: ValOrValArr<BorderWidth>;
  borderBottomLeftRadius: ValOrValArr<Radius>;
  borderBottomRightRadius: ValOrValArr<Radius>;
  borderColor: ValOrValArr<Palette>;
  borderLeft: ValOrValArr<BorderWidth>;
  borderRadius: ValOrValArr<Radius>;
  borderRight: ValOrValArr<BorderWidth>;
  borderTop: ValOrValArr<BorderWidth>;
  borderTopLeftRadius: ValOrValArr<Radius>;
  borderTopRightRadius: ValOrValArr<Radius>;
  bottom: ValOrValArr<Space | string>;
  boxShadow: ValOrValArr<Shadow | StandardProperties['boxShadow']>;
  color: ValOrValArr<Palette>;
  columnGap: ValOrValArr<Space>;
  gap: ValOrValArr<Space>;
  height: ValOrValArr<Space | StandardProperties['height']>;
  left: ValOrValArr<Space | string>;
  lineHeight: ValOrValArr<Space>;
  m: ValOrValArr<Space>;
  maxHeight: ValOrValArr<Space | StandardProperties['height']>;
  maxWidth: ValOrValArr<Space | StandardProperties['width']>;
  mb: ValOrValArr<Space>;
  minHeight: ValOrValArr<Space | StandardProperties['height']>;
  minWidth: ValOrValArr<Space | StandardProperties['width']>;
  ml: ValOrValArr<Space>;
  mr: ValOrValArr<Space>;
  mt: ValOrValArr<Space>;
  mx: ValOrValArr<Space>;
  my: ValOrValArr<Space>;
  outline: ValOrValArr<BorderWidth>;
  outlineColor: ValOrValArr<Palette>;
  p: ValOrValArr<Space>;
  pb: ValOrValArr<Space>;
  pl: ValOrValArr<Space>;
  pr: ValOrValArr<Space>;
  pt: ValOrValArr<Space>;
  px: ValOrValArr<Space>;
  py: ValOrValArr<Space>;
  right: ValOrValArr<Space | string>;
  rowGap: ValOrValArr<Space>;
  top: ValOrValArr<Space | string>;
  transitionDuration: ValOrValArr<Duration>;
  width: ValOrValArr<Space | StandardProperties['width']>;
}
const namedPropertiesDict: Record<keyof NamedProperties, Record<string, string>> = {
  animationDuration: duration,
  animationName: animation,
  bg: palette,
  border: borderWidth,
  borderBottom: borderWidth,
  borderBottomLeftRadius: radius,
  borderBottomRightRadius: radius,
  borderColor: palette,
  borderLeft: borderWidth,
  borderRadius: radius,
  borderRight: borderWidth,
  borderTop: borderWidth,
  borderTopLeftRadius: radius,
  borderTopRightRadius: radius,
  bottom: space,
  boxShadow: shadow,
  color: palette,
  columnGap: space,
  gap: space,
  height: space,
  left: space,
  lineHeight: space,
  m: space,
  maxHeight: space,
  maxWidth: space,
  mb: space,
  minHeight: space,
  minWidth: space,
  ml: space,
  mr: space,
  mt: space,
  mx: space,
  my: space,
  outline: borderWidth,
  outlineColor: palette,
  p: space,
  pb: space,
  pl: space,
  pr: space,
  pt: space,
  px: space,
  py: space,
  right: space,
  rowGap: space,
  top: space,
  transitionDuration: duration,
  width: space,
} as const;

interface Shorthands {
  bg: ValOrValArr<Palette>;
  m: ValOrValArr<Space>;
  mb: ValOrValArr<Space>;
  ml: ValOrValArr<Space>;
  mr: ValOrValArr<Space>;
  mt: ValOrValArr<Space>;
  mx: ValOrValArr<Space>;
  my: ValOrValArr<Space>;
  p: ValOrValArr<Space>;
  pb: ValOrValArr<Space>;
  pl: ValOrValArr<Space>;
  pr: ValOrValArr<Space>;
  pt: ValOrValArr<Space>;
  px: ValOrValArr<Space>;
  py: ValOrValArr<Space>;
}
const shorthandsDict: Record<keyof Shorthands, Array<keyof StandardProperties>> = {
  bg: ['backgroundColor'],
  m: ['margin'],
  mb: ['marginBottom'],
  ml: ['marginLeft'],
  mr: ['marginRight'],
  mt: ['marginTop'],
  mx: ['marginLeft', 'marginRight'],
  my: ['marginBottom', 'marginTop'],
  p: ['padding'],
  pb: ['paddingBottom'],
  pl: ['paddingLeft'],
  pr: ['paddingRight'],
  pt: ['paddingTop'],
  px: ['paddingLeft', 'paddingRight'],
  py: ['paddingBottom', 'paddingTop'],
};

type PlusPseudos<T> = T & Partial<Record<`&${Pseudos}`, T>>;
type RawCss = { raw?: PlusPseudos<Except<CSSObject, Pseudos>> };
type Combined = Partial<BasicProperties & NamedProperties & Shorthands>;
type Sx = RawCss & PlusPseudos<Combined>;

const responsiveArrays = facepaint(
  [`@media(min-width: ${breakpoints.tablet}px)`, `@media(min-width: ${breakpoints.desktop}px)`],
  { overlap: true },
);

function useSx(sx?: Sx | Sx[]) {
  if (!sx) return undefined;

  const css: CSSObject[] = [];
  const sxArr = isArray(sx) ? sx : [sx];

  for (const { raw, ...sxObj } of sxArr) {
    css.push(expand(sxObj));
    if (raw) css.push(raw);
  }

  return responsiveArrays(css);
}

function expand(obj: Except<Sx, 'raw'>) {
  const newObj: CSSObject = {};

  for (const [key, val] of Object.entries(obj)) {
    const lookup = key in namedPropertiesDict && namedPropertiesDict[key as keyof NamedProperties];

    // shorthands, eg `m: 2` or `pt: [2, 4]`
    if (key in shorthandsDict) {
      const shorthands = shorthandsDict[key as keyof Shorthands];
      for (const shorthand of shorthands) {
        // @ts-expect-error: too complex
        if (isArray(val)) newObj[shorthand] = val.map((v) => getValue(lookup, v));
        else newObj[shorthand] = getValue(lookup, val);
      }
      continue;
    }

    if (isArray(val)) {
      // responsive arrays, eg `gridArea: ['mobile-val', 'tablet-val', 'desktop-val']`
      newObj[key] = val.map((v) => getValue(lookup, v));
    } else if (isObject(val)) {
      // pseudo classes, eg '&:hover {...}'
      newObj[key] = expand(val as Sx);
    } else {
      newObj[key] = getValue(lookup, val);
    }
  }

  return newObj;
}

type Value = string | DynamicStyle[] | undefined;
function getValue<K extends Record<string, string>>(lookup: false | K, val: unknown): Value {
  if (!lookup) return val as Value;
  if ((isNumber(val) || isString(val)) && val in lookup) return lookup[val];
  return val as Value;
}

export { responsiveArrays, useSx };
export type { BasicProperties, NamedProperties, Shorthands, Sx };
