import { CSelect, Text } from '@givelegacy/react';

import { LOCATION } from '../../constants';
import { Step } from '../step';
import { type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'location';
const locationData: SchemaOptions<typeof schemaProperty> = LOCATION.stateNames.map((value) => ({
  label: value,
  value,
}));

function LocationStep() {
  return (
    <Step title="Where do you live?">
      <Text>We'll make sure Legacy can serve your location.</Text>
      <CSelect autoFocus data={locationData} label="State" name={schemaProperty} required />
    </Step>
  );
}

export { locationData, LocationStep };
