import { type IconInstanceProps, Minus, Plus } from '@givelegacy/icons';
import { type FC, type ReactNode, useContext } from 'react';
import { type Exact, type Except } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { Text, type TextProps } from '../text';
import { type AccordionContext, accordionContext } from './accordion_context';

interface AccordionTriggerProps extends Except<TextProps, 'as'> {
  readonly ClosedIcon?: FC<IconInstanceProps>;
  readonly OpenedIcon?: FC<IconInstanceProps>;
  readonly children?: ReactNode;
  readonly size?: AccordionContext['size'];
  readonly theme?: AccordionContext['theme'];
  readonly value: string;
}

function AccordionTrigger(props: AccordionTriggerProps) {
  const ctx = useContext(accordionContext);
  const {
    ClosedIcon = Plus,
    OpenedIcon = Minus,
    children,
    size = ctx.size,
    theme = ctx.theme,
    value,
    ...rest
  } = props;
  rest satisfies Exact<TextProps, typeof rest>;

  const { isDisabled, isOpen } = ctx.getItemState({ value });
  const isClickable = !isDisabled && (ctx.config.collapsible || !isOpen);

  const mergedProps = usePropsMerge<TextProps>(
    {
      alt: true,
      as: 'button',
      sx: {
        alignItems: 'center',
        color: theme === 'light' ? 'evergreen800' : 'white',
        cursor: isClickable ? 'pointer' : 'default',
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'space-between',
        py: (size === 'lg' && 8) || (size === 'md' && 4) || 2,
        width: 'full',
      },
      attrs: ctx.getItemTriggerProps({ value }),
      variant: (size === 'lg' && 'body2') || (size === 'md' && 'body3') || 'body4',
    },
    rest,
  );

  return (
    <Text {...mergedProps}>
      <span>{children}</span>
      {isOpen ? <OpenedIcon size={size} /> : <ClosedIcon size={size} />}
    </Text>
  );
}

export { AccordionTrigger };
export type { AccordionTriggerProps };
