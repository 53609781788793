import { CInput } from '@givelegacy/react';

import { Step } from '../step';
import { type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'name';

function NameStep() {
  return (
    <Step title="Let's start with your first name.">
      <CInput autoFocus label="Name" name={schemaProperty} required />
    </Step>
  );
}

export { NameStep };
