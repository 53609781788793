import { connect, type Context, machine } from '@zag-js/accordion';
import { normalizeProps, useMachine } from '@zag-js/react';
import { type ReactNode, useMemo } from 'react';
import { type Exact, type SetOptional } from 'type-fest';

import { useControllableId, usePropsMerge } from '../../hooks';
import { Box, type BoxProps } from '../box';
import { AccordionContent } from './accordion_content';
import { type AccordionContext, accordionContext } from './accordion_context';
import { AccordionItem } from './accordion_item';
import { AccordionTrigger } from './accordion_trigger';

type Config = SetOptional<Context, 'id'>;
interface AccordionProps extends BoxProps<'div'>, Config {
  readonly children?: ReactNode;
  readonly data?: Array<{ content: string; title: string }>;
  readonly size?: AccordionContext['size'];
  readonly theme?: AccordionContext['theme'];
}

function Accordion(props: AccordionProps) {
  const {
    as,
    attrs,
    children,
    className,
    data,
    id,
    rootRef,
    size = 'md',
    sx,
    theme = 'light',
    ...rest
  } = props;
  rest satisfies Exact<Config, typeof rest>;

  const cId = useControllableId(id);

  const config: Context = useMemo(() => ({ id: cId, ...rest }), [cId, rest]);
  const [state, send] = useMachine(machine(config), { context: config });
  const api = connect(state, send, normalizeProps);

  const context = useMemo(() => ({ ...api, config, size, theme }), [api, config, size, theme]);

  const mergedProps = usePropsMerge<BoxProps>(
    { attrs: api.rootProps },
    { attrs, className, rootRef, sx },
  );

  return (
    <accordionContext.Provider value={context}>
      <Box as={as} {...mergedProps}>
        {children ||
          data?.map(({ content, title }, idx) => (
            <AccordionItem first={!idx} key={title} value={title}>
              <AccordionTrigger value={title}>{title}</AccordionTrigger>
              <AccordionContent value={title}>{content}</AccordionContent>
            </AccordionItem>
          ))}
      </Box>
    </accordionContext.Provider>
  );
}

export { Accordion };
export type { AccordionProps };
