import { ChevronDownSmall } from '@givelegacy/icons';
import { collection, connect, type Context, machine } from '@zag-js/combobox';
import { normalizeProps, useMachine } from '@zag-js/react';
import { type FunctionComponentElement, useMemo } from 'react';
import { type Exact, type Except } from 'type-fest';

import { useControllableId, usePropsMerge } from '../../hooks';
import { Box } from '../box';
import { Input, type InputProps } from '../input';
import { type SelectContext, selectContext } from './select_context';
import { SelectOption, type SelectOptionProps } from './select_option';
import { type SelectOptionGroupProps } from './select_option_group';

type Config = Readonly<Except<Context, 'id' | 'collection'>>;

interface SelectProps extends Except<InputProps, 'defaultValue' | 'value'>, Config {
  readonly children?: Array<FunctionComponentElement<SelectOptionProps | SelectOptionGroupProps>>;
  readonly data: SelectContext['data'];
  readonly id?: string;
  readonly required?: boolean;
}

function Select(props: SelectProps) {
  const {
    attrs,
    autoComplete,
    autoFocus,
    children,
    className,
    data,
    error,
    id,
    inputProps,
    label,
    onChange,
    required,
    rightAddon,
    rootRef,
    sx,
    theme = 'light',
    type,
    ...rest
  } = props;
  rest satisfies Exact<Config, typeof rest>;

  const cId = useControllableId(id);
  const [state, send] = useMachine(
    machine({
      autoFocus,
      collection: collection({ items: data }),
      id: cId,
      positioning: { sameWidth: true },
      selectionBehavior: 'replace',
    }),
    { context: rest },
  );
  const api = connect(state, send, normalizeProps);

  const context = useMemo(() => ({ ...api, data, theme }), [api, data, theme]);

  const mergedProps = usePropsMerge<InputProps>(
    {
      inputProps: { attrs: { ...api.inputProps, readOnly: true } },
      rightAddon: <ChevronDownSmall />,
      sx: { cursor: 'pointer', width: 'full' },
    },
    {
      attrs,
      autoComplete,
      className,
      error,
      label,
      inputProps,
      onChange,
      required,
      rightAddon,
      rootRef,
      sx,
      type,
      theme,
    },
  );

  return (
    <selectContext.Provider value={context}>
      <Box attrs={api.rootProps}>
        <Box attrs={api.controlProps}>
          <Box as="button" attrs={api.triggerProps} sx={{ cursor: 'pointer', width: 'full' }}>
            <Input labelStaticOnFocus {...mergedProps} />
          </Box>
        </Box>

        <Box attrs={api.positionerProps}>
          <Box
            attrs={{
              ...api.contentProps,
              style: { maxHeight: 'var(--available-height)' },
            }}
            sx={{
              bg: 'white',
              border: 1,
              borderColor: 'evergreen300',
              borderRadius: 2,
              overflow: 'auto',
            }}
          >
            {children || data.map((o) => <SelectOption key={o.value} option={o} />)}
          </Box>
        </Box>
      </Box>
    </selectContext.Provider>
  );
}

export { Select };
export type { SelectProps };
