import { type connect, type Context } from '@zag-js/accordion';
import { createContext } from 'react';

interface AccordionContext extends ReturnType<typeof connect> {
  config: Context;
  size: 'lg' | 'md' | 'sm';
  theme: 'dark' | 'light';
}

// @ts-expect-error: don't need a default state
const accordionContext = createContext<AccordionContext>();

export { accordionContext };
export type { AccordionContext };
