import { createContext, type MouseEvent } from 'react';

import { type StepName } from './flow';
import { type Schema } from './types';

type ForkLogic = (form: Schema) => number;
interface WizardContext {
  activeStep: StepName;
  onBack: (event: MouseEvent) => void;
  onNext: (event: MouseEvent) => void;
  onReset: () => void;
}

// @ts-expect-error: no default context
const wizardContext = createContext<WizardContext>();

export { wizardContext };
export type { ForkLogic, WizardContext };
