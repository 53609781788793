import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgSupplements(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M8.5 2.5h7v4.293l2.268 2.268a2.5 2.5 0 0 1 .732 1.767V19a2.5 2.5 0 0 1-2.5 2.5H8A2.5 2.5 0 0 1 5.5 19v-8.172a2.5 2.5 0 0 1 .732-1.767L8.5 6.793V2.5Zm-2 10v4h11v-4h-11Zm11-1h-11v-.672c0-.397.158-.779.44-1.06L9.206 7.5h5.586l2.268 2.268a1.5 1.5 0 0 1 .439 1.06v.672Zm0 6h-11V19A1.5 1.5 0 0 0 8 20.5h8a1.5 1.5 0 0 0 1.5-1.5v-1.5Zm-3-14v3h-5v-3h5Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgSupplements;
