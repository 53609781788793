const palette = {
  black: '#000000',
  transparent: 'transparent',
  white: '#FFFFFF',
  error500: '#CF1C03',
  error300: '#F17F6F',
  error100: '#FFE4E0',
  evergreen100: '#F7F7F7',
  evergreen200: '#EEEEEE',
  evergreen300: '#D2D3D0',
  evergreen400: '#A3A7A1',
  evergreen500: '#7C8178',
  evergreen600: '#545B4F',
  evergreen700: '#353E30',
  evergreen800: '#212B1A',
  evergreen900: '#16200F',
  gold100: '#FAF7F2',
  gold200: '#F7F0E3',
  gold300: '#F1E6D0',
  gold400: '#EADBBC',
  gold500: '#D4B67A',
  gold700: '#CAA458',
  gold800: '#B08A37',
  gold900: '#785D26',
  success100: '#EEF9E2',
  success300: '#98D76A',
  success500: '#4B8B1D',
  peach: '#F4D5B1',
  sand: '#FDF2E6',
  heritageGreen: '#2B724B',
  mint: '#6ED39B',
  subzeroGreen: '#E8F3ED',
  paleGreen: '#6ED39B12',
} as const;

type $Palette = `$${keyof typeof palette}`;
type Palette = keyof typeof palette;

export { palette };
export type { $Palette, Palette };
