import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgMinus(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path clipRule="evenodd" d="M19 13H5v-2h14v2z" fillRule="evenodd" />
    </Icon>
  );
}

export default SvgMinus;
