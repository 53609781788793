import { match, P } from 'ts-pattern';
import { type ConditionalPick, type TupleToUnion } from 'type-fest';

import {
  AgeStep,
  CancerTreatmentNotStartedInfoStep,
  CancerTreatmentStartedInfoStep,
  CancerTreatmentStep,
  EmailStep,
  FamilySizeStep,
  FertilityTreatmentInfoStep,
  GenderAffirmingNotStartedInfoStep,
  GenderAffirmingStartedInfoStep,
  GenderAffirmingStep,
  HowLongTryingForKidsStep,
  IntroStep,
  LifeStyleInfoStep,
  LifestyleStep,
  LocationInvalidStep,
  LocationStep,
  LocationValidStep,
  MedicalStep,
  MiscTreatmentStartedStep,
  NameStep,
  PrevPregnancyStep,
  ResultStep,
  SpermDonotrOrSurrogateInfoStep,
  SpermFreezingInfoStep,
  SummaryStep,
  TrtNotStartedFollowUpStep,
  TrtStartedFollowUpStep,
  TrtStartedStep,
  Under18InfoStep,
  UrgentStep,
  VasectomyDoneInfoStep,
  VasectomyNotDoneInfoStep,
  VasectomyReversalInfoStep,
  VasectomyStep,
  WantKidsStep,
  WhenKidsStep,
} from './steps';
import { type Schema } from './types';

const { PUBLIC_DISALLOWED_SHIPPING_STATES: disallowedShippingStates } = import.meta.env;

const stepDict = {
  EXIT_QUIZ: () => 'EXIT_QUIZ',
  age: AgeStep,
  cancerTreatment: CancerTreatmentStep,
  cancerTreatmentStartedInfo: CancerTreatmentStartedInfoStep,
  cancerTreatmentNotStartedInfo: CancerTreatmentNotStartedInfoStep,
  email: EmailStep,
  familySize: FamilySizeStep,
  fertilityTreatment: FertilityTreatmentInfoStep,
  genderAffirming: GenderAffirmingStep,
  genderAffirmingStartedInfo: GenderAffirmingStartedInfoStep,
  genderAffirmingNotStartedInfo: GenderAffirmingNotStartedInfoStep,
  intro: IntroStep,
  lifestyle: LifestyleStep,
  lifestyleInfo: LifeStyleInfoStep,
  location: LocationStep,
  locationInvalid: LocationInvalidStep,
  locationValid: LocationValidStep,
  medical: MedicalStep,
  miscTreatmentStarted: MiscTreatmentStartedStep,
  name: NameStep,
  howLongTryingForKids: HowLongTryingForKidsStep,
  prevPregnancy: PrevPregnancyStep,
  result: ResultStep,
  spermDonorOrSurrogate: SpermDonotrOrSurrogateInfoStep,
  spermFreezingInfo: SpermFreezingInfoStep,
  summary: SummaryStep,
  trtStarted: TrtStartedStep,
  trtStartedFollowUp: TrtStartedFollowUpStep,
  trtNotStartedFollowUp: TrtNotStartedFollowUpStep,
  under18Info: Under18InfoStep,
  urgent: UrgentStep,
  vasectomy: VasectomyStep,
  vasectomyDoneInfo: VasectomyDoneInfoStep,
  vasectomyNotDoneInfo: VasectomyNotDoneInfoStep,
  vasectomyReversal: VasectomyReversalInfoStep,
  wantKids: WantKidsStep,
  whenKids: WhenKidsStep,
} as const;
type StepName = keyof typeof stepDict;

const flow = {
  EXIT_QUIZ: 'EXIT_QUIZ',
  age,
  cancerTreatment,
  cancerTreatmentNotStartedInfo: 'lifestyle',
  cancerTreatmentStartedInfo: 'lifestyle',
  email: 'summary',
  familySize: 'medical',
  fertilityTreatment: 'lifestyle',
  genderAffirming,
  genderAffirmingNotStartedInfo: 'lifestyle',
  genderAffirmingStartedInfo: 'lifestyle',
  howLongTryingForKids: 'prevPregnancy',
  intro: 'urgent',
  lifestyle,
  lifestyleInfo: 'email',
  location,
  locationInvalid: 'EXIT_QUIZ',
  locationValid: 'wantKids',
  medical,
  miscTreatmentStarted: 'email',
  name: 'age',
  prevPregnancy: 'medical',
  result: 'EXIT_QUIZ',
  spermDonorOrSurrogate: 'lifestyle',
  spermFreezingInfo: 'familySize',
  summary: 'result',
  trtStarted,
  trtStartedFollowUp: 'lifestyle',
  trtNotStartedFollowUp: 'lifestyle',
  under18Info: 'location',
  urgent: 'name',
  vasectomy,
  vasectomyNotDoneInfo: 'lifestyle',
  vasectomyDoneInfo: 'lifestyle',
  vasectomyReversal: 'lifestyle',
  wantKids,
  whenKids,
} satisfies Record<StepName, StepName | ((schema: Schema) => StepName)>;
type Flow = typeof flow;

const possibleAge = ['under18Info', 'location'] satisfies StepName[];
function age(schema: Schema) {
  return match(schema.age)
    .returnType<TupleToUnion<typeof possibleAge>>()
    .with(P.number.lt(18), () => 'under18Info')
    .otherwise(() => 'location');
}

const possibleCancerTreatment = [
  'cancerTreatmentStartedInfo',
  'cancerTreatmentNotStartedInfo',
] satisfies StepName[];
function cancerTreatment(schema: Schema) {
  return match(schema.cancerTreatment)
    .returnType<TupleToUnion<typeof possibleCancerTreatment>>()
    .with('yes', () => 'cancerTreatmentStartedInfo')
    .otherwise(() => 'cancerTreatmentNotStartedInfo');
}

const possibleGenderAffirming = [
  'genderAffirmingStartedInfo',
  'genderAffirmingNotStartedInfo',
] satisfies StepName[];
function genderAffirming(schema: Schema) {
  return match(schema.genderAffirming)
    .returnType<TupleToUnion<typeof possibleGenderAffirming>>()
    .with('yes', () => 'genderAffirmingStartedInfo')
    .otherwise(() => 'genderAffirmingNotStartedInfo');
}

const possibleLifestyle = ['lifestyleInfo', 'email'] satisfies StepName[];
function lifestyle(schema: Schema) {
  return match(schema.lifestyle.length)
    .returnType<TupleToUnion<typeof possibleLifestyle>>()
    .with(0, () => 'email')
    .otherwise(() => 'lifestyleInfo');
}

const possibleLocation = ['locationInvalid', 'locationValid'] satisfies StepName[];
function location(schema: Schema) {
  return match(schema.location)
    .returnType<TupleToUnion<typeof possibleLocation>>()
    .with(
      P.when(() => disallowedShippingStates?.split(',').includes(schema.location)),
      () => 'locationInvalid',
    )
    .otherwise(() => 'locationValid');
}

const possibleMedical = [
  'cancerTreatment',
  'genderAffirming',
  'trtStarted',
  'vasectomy',
  'vasectomyReversal',
  'fertilityTreatment',
  'spermDonorOrSurrogate',
  'lifestyle',
] satisfies StepName[];
function medical(schema: Schema) {
  return match(schema.medical)
    .returnType<TupleToUnion<typeof possibleMedical>>()
    .with('cancerTreatment', () => 'cancerTreatment')
    .with('genderAffirming', () => 'genderAffirming')
    .with('trt', () => 'trtStarted')
    .with('vasectomy', () => 'vasectomy')
    .with('vasectomyReversal', () => 'vasectomyReversal')
    .with('fertilityTreatment', () => 'fertilityTreatment')
    .with('spermDonorOrSurrogate', () => 'spermDonorOrSurrogate')
    .with('none', () => 'lifestyle')
    .exhaustive();
}

const possibleTrtStarted = ['trtStartedFollowUp', 'trtNotStartedFollowUp'] satisfies StepName[];
function trtStarted(schema: Schema) {
  return match(schema.trtStarted)
    .returnType<TupleToUnion<typeof possibleTrtStarted>>()
    .with('yes', () => 'trtStartedFollowUp')
    .otherwise(() => 'trtNotStartedFollowUp');
}

const possibleVasectomy = ['vasectomyDoneInfo', 'vasectomyNotDoneInfo'] satisfies StepName[];
function vasectomy(schema: Schema) {
  return match(schema.vasectomy)
    .returnType<TupleToUnion<typeof possibleVasectomy>>()
    .with('yes', () => 'vasectomyDoneInfo')
    .otherwise(() => 'vasectomyNotDoneInfo');
}

const possibleWantKids = ['medical', 'whenKids'] satisfies StepName[];
function wantKids(schema: Schema) {
  return match(schema.wantKids)
    .returnType<TupleToUnion<typeof possibleWantKids>>()
    .with('no', () => 'medical')
    .otherwise(() => 'whenKids');
}

const possibleWhenKids = ['howLongTryingForKids', 'spermFreezingInfo'] satisfies StepName[];
function whenKids(schema: Schema) {
  return match(schema.whenKids)
    .returnType<TupleToUnion<typeof possibleWhenKids>>()
    .with('now', () => 'howLongTryingForKids')
    .otherwise(() => 'spermFreezingInfo');
}

const _testHelpers = {
  age: possibleAge,
  cancerTreatment: possibleCancerTreatment,
  genderAffirming: possibleGenderAffirming,
  lifestyle: possibleLifestyle,
  location: possibleLocation,
  medical: possibleMedical,
  trtStarted: possibleTrtStarted,
  vasectomy: possibleVasectomy,
  wantKids: possibleWantKids,
  whenKids: possibleWhenKids,
} satisfies Record<keyof ConditionalPick<Flow, Function>, StepName[]>;

export { _testHelpers, flow, stepDict };
export type { Flow, StepName };
