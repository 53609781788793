import { connect, type Context, machine } from '@zag-js/checkbox';
import { normalizeProps, useMachine } from '@zag-js/react';
import { type ReactNode, useEffect } from 'react';
import { type Exact, type Except, type SetOptional } from 'type-fest';

import { useControllableId, useMutableRef, usePropsMerge } from '../../hooks';
import { Box } from '../box';
import { ButtonBase, type ButtonBaseProps } from '../button';
import { CheckboxTick } from './checkbox_tick';

type ZagCheckboxContext = SetOptional<Context, 'id'>;

interface CheckboxButtonProps
  extends Except<ButtonBaseProps<'label'>, 'as' | 'disabled'>,
    ZagCheckboxContext {
  readonly children: ReactNode;
  readonly error?: boolean;
  readonly theme?: 'dark' | 'light';
  readonly value: string;
}

function CheckboxButton(props: CheckboxButtonProps) {
  const {
    attrs,
    autoFocus,
    borderColor,
    children,
    className,
    error = false,
    fullWidth,
    icon: _,
    id,
    onClick,
    rootRef,
    size,
    sx,
    theme = 'light',
    variant = 'secondary',
    ...rest
  } = props;
  rest satisfies Exact<ZagCheckboxContext, typeof rest>;

  const cId = useControllableId(id);
  const [state, send] = useMachine(machine({ id: cId }), { context: rest });
  const api = connect(state, send, normalizeProps);

  const labelRef = useMutableRef<HTMLLabelElement>(rootRef);

  const mergedProps = usePropsMerge<ButtonBaseProps<'label'>>(
    {
      attrs: api.rootProps,
      borderColor: api.isChecked ? 'evergreen800' : 'evergreen300',
      sx: { raw: { columnGap: '0.25em' }, gridTemplateColumns: 'auto 1fr' },
    },
    {
      attrs,
      borderColor,
      className,
      fullWidth,
      onClick,
      rootRef: labelRef,
      size,
      sx,
      theme,
      variant,
    },
  );

  useEffect(() => {
    if (autoFocus) labelRef.current?.focus();
  }, [autoFocus, labelRef]);

  return (
    <ButtonBase as="label" {...mergedProps}>
      <CheckboxTick api={api} error={error} theme={theme} />
      <Box as="span" attrs={api.labelProps}>
        {children}
      </Box>
      <input {...api.hiddenInputProps} />
    </ButtonBase>
  );
}

export { CheckboxButton };
export type { CheckboxButtonProps };
