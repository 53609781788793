const fontSizes = {
  body1: '1.25rem', // 20px
  body2: '1rem', // 16px
  body3: '0.875rem', // 14px
  body4: '0.75rem', // 12px
  caption1: '1rem', // 16px
  caption2: '0.875rem', // 14px
  caption3: '0.75rem', // 12px
  display1: '6rem', // 96px
  display2: '5rem', // 80px
  heading1: '4rem', // 64px
  heading2: '3rem', // 48px
  heading3: '2.25rem', // 36px
  heading4: '1.875rem', // 30px
  heading5: '1.5rem', // 24px
  heading6: '1.25rem', // 20px
} as const;

// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
const fontWeights = {
  normal: '400',
  medium: '500',
  semibold: '600',
} as const;

const letterSpacings = {
  body1: 'normal',
  body2: 'normal',
  body3: 'normal',
  body4: 'normal',
  caption1: '0.1em', // 10%
  caption2: '0.1em', // 10%
  caption3: '0.1em', // 10%
  display1: '-0.04em', // -4%
  display2: '-0.04em', // -4%
  heading1: '-0.04em', // -4%
  heading2: '-0.04em', // -4%
  heading3: '-0.04em', // -4%
  heading4: '-0.04em', // -4%
  heading5: '-0.02em', // -2%
  heading6: '-0.02em', // -2%
} as const;

const lineHeights = {
  body1: '1.75rem', // 28px
  body2: '1.5rem', // 24px
  body3: '1.25rem', // 20px
  body4: '1rem', // 16px
  caption1: '1.5rem', // 24px
  caption2: '1.25rem', // 20px
  caption3: '1.25rem', // 20px
  display1: '6.5rem', // 104px
  display2: '5.5rem', // 88px
  heading1: '4.5rem', // 72px
  heading2: '3.375rem', // 54px
  heading3: '2.5rem', // 40px
  heading4: '2.25rem', // 36px
  heading5: '2rem', // 32px
  heading6: '1.75rem', // 28px
} as const;

export { fontSizes, fontWeights, letterSpacings, lineHeights };
