import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgPill(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M18.718 5.282a4.576 4.576 0 0 0-3.144-1.347 4.288 4.288 0 0 0-3.12 1.246l-7.273 7.273a4.289 4.289 0 0 0-1.246 3.12 4.577 4.577 0 0 0 1.347 3.144c.84.84 1.97 1.326 3.144 1.347a4.288 4.288 0 0 0 3.12-1.247l7.273-7.273a4.288 4.288 0 0 0 1.246-3.12 4.576 4.576 0 0 0-1.348-3.143Zm-3.162-.347A3.57 3.57 0 0 1 18.01 5.99a3.577 3.577 0 0 1 1.055 2.454 3.289 3.289 0 0 1-.954 2.394l-3.283 3.283-4.95-4.95 3.284-3.282a3.288 3.288 0 0 1 2.394-.954ZM9.172 9.879l-3.283 3.283a3.289 3.289 0 0 0-.954 2.394A3.57 3.57 0 0 0 5.99 18.01a3.577 3.577 0 0 0 2.454 1.055 3.289 3.289 0 0 0 2.394-.954l3.283-3.283-4.95-4.95Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgPill;
