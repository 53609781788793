import {
  CRadioGroup,
  InfoOverlay,
  InfoOverlayContent,
  InfoOverlayTrigger,
} from '@givelegacy/react';

import { Step } from '../step';
import { type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'prevPregnancy';
const familySizeData: SchemaOptions<typeof schemaProperty> = [
  { label: 'No', value: '0' },
  { label: 'Yes, once', value: '1' },
  { label: 'Yes, more than once', value: '2+' },
];

function PrevPregnancyStep() {
  return (
    <Step
      footerInfo={
        <InfoOverlay>
          <InfoOverlayTrigger>Why is this important?</InfoOverlayTrigger>
          <InfoOverlayContent>
            We know this can be a sensitive thing to share. Pregnancy loss, also known as
            miscarriage, could be a sign of poor sperm health, among many other factors.
          </InfoOverlayContent>
        </InfoOverlay>
      }
      title="Have you or your partner ever experienced pregnancy loss?"
    >
      <CRadioGroup data={familySizeData} name={schemaProperty} required />
    </Step>
  );
}

export { PrevPregnancyStep };
