import {
  CRadioGroup,
  InfoOverlay,
  InfoOverlayContent,
  InfoOverlayTrigger,
} from '@givelegacy/react';

import { Step } from '../step';
import { type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'medical';
const medicalData: SchemaOptions<typeof schemaProperty> = [
  { label: 'Gender-affirming treatment', value: 'genderAffirming' },
  { label: 'Testosterone replacement therapy', value: 'trt' },
  { label: 'Cancer treatment', value: 'cancerTreatment' },
  { label: 'Vasectomy', value: 'vasectomy' },
  { label: 'Vasectomy reversal', value: 'vasectomyReversal' },
  { label: 'Fertility treatment, such as IUI or IVF', value: 'fertilityTreatment' },
  { label: 'Using a sperm donor or gestational surrogate', value: 'spermDonorOrSurrogate' },
  { label: 'None of the above', value: 'none' },
];

function MedicalStep() {
  return (
    <Step
      footerInfo={
        <InfoOverlay>
          <InfoOverlayTrigger>What will we do with this info?</InfoOverlayTrigger>
          <InfoOverlayContent>
            This will let us find what's right for your journey. For example, some treatments, like
            hormone therapy or radiation, can compromise your sperm production. And if you're doing
            fertility treatment, you may benefit from additional testing and freezing options.
          </InfoOverlayContent>
        </InfoOverlay>
      }
      title="Are you considering or undergoing any of the following medical treatments or procedures, now or in the future?"
    >
      <CRadioGroup data={medicalData} name={schemaProperty} required />
    </Step>
  );
}

export { MedicalStep };
