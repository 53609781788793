import { Text } from '@givelegacy/react';

import { Step } from '../step';

function VasectomyReversalInfoStep() {
  return (
    <Step title="After a vasectomy reversal, you should test your semen several times over a period of 3–6 months (or as directed by your doctor).">
      <Text sx={{ mb: 2 }}>
        This allows you to check that your sperm have returned to your semen and your sperm health
        is recovering.
      </Text>
      <Text>
        Also: Vasectomy reversal surgery can increase the percentage of your sperm that contain
        damaged DNA, known as sperm DNA fragmentation. That's why we recommend an advanced semen
        analysis to understand the full picture of your sperm health.
      </Text>
    </Step>
  );
}

export { VasectomyReversalInfoStep };
