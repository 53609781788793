const duration = {
  0: '0s',
  1: '0.1s',
  2: '0.2s',
  3: '0.3s',
  4: '0.4s',
  5: '0.5s',
} as const;

type Duration = keyof typeof duration;

export { duration };
export type { Duration };
