import { Text } from '@givelegacy/react';

import { Step } from '../step';

function SpermFreezingInfoStep() {
  return (
    <Step nextButtonContent="Got it" title={`You've got time.`}>
      <Text>
        Sperm can be kept frozen indefinitely, and has been used to create healthy babies even after
        40 years in cryostorage.
      </Text>
    </Step>
  );
}

export { SpermFreezingInfoStep };
