import { type connect, type Context } from '@zag-js/accordion';
import { createContext } from 'react';

const ITEM_VALUE = 'only ever one';

interface InfoOverlayContext extends ReturnType<typeof connect> {
  config: Context;
}

// @ts-expect-error: don't need a default state
const infoOverlayContext = createContext<InfoOverlayContext>();

export { infoOverlayContext, ITEM_VALUE };
export type { InfoOverlayContext };
