import { type ReactNode, useContext } from 'react';
import { noop } from 'remeda';
import { type Exact, type Except } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { Button } from '../button';
import { Text, type TextProps } from '../text';
import { infoOverlayContext, ITEM_VALUE } from './info_overlay_context';

interface InfoOverlayTriggerProps extends Except<TextProps, 'as'> {
  readonly children?: ReactNode;
}

function InfoOverlayTrigger(props: InfoOverlayTriggerProps) {
  const ctx = useContext(infoOverlayContext);
  const { children, ...rest } = props;
  rest satisfies Exact<TextProps, typeof rest>;

  const { isOpen } = ctx.getItemState({ value: ITEM_VALUE });

  const mergedProps = usePropsMerge<TextProps>(
    {
      alt: true,
      attrs: ctx.getItemTriggerProps({ value: ITEM_VALUE }),
      sx: {
        alignItems: 'center',
        bg: 'mint',
        borderRadius: 3,
        columnGap: 2,
        cursor: 'pointer',
        display: 'grid',
        gridArea: 'trigger',
        gridAutoFlow: 'column',
        justifyContent: 'space-between',
        p: 4,
        width: 'full',
      },
      variant: 'body3',
    },
    rest,
  );

  return (
    <Text as="button" {...mergedProps}>
      <span>{children}</span>
      <Button onClick={noop} size="xs" sx={{ bg: 'heritageGreen' }}>
        {isOpen ? 'Hide' : 'Show'}
      </Button>
    </Text>
  );
}

export { InfoOverlayTrigger };
export type { InfoOverlayTriggerProps };
