import { type connect, type Context } from '@zag-js/dialog';
import { createContext } from 'react';
import { type SetOptional } from 'type-fest';

type ZagModalContext = SetOptional<Context, 'id'>;

interface ModalContext extends ReturnType<typeof connect> {
  config: ZagModalContext;
  size: 'lg' | 'md';
  theme: 'dark' | 'light';
}

// @ts-expect-error: don't need a default state
const modalContext = createContext<ModalContext>();

export { modalContext };
export type { ModalContext, ZagModalContext };
