import { useContext } from 'react';
import { type Exact } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { Box, type BoxProps } from '../box';
import { type Option, selectContext } from './select_context';

interface SelectOptionProps extends BoxProps {
  readonly option: Option;
}

function SelectOption(props: SelectOptionProps) {
  const ctx = useContext(selectContext);
  const { option, ...rest } = props;
  rest satisfies Exact<BoxProps, typeof rest>;

  const { isHighlighted, isSelected } = ctx.getItemState({ item: option });

  const mergedProps = usePropsMerge<BoxProps>(
    {
      attrs: ctx.getItemProps({ item: option }),
      sx: {
        bg: (isSelected && 'evergreen300') || (isHighlighted && 'evergreen100') || undefined,
        cursor: 'pointer',
        px: 2,
        py: 2,
      },
    },
    rest,
  );

  return <Box {...mergedProps}>{option.label}</Box>;
}

export { SelectOption };
export type { SelectOptionProps };
