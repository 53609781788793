import {
  BrowserTracing,
  ErrorBoundary,
  type ErrorBoundaryProps,
  init,
  Replay,
} from '@sentry/react';

const { PUBLIC_SENTRY_DSN: dsn } = import.meta.env;

const apiRegex = /^https:\/\/api\.givelegacy\.com/u;
init({
  dsn,
  integrations: [new BrowserTracing({ tracePropagationTargets: ['localhost', apiRegex] })],
  tracesSampleRate: 0.01,
});

function SentryProvider(props: ErrorBoundaryProps) {
  const { children, ...rest } = props;

  return <ErrorBoundary {...rest}>{children}</ErrorBoundary>;
}

export { SentryProvider };
