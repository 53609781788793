import { Portal } from '@zag-js/react';
import { type ReactNode, useContext } from 'react';
import { type Exact } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { Box, type BoxProps, Grid } from '../box';
import { modalContext } from './modal_context';

interface ModalContentProps extends BoxProps {
  readonly backdropProps?: BoxProps;
  readonly children: ReactNode;
  readonly containerProps?: BoxProps;
}

function ModalContent(props: ModalContentProps) {
  const ctx = useContext(modalContext);
  const { backdropProps, children, containerProps, ...rest } = props;
  rest satisfies Exact<BoxProps, typeof rest>;

  const mergedProps = usePropsMerge<BoxProps>(
    {
      attrs: ctx.contentProps,
      sx: {
        bg: 'white',
        borderRadius: 3,
        boxShadow: 4,
        height: ['full', 'auto'],
        overflow: 'auto',
        position: 'relative',
        width: ['full', undefined, 160],
      },
    },
    rest,
  );

  const sharedPositioning = {
    height: '100dvh',
    position: 'fixed',
    top: 0,
    width: '100dvw',
  } as const;

  const mergedBackdropProps = usePropsMerge<BoxProps>(
    {
      attrs: ctx.backdropProps,
      sx: { ...sharedPositioning, bg: 'black', opacity: 0.5 },
    },
    backdropProps,
  );

  const mergedContainerProps = usePropsMerge<BoxProps>(
    {
      attrs: ctx.containerProps,
      sx: {
        ...sharedPositioning,
        alignItems: 'center',
        justifyItems: 'center',
        p: [4, 8],
      },
    },
    containerProps,
  );

  if (!ctx.isOpen) return null;
  return (
    <Portal>
      <Box {...mergedBackdropProps} />
      <Grid {...mergedContainerProps}>
        <Grid {...mergedProps}>{children}</Grid>
      </Grid>
    </Portal>
  );
}

export { ModalContent };
export type { ModalContentProps };
