import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgCannabis(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M12.45 2.28 12 1.361l-.45.92C10.587 4.254 10 6.994 10 10c0 1.247.1 2.448.288 3.568a16.305 16.305 0 0 0-1.314-1.483c-1.82-1.819-3.899-3.063-5.789-3.572l-.839-.226.226.839c.51 1.89 1.754 3.969 3.573 5.788.592.591 1.21 1.122 1.841 1.586-1.4.003-2.7.4-3.756 1.08l-.655.42.655.42A6.973 6.973 0 0 0 8 19.5c.736 0 1.444-.11 2.104-.31l-.958.956-.353.354.707.707.354-.353 1.646-1.647V22.5h1v-3.293l1.646 1.647.354.353.707-.707-.353-.354-.958-.957c.66.202 1.368.311 2.104.311 1.405 0 2.71-.398 3.77-1.08l.655-.42-.655-.42a6.964 6.964 0 0 0-3.686-1.08 16.183 16.183 0 0 0 1.847-1.59c1.82-1.821 3.066-3.902 3.577-5.793l.226-.84-.84.227c-1.89.51-3.97 1.756-5.792 3.577a16.283 16.283 0 0 0-1.41 1.606c.2-1.154.308-2.396.308-3.687 0-3.005-.585-5.745-1.55-7.72ZM11 10c0-2.364.379-4.523 1-6.22.622 1.697 1 3.856 1 6.22s-.378 4.523-1 6.22c-.621-1.697-1-3.856-1-6.22Zm4.81 2.788c1.417-1.417 2.976-2.44 4.425-3.012-.572 1.45-1.594 3.01-3.011 4.426-1.418 1.418-2.977 2.44-4.427 3.013.572-1.45 1.595-3.01 3.012-4.427ZM3.843 9.784c1.449.571 3.007 1.593 4.423 3.008 1.415 1.416 2.437 2.974 3.008 4.423-1.449-.571-3.007-1.592-4.423-3.008s-2.437-2.974-3.008-4.423ZM5.558 18c.73.319 1.56.5 2.442.5.882 0 1.711-.181 2.442-.5-.73-.319-1.56-.5-2.442-.5-.882 0-1.711.181-2.442.5Zm8 0c.73.319 1.56.5 2.442.5.882 0 1.711-.181 2.442-.5-.73-.319-1.56-.5-2.442-.5-.882 0-1.711.181-2.442.5Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgCannabis;
