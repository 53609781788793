import { Text } from '@givelegacy/react';
import { useFormContext } from 'react-hook-form';

import { Step } from '../step';
import { type Schema } from '../types';

function FertilityTreatmentInfoStep() {
  const { getValues } = useFormContext<Schema>();
  const name = getValues('name');

  return (
    <Step
      title={`We have our fingers crossed for you, ${name}. Let's maximize your chances of success.`}
    >
      <Text sx={{ mb: 2 }}>
        Consider doing an advanced semen analysis with sperm DNA fragmentation testing. This deeper
        analysis looks at the genetic health of sperm, which can affect pregnancy rates even if
        other sperm health metrics (like count or motility) are normal.
      </Text>
      <Text>
        We also recommend freezing a sample as a back-up for your IUI or IVF cycle. As you know,
        fertility procedures are carefully timed. It's a good idea to have a fully analyzed semen
        sample available, in case there's an obstacle to getting a healthy "fresh" sample on the
        procedure day.
      </Text>
    </Step>
  );
}

export { FertilityTreatmentInfoStep };
