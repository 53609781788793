const breakpoints = {
  tablet: 768,
  desktop: 1_400,
  desktopCentered: 1_312,
} as const;

const breakpointConditions = {
  mobile: {},
  tablet: { '@media': `screen and (min-width: ${breakpoints.tablet}px)` },
  desktop: { '@media': `screen and (min-width: ${breakpoints.desktop}px)` },
} as const;

export { breakpointConditions, breakpoints };
