import { CheckCircleLarge } from '@givelegacy/icons';
import { Text } from '@givelegacy/react';
import { useFormContext } from 'react-hook-form';

import { Step } from '../step';
import { type Schema } from '../types';

function LocationValidStep() {
  const { getValues } = useFormContext<Schema>();
  const location = getValues('location');

  return (
    <Step
      title={
        <>
          <CheckCircleLarge size="inherit" sx={{ mr: 2 }} />
          {location} is in our service area.
        </>
      }
    >
      <Text>Shipping to you and overnight shipping back to our lab are free.</Text>
    </Step>
  );
}

export { LocationValidStep };
