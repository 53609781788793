import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgAlcohol(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M4 2.5H2.887l.74.832L11.5 12.19v8.31H8.21v1h7.58v-1H12.5v-8.31l7.874-8.858.74-.832H4Zm12.22 4L12 11.247 7.78 6.5h8.44Zm.78-.878L18.887 3.5H5.113L7 5.622V5.5h10v.122Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgAlcohol;
