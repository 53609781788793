import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgCheckCircle(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="m22.354 4.364.353-.354L22 3.303l-.354.353L12 13.303l-2.646-2.647L9 10.303l-.707.707.353.354 3 3 .354.353.354-.354 10-10ZM4.5 12a7.5 7.5 0 0 1 10.312-6.955l.464.188.375-.927-.463-.188A8.5 8.5 0 1 0 20.5 12v-.5h-1v.5a7.5 7.5 0 0 1-15 0Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgCheckCircle;
