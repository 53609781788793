import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgHotTub(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M5.94 3.94a1.5 1.5 0 1 1 2.12 2.12 1.5 1.5 0 0 1-2.12-2.12ZM7 2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm7.146.646.5-.5.708.708-.5.5a1.621 1.621 0 0 0 0 2.292 2.621 2.621 0 0 1 0 3.708l-.5.5-.708-.708.5-.5a1.621 1.621 0 0 0 0-2.292 2.621 2.621 0 0 1 0-3.708Zm4 0 .5-.5.708.708-.5.5a1.621 1.621 0 0 0 0 2.292 2.621 2.621 0 0 1 0 3.708l-.5.5-.708-.708.5-.5a1.621 1.621 0 0 0 0-2.292 2.621 2.621 0 0 1 0-3.708ZM3 12.5h-.5V21A2.5 2.5 0 0 0 5 23.5h14a2.5 2.5 0 0 0 2.5-2.5v-8.5H11.309l-1.178-2.357a2.972 2.972 0 0 0-5.631 1.33V12.5H3Zm8 1h9.5V21a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 21v-7.5H11Zm-1.764-2.91.955 1.91H5.5v-1.028a1.972 1.972 0 0 1 3.736-.882ZM6.5 15.5v5h-1v-5h1Zm4 .5v-.5h-1v5h1V16Zm4-.5v5h-1v-5h1Zm4 .5v-.5h-1v5h1V16Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgHotTub;
