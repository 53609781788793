import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgChevronDown(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="m4 7.293.354.353L12 15.293l7.646-7.647.354-.353.707.707-.354.354-8 8-.353.353-.354-.354-8-8L3.293 8 4 7.293Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgChevronDown;
