import { Box, Button, Grid, Text } from '@givelegacy/react';
import { useContext } from 'react';

import { layoutContext } from './context';

function Help() {
  const { setHelpModal } = useContext(layoutContext);

  return (
    <Grid sx={{ gridTemplateRows: '1fr auto', overflow: 'hidden' }} vertical>
      <Box sx={{ alignSelf: 'center', maxHeight: 'full', my: 4, overflow: 'auto', p: 4 }}>
        <Text variant="heading5">We're here to help.</Text>
        <Text sx={{ mb: 10, mt: 8 }}>
          Have questions about the sperm testing and freezing process? Our client advisors are ready
          to support you.
        </Text>
        <Grid sx={{ mb: 8, rowGap: 4 }} vertical>
          <Button
            fullWidth
            href="mailto:clientservices@givelegacy.com"
            target="_blank"
            variant="secondary"
          >
            Email us
          </Button>
          <Button
            fullWidth
            href="https://givelegacy.com/schedule"
            target="_blank"
            variant="secondary"
          >
            Schedule a call
          </Button>
          <Button fullWidth href="tel:+16175140901" target="_blank" variant="secondary">
            Call us
          </Button>
          <Text sx={{ color: 'evergreen800', textAlign: 'center' }} variant="body3">
            617 514 0901
          </Text>
        </Grid>
        <Text sx={{ color: 'evergreen500', mb: 2, textAlign: 'center' }} variant="body3">
          Client advisors are available 8am-9pm EST, Monday-Friday. We respond to most emails and
          voicemails within 60 minutes.
        </Text>
        <Text sx={{ color: 'evergreen500', textAlign: 'center' }} variant="body3">
          Outside of these hours, we recommend scheduling a call. We'll respond to emails and
          voicemails by the next business day.
        </Text>
      </Box>
      <Box sx={{ borderColor: 'evergreen300', borderTop: 1, p: 4 }}>
        <Button fullWidth onClick={handleClose}>
          Back
        </Button>
      </Box>
    </Grid>
  );

  function handleClose() {
    setHelpModal(false);
  }
}

export { Help };
