import { type IconInstanceProps } from '@givelegacy/icons';
import {
  type ButtonHTMLAttributes,
  type FC,
  type HTMLAttributeAnchorTarget,
  type ReactNode,
} from 'react';
import { type Exact } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { ButtonBase, type ButtonBaseProps } from './button_base';

interface ButtonProps extends ButtonBaseProps {
  readonly IconLeft?: FC<IconInstanceProps>;
  readonly IconRight?: FC<IconInstanceProps>;
  readonly children?: ReactNode;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly fullWidth?: boolean;
  readonly href?: string;
  readonly target?: HTMLAttributeAnchorTarget;
  readonly type?: ButtonHTMLAttributes<HTMLElement>['type'];
}

function Button(props: ButtonProps) {
  const {
    as = 'button',
    children,
    href,
    IconLeft,
    IconRight,
    target,
    type = 'button',
    ...rest
  } = props;
  rest satisfies Exact<ButtonBaseProps, typeof rest>;

  const mergedProps = usePropsMerge<ButtonBaseProps>({ attrs: { href, target, type } }, rest);
  const iconSize = rest.size === 'xs' ? 'sm' : rest.size;

  return (
    <ButtonBase as={href ? 'a' : as} {...mergedProps}>
      {IconLeft && <IconLeft size={iconSize} sx={{ mr: 2 }} />}
      {children}
      {IconRight && <IconRight size={iconSize} sx={{ ml: 2 }} />}
    </ButtonBase>
  );
}

export { Button };
export type { ButtonProps };
