import { CRadioGroup, Text } from '@givelegacy/react';
import { useFormContext } from 'react-hook-form';

import { Step } from '../step';
import { type Schema, type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'cancerTreatment';
const genderAffirmingData: SchemaOptions<typeof schemaProperty> = [
  { label: 'Yes', value: 'yes' },
  { label: 'Not yet', value: 'no' },
];

function CancerTreatmentStep() {
  const { getValues } = useFormContext<Schema>();
  const name = getValues('name');

  return (
    <Step
      title={`We're here to help, ${name}. Our client and clinical teams are oncofertility experts.`}
    >
      <Text>
        Cancer treatment is so important to getting you healthy again, but it may inadvertently harm
        your fertility. Have you already started treatment?
      </Text>
      <CRadioGroup data={genderAffirmingData} name={schemaProperty} required />
    </Step>
  );
}
function CancerTreatmentStartedInfoStep() {
  const { getValues } = useFormContext<Schema>();
  const name = getValues('name');

  return (
    <Step title={`Thanks for sharing, ${name}.`}>
      <Text sx={{ mb: 2 }}>
        Unfortunately, chemotherapy and radiation can harm the genetic health of your sperm almost
        immediately. We recommend talking to your doctor about the impact of your treatment before
        moving forward with sperm freezing.
      </Text>
      <Text>
        You may want to start with an advanced semen analysis, which measures how many of your sperm
        contain damaged DNA.
      </Text>
    </Step>
  );
}

function CancerTreatmentNotStartedInfoStep() {
  return (
    <Step title="Preserving your fertility before cancer treatment is the best option if you want (more) kids in the future.">
      <Text>
        Let's make this as quick and easy as possible. We'll show you some sperm freezing options
        based on your family plans and timeline, and we can have a collection kit in your hands in
        just 2 business days.
      </Text>
    </Step>
  );
}

export { CancerTreatmentStep, CancerTreatmentNotStartedInfoStep, CancerTreatmentStartedInfoStep };
