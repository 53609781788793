import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgPlus(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M12.5 5V4.5H11.5V5V11.5H5H4.5V12.5H5H11.5V19V19.5H12.5V19V12.5H19H19.5V11.5H19H12.5V5Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgPlus;
