import { connect, type Context, machine } from '@zag-js/checkbox';
import { normalizeProps, useMachine } from '@zag-js/react';
import { type ReactNode, useEffect } from 'react';
import { type Exact, type Except, type SetOptional } from 'type-fest';

import { useControllableId, useMutableRef, usePropsMerge } from '../../hooks';
import { Box } from '../box';
import { Text, type TextBodyType, type TextProps } from '../text';
import { CheckboxTick } from './checkbox_tick';

type ZagCheckboxContext = SetOptional<Context, 'id'>;

interface CheckboxProps extends Except<TextProps<'label'>, 'as'>, ZagCheckboxContext {
  readonly autoFocus?: boolean;
  readonly children: ReactNode;
  readonly error?: boolean;
  readonly theme?: 'dark' | 'light';
  readonly value: string;
  readonly variant?: TextBodyType;
}

function Checkbox(props: CheckboxProps) {
  const {
    alt,
    attrs,
    autoFocus,
    className,
    error = false,
    italic,
    rootRef,
    children,
    id,
    sx,
    theme = 'light',
    underline,
    variant,
    ...rest
  } = props;
  rest satisfies Exact<ZagCheckboxContext, typeof rest>;

  const cId = useControllableId(id);
  const [state, send] = useMachine(machine({ id: cId }), { context: rest });
  const api = connect(state, send, normalizeProps);

  const labelRef = useMutableRef<HTMLLabelElement>(rootRef);

  const mergedProps = usePropsMerge<TextProps<'label'>>(
    {
      attrs: api.rootProps,
      sx: {
        raw: { columnGap: '0.25em' },
        alignItems: 'center',
        cursor: 'default',
        display: 'inline-grid',
        gridAutoFlow: 'column',
        justifyContent: 'start',
      },
    },
    { alt, attrs, className, italic, rootRef: labelRef, sx, underline, variant },
  );

  useEffect(() => {
    if (autoFocus) labelRef.current?.focus();
  }, [autoFocus, labelRef]);

  return (
    <Text as="label" {...mergedProps}>
      <CheckboxTick api={api} error={error} theme={theme} />
      <Box as="span" attrs={api.labelProps}>
        {children}
      </Box>
      <input {...api.hiddenInputProps} />
    </Text>
  );
}

export { Checkbox };
export type { CheckboxProps };
