import { type Palette, palette, type Sx } from '@givelegacy/style';
import { type ElementRef, type MouseEventHandler, type ReactNode } from 'react';
import { match } from 'ts-pattern';
import { type EmptyObject, type Exact } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { type As, type Attrs, type BoxProps } from '../box';
import { Text, type TextProps } from '../text';

interface ButtonBaseProps<E extends As = As> extends BoxProps<E> {
  readonly autoFocus?: boolean;
  readonly borderColor?: Palette;
  readonly children?: ReactNode;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly fullWidth?: boolean;
  readonly icon?: boolean;
  readonly onClick?: MouseEventHandler<ElementRef<E>>;
  readonly size?: 'md' | 'sm' | 'xs';
  readonly theme?: 'dark' | 'light';
  readonly variant?: 'highlight' | 'primary' | 'secondary' | 'tertiary';
}

function ButtonBase<E extends As = As>(props: ButtonBaseProps<E>) {
  const {
    as = 'button' as E,
    autoFocus,
    attrs,
    borderColor,
    children,
    className,
    disabled = false,
    fullWidth,
    icon = false,
    onClick,
    rootRef,
    size = 'md',
    sx,
    theme = 'light',
    variant = 'primary',
    ...rest
  } = props;
  rest satisfies Exact<EmptyObject, typeof rest>;

  const paddingProps = getPaddingProps();
  const variantProps = getVariantProps();

  const mergedProps = usePropsMerge<TextProps<E>>(
    {
      alt: true,
      sx: {
        ...paddingProps,
        ...variantProps,
        borderRadius: 2,
        cursor: 'pointer',
        display: 'inline-grid',
        gridAutoFlow: 'column',
        textAlign: 'center',
        width: fullWidth ? 'full' : undefined,
      },
      variant: ((size === 'xs' || size === 'sm') && 'body3') || 'body2',
    },
    { attrs, className, rootRef, sx },
    { attrs: { autoFocus, disabled, onClick } as Attrs<E> },
  );

  return (
    <Text as={as} {...mergedProps}>
      {children}
    </Text>
  );

  function getPaddingProps() {
    return match({ icon, size })
      .returnType<Sx>()
      .with({ icon: true, size: 'md' }, () => ({ px: 4, py: 4 }))
      .with({ icon: true, size: 'sm' }, () => ({ px: 2.5, py: 2.5 }))
      .with({ icon: true, size: 'xs' }, () => ({}))
      .with({ size: 'md' }, () => ({ px: 6, py: 4 }))
      .with({ size: 'sm' }, () => ({ px: 4, py: 2.5 }))
      .with({ size: 'xs' }, () => ({ px: 2, py: 0.5 }))
      .exhaustive();
  }

  function getVariantProps() {
    // An inset box-shadow is the only way to give a "border" without needing size calculations
    // To add a shadow onto ButtonBase: boxShadow: `${yourShadow}, ${defaultShadowBelow}`
    const borderShadow = ({ base, hover }: { base?: Palette; hover?: Palette }) => ({
      boxShadow: base ? `0 0 0 1px ${palette[borderColor ?? base]} inset` : undefined,
      '&:hover': {
        boxShadow: hover ? `0 0 0 1px ${palette[borderColor ?? hover]} inset` : undefined,
      },
    });

    if (theme === 'dark') {
      return match({ disabled, variant })
        .returnType<Sx>()
        .with({ disabled: true, variant: 'highlight' }, () => ({
          bg: 'evergreen400',
          color: 'evergreen600',
        }))
        .with({ disabled: false, variant: 'highlight' }, () => ({
          bg: 'evergreen600',
          color: 'white',
          '&:hover': { bg: 'evergreen700' },
        }))
        .with({ disabled: true, variant: 'primary' }, () => ({
          bg: 'evergreen600',
          color: 'evergreen800',
        }))
        .with({ disabled: false, variant: 'primary' }, () => ({
          bg: 'white',
          color: 'evergreen800',
          '&:hover': { bg: 'evergreen100' },
        }))
        .with({ disabled: true, variant: 'secondary' }, () => ({
          ...borderShadow({ base: 'evergreen700' }),
          color: 'evergreen600',
        }))
        .with({ disabled: false, variant: 'secondary' }, () => ({
          ...borderShadow({ base: 'evergreen600', hover: 'white' }),
          color: 'white',
        }))
        .with({ disabled: true, variant: 'tertiary' }, () => ({
          color: 'evergreen600',
        }))
        .with({ disabled: false, variant: 'tertiary' }, () => ({
          color: 'white',
          '&:hover': { color: 'evergreen900' },
        }))
        .exhaustive();
    }

    return match({ disabled, variant })
      .returnType<Sx>()
      .with({ disabled: true, variant: 'highlight' }, () => ({
        bg: 'evergreen200',
        color: 'evergreen400',
      }))
      .with({ disabled: false, variant: 'highlight' }, () => ({
        bg: 'sand',
        color: 'peach',
        '&:hover': { bg: 'evergreen700' },
      }))
      .with({ disabled: true, variant: 'primary' }, () => ({
        bg: 'evergreen200',
        color: 'evergreen400',
      }))
      .with({ disabled: false, variant: 'primary' }, () => ({
        bg: 'evergreen800',
        color: 'white',
        '&:hover': { bg: 'evergreen700' },
      }))
      .with({ disabled: true, variant: 'secondary' }, () => ({
        ...borderShadow({ base: 'evergreen300' }),
        bg: 'evergreen100',
        color: 'evergreen400',
      }))
      .with({ disabled: false, variant: 'secondary' }, () => ({
        ...borderShadow({ base: 'evergreen300', hover: 'evergreen800' }),
        color: 'evergreen800',
      }))
      .with({ disabled: true, variant: 'tertiary' }, () => ({
        bg: 'evergreen400',
        color: 'evergreen600',
      }))
      .with({ disabled: false, variant: 'tertiary' }, () => ({
        bg: 'white',
        color: 'evergreen800',
        '&:hover': { bg: 'evergreen200' },
      }))
      .exhaustive();
  }
}

export { ButtonBase };
export type { ButtonBaseProps };
