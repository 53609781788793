import { useController, type UseControllerProps } from 'react-hook-form';
import { type Exact } from 'type-fest';

import { RadioGroup, type RadioGroupProps } from '../components';
import { usePropsMerge } from '../hooks';

interface CRadioGroupProps extends RadioGroupProps {
  readonly name: string;
  readonly rules?: UseControllerProps['rules'];
}

function CRadioGroup(props: CRadioGroupProps) {
  const { children, name, required, rules, ...rest } = props;
  rest satisfies Exact<RadioGroupProps, typeof rest>;

  const { field } = useController({ name, rules: { required, ...rules } });
  const mergedProps = usePropsMerge<RadioGroupProps>({ attrs: field, value: field.value }, rest);

  return <RadioGroup {...mergedProps}>{children}</RadioGroup>;
}

export { CRadioGroup };
export type { CRadioGroupProps };
