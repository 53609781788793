import { type connect } from '@zag-js/combobox';
import { createContext } from 'react';

interface Option {
  label: string;
  value: string;
}

interface SelectContext extends ReturnType<typeof connect> {
  data: Option[];
  theme: 'dark' | 'light';
}

// @ts-expect-error: don't need a default state
const selectContext = createContext<SelectContext>();

export { selectContext };
export type { Option, SelectContext };
