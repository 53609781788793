import {
  CRadioGroup,
  InfoOverlay,
  InfoOverlayContent,
  InfoOverlayTrigger,
  Text,
} from '@givelegacy/react';

import { Step } from '../step';
import { type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'familySize';
const familySizeData: SchemaOptions<typeof schemaProperty> = [
  { label: '1 or 2', value: '1-2' },
  { label: '3 or more', value: '3+' },
  { label: `I'm not sure`, value: 'unsure' },
];

function FamilySizeStep() {
  return (
    <Step
      footerInfo={
        <InfoOverlay>
          <InfoOverlayTrigger>Why do we ask?</InfoOverlayTrigger>
          <InfoOverlayContent>
            For most people, one frozen sperm sample is enough to create one pregnancy. So if you
            want a bigger family, we might recommend freezing multiple samples.
          </InfoOverlayContent>
        </InfoOverlay>
      }
      title={
        <>
          <Text sx={{ mb: 1 }} variant="inherit">
            Think about what your dream family looks like.
          </Text>
          <Text variant="inherit">How many children are you picturing?</Text>
        </>
      }
    >
      <CRadioGroup data={familySizeData} name={schemaProperty} required />
    </Step>
  );
}

export { FamilySizeStep, familySizeData };
