import { lazy, type ReactElement, type ReactNode, Suspense } from 'react';
import {
  type Control,
  type FieldValues,
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
  type UseFormReturn,
} from 'react-hook-form';
import { type EmptyObject, type Exact, type Except } from 'type-fest';

import { Box, type BoxProps } from '../components';
import { usePropsMerge } from '../hooks';

const isDev = import.meta.env.DEV;
const DevTool = lazy(() =>
  import('@hookform/devtools').then((module) => ({ default: module.DevTool })),
);

interface CFormProps<T extends FieldValues> extends Except<BoxProps<'form'>, 'as'> {
  readonly children: ReactNode;
  readonly onInvalidSubmit?: SubmitErrorHandler<T>;
  readonly onValidSubmit?: SubmitHandler<T>;
  readonly rhfForm: UseFormReturn<T>;
}

function CForm<T extends FieldValues>(props: CFormProps<T>): ReactElement {
  const {
    attrs,
    children,
    onInvalidSubmit,
    onValidSubmit,
    className,
    rhfForm,
    rootRef,
    sx,
    ...rest
  } = props;
  rest satisfies Exact<EmptyObject, typeof rest>;

  const { handleSubmit } = rhfForm;

  const mergedProps = usePropsMerge<BoxProps<'form'>>(
    { attrs: { onSubmit: handleSubmit(handleValidSubmit, handleInvalidSubmit) } },
    { attrs, className, rootRef, sx },
  );

  const devToolControl = rhfForm.control as Control<FieldValues>;

  return (
    <FormProvider {...rhfForm}>
      {isDev ? (
        <Suspense>
          <DevTool control={devToolControl} />
        </Suspense>
      ) : null}

      <Box as="form" {...mergedProps}>
        {children}
      </Box>
    </FormProvider>
  );

  function handleInvalidSubmit(...args: Parameters<SubmitErrorHandler<T>>) {
    const [errors, event] = args;
    console.info(errors);
    // rhfForm.setError(errors);
    return onInvalidSubmit?.(errors, event);
  }

  async function handleValidSubmit(...args: Parameters<SubmitHandler<T>>) {
    const [data, event] = args;
    return onValidSubmit?.(data, event);
  }
}

export { CForm };
export type { CFormProps };
