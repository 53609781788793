import { keyframes } from '@emotion/react';

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const fadeOut = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const animation = {
  fadeIn,
  fadeOut,
} as const;

type Animation = keyof typeof animation;

export { animation };
export type { Animation };
