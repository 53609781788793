import { CRadioGroup } from '@givelegacy/react';

import { Step } from '../step';
import { type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'miscTreatmentStarted';
const data: SchemaOptions<typeof schemaProperty> = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

function MiscTreatmentStartedStep() {
  return (
    <Step title="Already started?">
      <CRadioGroup data={data} name={schemaProperty} required />
    </Step>
  );
}

export { MiscTreatmentStartedStep };
