import { CRadioGroup, Text } from '@givelegacy/react';
import { useFormContext } from 'react-hook-form';

import { Step } from '../step';
import { type Schema, type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'vasectomy';
const data: SchemaOptions<typeof schemaProperty> = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

function VasectomyStep() {
  return (
    <Step
      title={
        <>
          <Text sx={{ mb: 2 }}>Taking control of family planning — awesome.</Text>
          <Text>Have you already had your vasectomy?</Text>
        </>
      }
    >
      <CRadioGroup data={data} name={schemaProperty} required />
    </Step>
  );
}

function VasectomyDoneInfoStep() {
  return (
    <Step title="All right, done and done.">
      <Text sx={{ mb: 2 }}>
        The next step after a vasectomy is to get a basic semen analysis, known as a "post-vasectomy
        check." This test determines whether or not there are sperm present in your semen, to make
        sure your vasectomy has been successful.
      </Text>
      <Text>
        We recommend testing about 12 weeks after your vasectomy, or after 20-30 ejaculations.
      </Text>
    </Step>
  );
}

function VasectomyNotDoneInfoStep() {
  const { getValues } = useFormContext<Schema>();
  const name = getValues('name');

  return (
    <Step title={`You're here at just the right time, ${name}.`}>
      <Text sx={{ mb: 2 }}>
        Vasectomy is a highly effective birth control option. If change your mind about having
        children in the future — it happens! — vasectomy reversal surgery is no guarantee. Pregnancy
        rates after reversal can be as low as 30%.
      </Text>
      <Text>
        Freezing sperm is a more affordable and reliable back-up plan, just in case. We can get a
        sperm freezing kit to your door in as little as 2 days.
      </Text>
    </Step>
  );
}

export { VasectomyStep, VasectomyDoneInfoStep, VasectomyNotDoneInfoStep };
