import { HelpCircle, X } from '@givelegacy/icons';
import { Box, Button, Grid, Image } from '@givelegacy/react';
import { type MutableRefObject, useContext } from 'react';

import { layoutContext } from './context';

interface HeaderProps {
  readonly rootRef: MutableRefObject<HTMLDivElement | null>;
}

function Header(props: HeaderProps) {
  const { rootRef } = props;

  const { exitUrl, setHelpModal } = useContext(layoutContext);

  return (
    <Grid
      rootRef={rootRef}
      sx={{
        alignItems: 'center',
        gridTemplateColumns: '1fr 2fr 1fr',
        gridTemplateAreas: '"left-side logo right-side"',
        p: 4,
      }}
    >
      <Box
        as="a"
        attrs={{ href: 'https://www.givelegacy.com' }}
        sx={{ justifySelf: 'center', gridArea: 'logo', height: [4, 8] }}
      >
        <Image
          alt="Legacy logo"
          src="https://www.givelegacy.com/wp-content/uploads/2020/03/Secondary.svg"
        />
      </Box>
      <Grid sx={{ columnGap: 4, justifyContent: 'end', gridArea: 'right-side' }}>
        <Button icon onClick={openHelp} size="xs" variant="tertiary">
          <HelpCircle />
        </Button>
        <Button href={exitUrl} icon size="xs" target="_self" variant="tertiary">
          <X />
        </Button>
      </Grid>
    </Grid>
  );

  function openHelp() {
    setHelpModal(true);
  }
}

export { Header };
