import { CRadioGroup, Text } from '@givelegacy/react';
import { useFormContext } from 'react-hook-form';

import { Step } from '../step';
import { type Schema, type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'genderAffirming';
const genderAffirmingData: SchemaOptions<typeof schemaProperty> = [
  { label: 'Yes', value: 'yes' },
  { label: 'Not yet', value: 'no' },
];

function GenderAffirmingStep() {
  return (
    <Step title="Gender-affirming care, got it.">
      <Text sx={{ mb: 2 }}>
        Our client experience and clinical teams are experts in fertility for trans folks.
      </Text>
      <Text>
        Taking estrogen or having gender-affirming surgery may harm your fertility, but you have
        options. Have you already started treatment?
      </Text>
      <CRadioGroup data={genderAffirmingData} name={schemaProperty} required />
    </Step>
  );
}

function GenderAffirmingNotStartedInfoStep() {
  const { getValues } = useFormContext<Schema>();
  const name = getValues('name');

  return (
    <Step
      title={`You're about to take the next step in your transition, ${name} — that's exciting!`}
    >
      <Text>
        Now is the best time to preserve your fertility. If you freeze now, you can try for
        biological children later without pausing your hormone therapy. Frozen samples can be used
        to create a pregnancy with fertility treatment such as IUI or IVF.
      </Text>
    </Step>
  );
}

function GenderAffirmingStartedInfoStep() {
  const { getValues } = useFormContext<Schema>();
  const name = getValues('name');

  return (
    <Step title={`Congrats on taking the next step in your transition, ${name}!`}>
      <Text sx={{ mb: 2 }}>
        Because you’ve already started your treatment, we recommend you start with a semen analysis.
        This will help you understand whether your fertility has been affected, and if storing sperm
        for the future makes sense for you.
      </Text>
      <Text>
        If it does, we can freeze your sample immediately. (You can choose a storage plan later.)
      </Text>
    </Step>
  );
}

export { GenderAffirmingStep, GenderAffirmingStartedInfoStep, GenderAffirmingNotStartedInfoStep };
