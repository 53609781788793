import { type ChangeEvent } from 'react';
import { useController, type UseControllerProps } from 'react-hook-form';
import { type Exact, type Except } from 'type-fest';

import { Input, type InputProps } from '../components';
import { usePropsMerge } from '../hooks';

interface CInputProps
  extends InputProps,
    Readonly<Except<UseControllerProps, 'control' | 'defaultValue'>> {}

function CInput(props: CInputProps) {
  const { defaultValue = '', name, required, rules, shouldUnregister, ...rest } = props;
  rest satisfies Exact<InputProps, typeof rest>;

  const { field } = useController({
    defaultValue,
    name,
    rules: { ...rules, required },
    shouldUnregister,
  });
  const { onChange, ...attrs } = field;

  const mergedProps = usePropsMerge<InputProps>(
    { inputProps: { attrs }, onChange: handleChange },
    rest,
  );

  return <Input {...mergedProps} />;

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (rest.type === 'number') {
      return onChange(
        event.currentTarget.value ? Number.parseFloat(event.currentTarget.value) : '',
      );
    }
    return onChange(event.currentTarget.value);
  }
}

export { CInput };
export type { CInputProps };
