import { CInput, Text } from '@givelegacy/react';

import { Step } from '../step';
import { type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'email';

function EmailStep() {
  return (
    <Step title="We're almost there.">
      <Text>Just need your email address, so we can share what we recommend for you.</Text>
      <CInput
        inputProps={{ attrs: { type: 'email' } }}
        label="Email"
        name={schemaProperty}
        required
      />
    </Step>
  );
}

export { EmailStep };
