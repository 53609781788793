import { type CheckedState } from '@zag-js/checkbox';
import { useState } from 'react';
import { type EmptyObject, type Exact, type Except } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { Grid, type GridProps } from '../box';
import { Checkbox } from './checkbox';
import { CheckboxButton } from './checkbox_button';

interface CheckboxItem {
  label: string;
  value: string;
}

interface CheckboxGroupProps extends Except<GridProps, 'children'> {
  readonly checkboxComponent?: 'Checkbox' | 'CheckboxButton';
  readonly data?: CheckboxItem[];
  readonly defaultValue?: string[];
  readonly disabled?: boolean;
  // TODO: rework to make the event available
  readonly onChange?: (nextValue?: string[]) => void;
  readonly theme?: 'light' | 'dark';
}

function CheckboxGroup(props: CheckboxGroupProps) {
  const {
    as,
    attrs,
    className,
    data,
    defaultValue,
    disabled,
    inline = false,
    checkboxComponent = 'checkbox',
    onChange,
    rootRef,
    sx,
    theme = 'light',
    vertical = true,
    ...rest
  } = props;
  rest satisfies Exact<EmptyObject, typeof rest>;
  const [value, setValue] = useState<undefined | string[]>(defaultValue);

  const mergedProps = usePropsMerge<GridProps>(
    { sx: { rowGap: 4 } },
    { attrs, className, inline, rootRef, sx, vertical },
  );

  const CheckboxComponent = checkboxComponent === 'Checkbox' ? Checkbox : CheckboxButton;

  return (
    <Grid as={as} {...mergedProps}>
      {data?.map((item, idx) => (
        <CheckboxComponent
          autoFocus={!idx}
          checked={itemChecked(item.value)}
          disabled={disabled}
          key={item.value}
          onCheckedChange={handleChange(item.value)}
          theme={theme}
          value={item.value}
        >
          {item.label}
        </CheckboxComponent>
      ))}
    </Grid>
  );

  function handleChange(itemValue: string) {
    return ({ checked }: { checked: CheckedState }) => {
      const val = value || [];
      const nextValue = checked ? [...val, itemValue] : val.filter((x) => x !== itemValue);
      setValue(nextValue);

      onChange?.(nextValue);
    };
  }

  function itemChecked(itemValue: string) {
    return (value || []).includes(itemValue);
  }
}

export { CheckboxGroup };
export type { CheckboxGroupProps };
