import { type Sx, useSx } from '@givelegacy/style';
import { type ReactNode, type SVGProps } from 'react';
import { match } from 'ts-pattern';

interface IconInstanceProps {
  readonly size?: 'expand' | 'inherit' | 'lg' | 'md' | 'sm';
  readonly svgAttrs?: SVGProps<SVGSVGElement>;
  readonly sx?: Sx;
}

interface IconProps extends IconInstanceProps {
  readonly children: ReactNode;
}

function Icon(props: IconProps) {
  const { children, size = 'md', svgAttrs, sx = {} } = props;

  const hw = getHeightWidth();

  const sxCss = useSx(sx);

  return (
    <svg
      // @ts-expect-error: @emotion/react uses css
      // eslint-disable-next-line react/no-unknown-property
      css={[sxCss, { height: hw, verticalAlign: 'middle', width: hw }]}
      fill="currentColor"
      stroke="none"
      viewBox="0 0 24 24"
      {...svgAttrs}
    >
      {children}
    </svg>
  );

  function getHeightWidth() {
    return match(size)
      .with('sm', () => '1rem')
      .with('md', () => '1.5rem')
      .with('lg', () => '3rem')
      .with('inherit', () => '1em')
      .with('expand', () => '100%')
      .exhaustive();
  }
}

export { Icon };
export type { IconInstanceProps, IconProps };
