import {
  CRadioGroup,
  InfoOverlay,
  InfoOverlayContent,
  InfoOverlayTrigger,
} from '@givelegacy/react';

import { Step } from '../step';
import { type SchemaOptions, type SchemaProperty } from '../types';

const schemaProperty: SchemaProperty = 'howLongTryingForKids';
const familySizeData: SchemaOptions<typeof schemaProperty> = [
  { label: `Haven't started yet`, value: 'unstarted' },
  { label: 'Less than 6 months', value: 'under6Months' },
  { label: '6 - 12 months', value: '6-12Months' },
  { label: '1 year or more', value: 'over1Year' },
];

function HowLongTryingForKidsStep() {
  return (
    <Step
      footerInfo={
        <InfoOverlay>
          <InfoOverlayTrigger>Why do we ask?</InfoOverlayTrigger>
          <InfoOverlayContent>
            If getting pregnant has been taking longer than you'd hoped, you might benefit from more
            in-depth testing to help optimize your chances.
          </InfoOverlayContent>
        </InfoOverlay>
      }
      title="How long have you been trying to get pregnant?"
    >
      <CRadioGroup autoFocus data={familySizeData} name={schemaProperty} required />
    </Step>
  );
}

export { HowLongTryingForKidsStep };
