import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgCheckCircleLarge(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M2.5 12a9.5 9.5 0 1 1 19 0 9.5 9.5 0 0 1-19 0ZM12 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5Zm5.854 7.354.353-.354-.707-.707-.354.353L10 15.293l-2.646-2.647L7 12.293 6.293 13l.353.354 3 3 .354.353.354-.353 7.5-7.5Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgCheckCircleLarge;
