import { type Sx, useSx } from '@givelegacy/style';
import { deepmerge } from 'deepmerge-ts';
import { type ElementRef, type EventHandler, type JSX, type ReactNode, type Ref } from 'react';
import { type EmptyObject, type SetRequired } from 'type-fest';

import { usePropsMerge } from '../../hooks';

type As = keyof JSX.IntrinsicElements;
type DataAttrs = Record<`data-${string}`, string | undefined>;
type Attrs<E extends As> = JSX.IntrinsicElements[E] & DataAttrs;

type BoxAttrs<P extends BoxProps> = SetRequired<P, 'attrs'>['attrs'];
type AttrKey<P extends BoxProps> = keyof BoxAttrs<P>;
type Attr<P extends BoxProps, A extends AttrKey<P>> = BoxAttrs<P>[A];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Event<P extends BoxProps, A extends AttrKey<P>, E = Attr<P, A>> = E extends EventHandler<any>
  ? Parameters<E>[0]
  : never;

interface BoxProps<E extends As = As> {
  /** HTML Element name to use as the root element (eg 'span') */
  readonly as?: E;
  readonly attrs?: Attrs<E>;
  readonly children?: ReactNode;
  readonly className?: string;
  readonly rootRef?: Ref<ElementRef<E>>;
  readonly sx?: Sx | Sx[];
}

function Box<E extends As = As>(props: BoxProps<E>) {
  const { as: Component = 'div' as E, attrs, children, className, rootRef, sx, ...rest } = props;
  rest satisfies EmptyObject;

  const css = useSx(sx) || [];

  const mergedAttrs = deepmerge({ className, css }, attrs || {}) satisfies Attrs<E>;
  return (
    // @ts-expect-error: difficult to satisfy React's JSX attrs types
    <Component ref={rootRef} {...mergedAttrs}>
      {children}
    </Component>
  );
}

interface GridProps<E extends As = As> extends BoxProps<E> {
  readonly inline?: boolean;
  readonly vertical?: boolean;
}

// Convenient, semantic wrapper
function Grid<E extends As = As>(props: GridProps<E>) {
  const { as, inline, vertical, ...rest } = props;

  const mergedProps = usePropsMerge<GridProps<E>>(
    {
      sx: { display: inline ? 'inline-grid' : 'grid', gridAutoFlow: vertical ? 'row' : 'column' },
    },
    rest,
  );

  return <Box as={as} {...mergedProps} />;
}

export { Box, Grid };
export type { As, Attr, Attrs, DataAttrs, Event, BoxProps, GridProps };
