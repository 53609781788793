const borderWidth = {
  0: '0px solid',
  1: '1px solid',
  2: '2px solid',
} as const;

const radius = {
  0: '0px',
  1: '2px', // default
  2: '4px',
  3: '8px',
  full: '50%',
} as const;

type BorderWidth = keyof typeof borderWidth;
type Radius = keyof typeof radius;

export { borderWidth, radius };
export type { BorderWidth, Radius };
