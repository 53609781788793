import { type ReactNode, useContext } from 'react';
import { type Exact } from 'type-fest';

import { usePropsMerge } from '../../hooks';
import { Box, type BoxProps } from '../box';
import { type AccordionContext, accordionContext } from './accordion_context';

interface AccordionItemProps extends BoxProps {
  readonly children: ReactNode;
  readonly disabled?: boolean;
  readonly first?: boolean;
  readonly theme?: AccordionContext['theme'];
  readonly value: string;
}

function AccordionItem(props: AccordionItemProps) {
  const ctx = useContext(accordionContext);
  const { children, disabled, first, theme = ctx.theme, value, ...rest } = props;
  rest satisfies Exact<BoxProps, typeof rest>;

  const mergedProps = usePropsMerge<BoxProps>(
    {
      attrs: ctx.getItemProps({ disabled, value }),
      sx: {
        borderBottom: 1,
        borderTop: first ? 1 : undefined,
        // intentionally last
        borderColor: theme === 'light' ? 'evergreen300' : 'evergreen600',
      },
    },
    rest,
  );

  return <Box {...mergedProps}>{children}</Box>;
}

export { AccordionItem };
export type { AccordionItemProps };
