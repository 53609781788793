import { type IconInstanceProps } from '../icon';
import { Icon } from '../icon';

function SvgXCircle(props: IconInstanceProps) {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M12 2.5a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19ZM1.5 12C1.5 6.201 6.201 1.5 12 1.5S22.5 6.201 22.5 12 17.799 22.5 12 22.5 1.5 17.799 1.5 12Zm14.207-3-.353.354L12.707 12l2.647 2.646.353.354-.707.707-.354-.353L12 12.707l-2.646 2.647-.354.353L8.293 15l.353-.354L11.293 12 8.646 9.354 8.293 9 9 8.293l.354.353L12 11.293l2.646-2.647.354-.353.707.707Z"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default SvgXCircle;
